import { useSteps } from '@chakra-ui/react';
import { SelectMsaFile } from 'features/admin/msa-table/upload-msa-modal/SelectMsaFile';
import { ConfirmEffectiveDate } from 'features/admin/msa-table/upload-msa-modal/ConfirmEffectiveDate';
import { useMemo, useState } from 'react';
import createContext from 'utils/createContext';
import { MsaDocument } from 'types/msa';

type UseUploadMsaOptions = {
  msaDocuments: MsaDocument[];
};

export const useUploadMsa = (
  options: Partial<UseUploadMsaOptions> | undefined
) => {
  const steps = useMemo(
    () => [
      {
        title: 'Select a MSA PDF',
        component: SelectMsaFile,
      },
      {
        title: 'Confirm the Effective Date Range',
        component: ConfirmEffectiveDate,
      },
    ],
    []
  );
  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  });
  const [file, setFile] = useState<File>();
  const [effectiveDateRange, setEffectiveDateRange] = useState<string[]>([]);

  const resetState = () => {
    setActiveStep(0);
    setFile(undefined);
    setEffectiveDateRange([]);
  };

  return {
    steps,
    activeStep,
    setActiveStep,
    file,
    setFile,
    effectiveDateRange,
    setEffectiveDateRange,
    resetState,
    msaDocuments: options?.msaDocuments ?? [],
  };
};

export const { Provider: UploadMsaProvider, useContext: useUploadMsaContext } =
  createContext(useUploadMsa);
