import { mapDtoToCancelSalesOrderResult } from './mappers';
import { CancelSalesOrderResult } from 'types/salesOrders';
import { useCancelSalesOrder } from './useCancelSalesOrder';
import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';

export function useCancelSalesOrders() {
  const queryClient = useQueryClient();
  const [results, setResults] = useState<CancelSalesOrderResult[]>([]);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { mutateAsync: mutateSaleOrderAsync } = useCancelSalesOrder({
    invalidateQueries: false,
  });

  const cancelOrder = async (
    soNumber: string
  ): Promise<CancelSalesOrderResult> => {
    try {
      await mutateSaleOrderAsync(soNumber);
      return {
        soNumber,
        messages: ['Successful cancelation'],
      };
    } catch (error: any) {
      return mapDtoToCancelSalesOrderResult(soNumber, error?.response?.data);
    }
  };

  const mutateAsync = async (soNumbers: string[]) => {
    setIsLoading(true);

    setResults(await Promise.all(soNumbers.map(cancelOrder)));

    setIsLoading(false);
    setIsSuccess(true);
    queryClient.invalidateQueries({ queryKey: ['salesOrders'] });
  };

  return {
    mutateAsync,
    isLoading,
    isSuccess,
    results,
  };
}
