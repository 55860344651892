export const BLACK = '#000000';

export const WHITE = '#FFFFFF';

export const ERROR_RED = '#E53E3E';

export const PRIMARY_BLUE_100 = '#CADCE7';
export const PRIMARY_BLUE_200 = '#96BACF';
export const PRIMARY_BLUE_300 = '#5F96B8';
export const PRIMARY_BLUE_400 = '#2573A0';
export const PRIMARY_BLUE_500 = '#074F83';
export const PRIMARY_BLUE_600 = '#003F6C';
export const PRIMARY_BLUE_700 = '#002F52';
export const PRIMARY_BLUE_800 = '#002036';
export const PRIMARY_BLUE_900 = '#00101B';

export const PRIMARY_ORANGE_100 = '#FFE5CF';
export const PRIMARY_ORANGE_200 = '#FFCCA0';
export const PRIMARY_ORANGE_300 = '#FFB16E';
export const PRIMARY_ORANGE_400 = '#FF9736';
export const PRIMARY_ORANGE_500 = '#F58220';
export const PRIMARY_ORANGE_600 = '#D06300';
export const PRIMARY_ORANGE_700 = '#9C4A00';
export const PRIMARY_ORANGE_800 = '#683200';
export const PRIMARY_ORANGE_900 = '#341902';

export const SECONDARY_GREEN_100 = '#E1F3D7';
export const SECONDARY_GREEN_200 = '#C1E6AF';
export const SECONDARY_GREEN_300 = '#A2DA85';
export const SECONDARY_GREEN_400 = '#80CC5A';
export const SECONDARY_GREEN_500 = '#76BC43';
export const SECONDARY_GREEN_600 = '#4B991F';
export const SECONDARY_GREEN_700 = '#397317';
export const SECONDARY_GREEN_800 = '#264D12';
export const SECONDARY_GREEN_900 = '#142709';

export const SECONDARY_TEAL_100 = '#EDF9F9';
export const SECONDARY_TEAL_200 = '#DBF3F3';
export const SECONDARY_TEAL_300 = '#C9EDEE';
export const SECONDARY_TEAL_400 = '#B6E6E8';
export const SECONDARY_TEAL_500 = '#8BCED1';
export const SECONDARY_TEAL_600 = '#83B3B5';
export const SECONDARY_TEAL_700 = '#628788';
export const SECONDARY_TEAL_800 = '#425A5A';
export const SECONDARY_TEAL_900 = '#212D2D';

export const SECONDARY_ORANGE_100 = '#FED9CB';
export const SECONDARY_ORANGE_200 = '#FDB195';
export const SECONDARY_ORANGE_300 = '#F98A5F';
export const SECONDARY_ORANGE_400 = '#F6601D';
export const SECONDARY_ORANGE_500 = '#E04403';
export const SECONDARY_ORANGE_600 = '#C12700';
export const SECONDARY_ORANGE_700 = '#901F00';
export const SECONDARY_ORANGE_800 = '#611500';
export const SECONDARY_ORANGE_900 = '#310C00';

export const SECONDARY_GRAY_200 = '#f2f2f2';
export const SECONDARY_GRAY_500 = '#989898';
export const SECONDARY_GRAY_800 = '#D5D5D5';
export const SECONDARY_GRAY_900 = '#f0f0f0';

export const GRAY_500 = '#718096';
