/* istanbul ignore file */
import { useFormContext } from 'react-hook-form';
import { FormTextarea } from 'components/FormTextarea';
import { SalesOrder } from 'types/salesOrders';
import { SectionCollapsable } from 'components/SectionCollapsable';
import { Text } from '@chakra-ui/react';

type Props = {
  tabIndex?: number;
};

export const GiftMessageForm: React.FC<Props> = ({ tabIndex }) => {
  const { register } = useFormContext<SalesOrder>();

  return (
    <SectionCollapsable title="Gift Message" defaultIsOpen={false}>
      <Text variant="boldBlue" mt={2}>
        Optional: Enter a gift message that will reflect on the packing slip
      </Text>
      <FormTextarea
        {...register('giftMessage')}
        aria-label="Gift Message"
        marginTop="12px"
        maxLength={500}
        rows={2}
        tabIndex={tabIndex}
      />
    </SectionCollapsable>
  );
};
