import { SortState } from 'hooks/useGridSorting';
import { AsnItem } from 'types/asn';
import { Report } from 'types/report';
import { QueryParamsObject } from 'utils/urlSearchParamsUtils';

export interface AsnPageParams {
  initialFilters?: QueryParamsObject;
  initialPage?: number;
  initialPageSize?: number;
  initialSort?: SortState<AsnItem>;
  onFilterChange?: (filters: QueryParamsObject) => void;
  onPaginationChange?: (pagination: { page: number; pageSize: number }) => void;
  onSortByChange?: (sorting: SortState<AsnItem>) => void;
  sortBy?: string | null;
}

export const dictFieldToQuery: Record<keyof Report<AsnItem>, string> = {
  tenantSoNumber: 'tenant-so-number',
  carrierService: 'carrier-service',
  maxLabelCreatedOn: 'max-label-created-on-range',
  trackingNbrs: 'tracking-nbrs',
  pageSkip: 'page-skip',
  pageSize: 'page-size',
  sortBy: 'sort-by',
};

const dictQueryToField = {
  'tenant-so-number': 'tenantSoNumber',
  'carrier-service': 'carrierService',
  'max-label-created-on-range': 'maxLabelCreatedOn',
  'tracking-nbrs': 'trackingNbrs',
} as { [key: string]: keyof AsnItem };

export function queryToAsnField(
  queryKey: string,
  queryWordSeparator: string = '-'
): keyof AsnItem | '' {
  const field =
    dictQueryToField[queryKey.replaceAll(queryWordSeparator, '-')] || '';
  return field;
}

export function asnFieldToQuery(
  field: string,
  queryWordSeparator: string = '-'
): string {
  const queryKey = dictFieldToQuery[field as keyof AsnItem] || field;
  return queryKey.replaceAll('-', queryWordSeparator);
}
