import { ComponentStyleConfig } from '@chakra-ui/react';

export const modalTheme: ComponentStyleConfig = {
  baseStyle: {
    overlay: {
      bg: 'whiteAlpha.800',
      backdropFilter: 'blur(5px)',
    },
    header: {
      fontSize: ['4xl', null, '2.5rem'],
      fontWeight: 300,
      color: 'primaryBlue.500',
      p: ['80px 18px 9px 18px', null, '48px 48px 24px 48px'],
    },
    dialog: {
      boxShadow: 'rgba(0, 0, 0, 0.25) 0px 5px 15px',
      borderRadius: '2xl',
    },
    closeButton: {
      top: ['12px', null, '24px'],
      right: ['12px', null, '24px'],
      fontSize: '2xl',
      color: 'primaryBlue.500',
    },
    dialogContainer: {
      alignItems: 'center',
    },
    body: {
      p: ['0 18px 18px 18px', null, '0 48px 48px 48px'],
    },
    footer: {
      p: ['20px 18px 18px 18px', null, '20px 48px 48px 48px'],
      justifyContent: 'start',
    },
  },
  sizes: { '7xl': { dialog: { maxW: '7xl' } } },
  variants: {
    'full-screen': {
      dialog: {
        margin: 0,
        maxH: '100%',
        maxW: '100% !important',
        position: 'absolute',
        width: 'auto',
        inset: 0,
        borderRadius: 0,
      },
    },
  },
};
