/* eslint-disable react-hooks/exhaustive-deps */
import { RATE_SHOP_OPTION, useShipVias } from 'queries/shipVias/useShipVias';
import { useTenant } from 'queries/tenant/useTenant';
import { SELECT_NODE_OPTION } from 'queries/warehouses/useWarehouses';
import { useEffect } from 'react';
import { useForm, UseFormProps } from 'react-hook-form';
import { SalesOrder } from 'types/salesOrders';

export const useSalesOrderForm = ({
  defaultValues,
  ...formProps
}: UseFormProps<SalesOrder>) => {
  const { data: tenant } = useTenant();
  const { data: shipVias } = useShipVias();

  const reactHookFormContext = useForm<SalesOrder>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues,
    context: {
      storefronts: tenant?.storefronts ?? [],
      shipVias: shipVias ?? [],
    },
    ...formProps,
  });
  const {
    watch,
    setValue,
    trigger,
    clearErrors,
    getValues,
    formState: { isDirty },
  } = reactHookFormContext;

  const orderType = watch('type');
  const shipViaValue = watch('shipVia');
  const shipOptionValue = watch('shipOption');
  const warehouseID = watch('warehouseID');
  const country = watch('shipTo.country');

  useEffect(() => {
    if (!isDirty || orderType === 'Freight' || !shipOptionValue) return;
    trigger('shipOption');
  }, [shipOptionValue, isDirty]);

  useEffect(() => {
    if ((country && country !== 'US') || shipViaValue !== RATE_SHOP_OPTION) {
      setValue('shipOption', undefined);
      trigger('shipOption');
    }
  }, [shipViaValue, country]);

  useEffect(() => {
    if (warehouseID === SELECT_NODE_OPTION.code) {
      clearErrors('carrierAccountUUID');
    }
  }, [warehouseID]);

  useEffect(() => {
    const { warehouseID } = getValues();
    if (orderType === 'Freight') {
      setValue('shipVia', 'UNSP');
      if (warehouseID === SELECT_NODE_OPTION.code) setValue('warehouseID', '');
    } else if (orderType === 'Parcel' && !warehouseID) {
      setValue('warehouseID', SELECT_NODE_OPTION.code);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderType]);

  return reactHookFormContext;
};
