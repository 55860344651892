import { SortState } from 'hooks/useGridSorting';
import { ItemMaster } from 'types/itemMaster';
import { QueryParamsObject } from 'utils/urlSearchParamsUtils';

export interface ItemMasterPageParams {
  initialSort?: SortState<ItemMaster>;
  onSortByChange?: (sorting: SortState<ItemMaster>) => void;
  initialPage?: number;
  onPageChange?: (page: number) => void;
  initialPageSize?: number;
  onPaginationChange?: (pagination: { page: number; pageSize: number }) => void;
  initialFilters?: QueryParamsObject;
  onFilterChange?: (filters: QueryParamsObject) => void;
}

export const dictFieldToQuery = {
  sku: 'sku',
  upc: 'upc',
  description: 'description',
  weight: 'weight-range',
  length: 'length-range',
  width: 'width-range',
  height: 'height-range',
  cubicFeet: 'cuft-range',
  isConveyable: 'is-conveyable-select',
  hazmatUnNumber: 'hazmat-un-number',
  itemValue: 'item-value-range',
  requiresLotControl: 'is-lot-control-select',
  tariffNumber: 'harmonized-tariff-number',
  customsValue: 'customs-value-range',
  commodityDescription: 'commodity-description',
  countryOfManufacture: 'country-of-manufacture',
  customsWeight: 'customs-weight-range',
} as { [key in keyof ItemMaster]: string };

export function queryToItemMasterField(
  queryKey: string,
  queryWordSeparator: string = '-'
): keyof ItemMaster | '' {
  const dictQueryToField = {
    sku: 'sku',
    upc: 'upc',
    description: 'description',
    'weight-range': 'weight',
    'length-range': 'length',
    'width-range': 'width',
    'height-range': 'height',
    'cuft-range': 'cubicFeet',
    'is-conveyable-select': 'isConveyable',
    'hazmat-un-number': 'hazmatUnNumber',
    'item-value-range': 'itemValue',
    'is-lot-control-select': 'requiresLotControl',
    'harmonized-tariff-number': 'tariffNumber',
    'customs-value-range': 'customsValue',
    'commodity-description': 'commodityDescription',
    'country-of-manufacture': 'countryOfManufacture',
    'customs-weight-range': 'customsWeight',
  } as { [key: string]: keyof ItemMaster };
  const field =
    dictQueryToField[queryKey.replaceAll(queryWordSeparator, '-')] || '';
  return field;
}

export function itemMasterFieldToQuery(
  field: string,
  queryWordSeparator: string = '-'
): string {
  const queryKey = dictFieldToQuery[field as keyof ItemMaster] || field;
  return queryKey.replaceAll('-', queryWordSeparator);
}
