import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { ExportTransporationDetails } from 'schemas/exportTransportationDetails';
import { mapTransportationDetailsToDto } from './mappers';
import {
  downloadReport,
  MAXIMUM_DOWNLOAD_TRIES,
  RETRY_REQUEST_INTERVAL,
} from 'utils/reportUtils';
import { FileType } from 'utils/fileTypes';

export const useDownloadTransportationDetailsReport = () => {
  return useMutation<any, any, ExportTransporationDetails>({
    retry: MAXIMUM_DOWNLOAD_TRIES,
    retryDelay: RETRY_REQUEST_INTERVAL * 1000,
    mutationFn: async (payload) => {
      const response = await axios.post(
        `/report/invoice/parcel-${payload.type}`,
        mapTransportationDetailsToDto(payload)
      );
      if (typeof response.data === 'string') {
        const filename = (response.headers['content-disposition'] as string)
          ?.split('filename=')
          ?.at(-1);
        downloadReport({
          fileName: filename,
          data: response.data,
          fileType: FileType.Csv,
        });
      } else {
        throw new Error(
          response.data.message ??
            'Failed to download report. Please try again.'
        );
      }
      return response;
    },
  });
};
