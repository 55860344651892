import { Spinner, SpinnerProps } from '@chakra-ui/react';

export function BigLoading(props: SpinnerProps) {
  return (
    <Spinner
      aria-label="Loading..."
      display="block"
      margin="auto"
      thickness="4px"
      speed="0.65s"
      emptyColor="gray.200"
      color="primaryBlue.500"
      size="xl"
      {...props}
    />
  );
}
