import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { PurchaseOrder } from 'types/purchaseOrders';
import { mapPurchaseOrderToDto } from './mappers';

export function useUpdatePurchaseOrder(poNumber: string) {
  const queryClient = useQueryClient();
  return useMutation<any, any, PurchaseOrder>({
    mutationFn: (order: PurchaseOrder) => {
      return axios.put(`/purchaseorder/${poNumber}`, {
        request_transaction_id: '',
        order: mapPurchaseOrderToDto(order),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['purchaseOrder'],
      });
      queryClient.invalidateQueries({
        queryKey: ['purchaseOrders'],
      });
    },
  });
}
