import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { MsaDocument } from 'types/msa';
import { formatDate } from 'utils/dateUtils';

export function useMsaUpload() {
  const queryClient = useQueryClient();

  return useMutation<
    any,
    any,
    { file: File } & Pick<
      MsaDocument,
      'effectiveStartDate' | 'effectiveEndDate'
    >
  >({
    mutationFn: async ({ file, effectiveStartDate, effectiveEndDate }) => {
      const formData = new FormData();
      formData.append('file', file);
      formData.append(
        'effective_start_date',
        formatDate(effectiveStartDate, 'YYYY-MM-DD', { timeZone: 'UTC' })
      );
      formData.append(
        'effective_end_date',
        formatDate(effectiveEndDate, 'YYYY-MM-DD', { timeZone: 'UTC' })
      );

      return axios.post('/admin/msa', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['msa-documents'],
      });
    },
  });
}
