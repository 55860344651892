import { Box, Text } from '@chakra-ui/react';
import { ButtonGroup } from 'components/ButtonGroup';
import {
  FORECAST_URL,
  CREATE_ITEM_MASTER_URL,
  PURCHASE_ORDER_URL,
  SALES_ORDER_URL,
  SHIPPING_ACCOUNT_SETUP_URL,
  UPDATE_ITEM_MASTER_URL,
  BARNES_AND_NOBLES,
  SHORELIGHT,
} from 'constants/templateLinks';
import { TemplateItem } from 'layouts/components/NavMenu';
import { useTenant } from 'queries/tenant/useTenant';

export const templateItemsDefault: TemplateItem[] = [
  {
    text: 'Sales Orders',
    pathname: SALES_ORDER_URL,
  },
  {
    text: 'Purchase Orders',
    pathname: PURCHASE_ORDER_URL,
  },
  {
    text: 'Item Master (Create)',
    pathname: CREATE_ITEM_MASTER_URL,
  },
  {
    text: 'Item Master (Update)',
    pathname: UPDATE_ITEM_MASTER_URL,
  },
  {
    text: 'Forecast',
    pathname: FORECAST_URL,
  },
  {
    text: 'Shipping Account Setup',
    pathname: SHIPPING_ACCOUNT_SETUP_URL,
  },
];

export const templateItemsByOrg: Record<string, TemplateItem[]> = {
  // SCP-266
  DMF: [
    {
      text: 'Barnes & Nobles',
      pathname: BARNES_AND_NOBLES,
    },
    {
      text: 'Shorelight',
      pathname: SHORELIGHT,
    },
  ],
};

export function GetTemplateItemsByOrgId(
  orgCode: string | undefined
): TemplateItem[] {
  if (orgCode === undefined) return [];
  return templateItemsByOrg[orgCode.toUpperCase()] || [];
}

export function DownloadTemplates() {
  const { data } = useTenant();
  const orgCode = data?.code;
  const templateItems = [
    ...templateItemsDefault,
    ...GetTemplateItemsByOrgId(orgCode),
  ];

  const buttons = templateItems.map((templateItem) => ({
    label: templateItem.text,
    handler: () => window.open(templateItem.pathname, '_blank'),
  }));
  return (
    <Box mb={4}>
      <Text as="h3" variant="pageHeading" mb={2}>
        1. Download
      </Text>
      <Text fontWeight={500} mb={2}>
        Choose the right template.
      </Text>
      <ButtonGroup
        buttons={buttons}
        commonButtonWrapperProps={{
          mr: 4,
        }}
        commonButtonProps={{
          mb: 3,
          size: 'md',
          width: '100%',
          padding: '10px',
        }}
      />
    </Box>
  );
}
