import {
  Button,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { BLACK } from 'theme/ui-palette';
import { useAuth0 } from '@auth0/auth0-react';
import { useListOrganizations } from 'queries/organizations/useListOrganizations';
import { useCallback, useEffect, useMemo } from 'react';
import debounce from 'lodash-es/debounce';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FormInput } from 'components/FormInput';
import { Organization } from 'types/organization';

function getOrg(state: string, organizations?: Organization[]) {
  return organizations?.find(
    (org) =>
      org.auth0OrganizationName.toLowerCase() === state ||
      org.code.toLowerCase() === state
  );
}

const schema = yup.object({
  code: yup
    .string()
    .test(
      'is-valid-org',
      'Organization Validation',
      function (state = '', { options: { context }, createError }) {
        if (!state) return true;
        const organizations = (context?.organizations ?? []) as Organization[];
        const currentOrg = context?.currentOrg as Organization;
        const newOrg = getOrg(state, organizations);
        if (!newOrg) {
          return createError({
            message: 'Cannot find organization name: ' + state,
          });
        } else if (currentOrg?.id === newOrg?.id) {
          return createError({
            message: 'You are already in this organization',
          });
        }
        return true;
      }
    ),
});

export function TenantSwitcher() {
  const { getAccessTokenSilently, user } = useAuth0();
  const { data: organizations } = useListOrganizations();

  const currentOrg = useMemo(() => {
    return organizations?.find(
      (org) => org.auth0OrganizationId === user?.org_id
    );
  }, [organizations, user]);

  const formContext = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    context: {
      organizations,
      currentOrg,
    },
    defaultValues: {
      code: '',
    },
    resolver: yupResolver(schema),
  });
  const {
    register,
    setValue,
    watch,
    formState: { isValid },
  } = formContext;

  const orgCode = watch('code');

  const switchTenant = useCallback(
    async (name: string) => {
      const newOrg = getOrg(name, organizations);
      if (!newOrg) return;
      await getAccessTokenSilently({
        organization: newOrg.auth0OrganizationId,
        ignoreCache: true,
      });
      window.location.href = '/';
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [organizations]
  );

  const handleChange = useMemo(
    () =>
      debounce((code: string) => {
        switchTenant(code);
      }, 1000),
    [switchTenant]
  );
  useEffect(() => {
    if (orgCode && isValid) handleChange(orgCode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid, orgCode, handleChange]);

  return currentOrg ? (
    <Popover isLazy>
      {({ isOpen }) => (
        <>
          <PopoverTrigger>
            <Button
              aria-label="Tenant Switcher"
              px={3}
              variant="ghost"
              rightIcon={isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            >
              {currentOrg.name}
            </Button>
          </PopoverTrigger>
          <PopoverContent>
            <PopoverBody py={3}>
              <FormProvider {...formContext}>
                <Text mb={1} fontWeight="bold" color={BLACK}>
                  Current: {currentOrg.auth0OrganizationName} ({currentOrg.code}
                  )
                </Text>
                <FormInput
                  variant="outline"
                  placeholder="Organization name or 3 letter code"
                  {...register('code')}
                  value={orgCode}
                  onChange={(e) =>
                    setValue('code', e.target.value?.toLowerCase(), {
                      shouldValidate: true,
                    })
                  }
                />
              </FormProvider>
            </PopoverBody>
          </PopoverContent>
        </>
      )}
    </Popover>
  ) : null;
}
