import { User } from 'types/user';

export function queryToUserField(
  queryKey: string,
  queryWordSeparator: string = '-'
): keyof User {
  const dictQueryToField: { [key: string]: keyof User } = {
    id: 'id',
    'email-address': 'emailAddress',
    name: 'name',
    roles: 'rolesLabel',
  };
  const field =
    dictQueryToField[queryKey.replaceAll(queryWordSeparator, '-')] || '';
  return field;
}

export function userFieldToQuery(
  userField: string,
  queryWordSeparator: string = '-'
): string {
  const dictFieldToQuery = {
    id: 'id',
    emailAddress: 'email-address',
    name: 'name',
    rolesLabel: 'roles',
  } as { [key in keyof User]: string };
  const queryKey = dictFieldToQuery[userField as keyof User] || userField;
  return queryKey.replaceAll('-', queryWordSeparator);
}
