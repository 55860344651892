import { Box, Text } from '@chakra-ui/react';
import { ErrorBoundary } from './ErrorBoundary';

export interface PerPageQtyPickerProps {
  quantity: number;
  setQuantity: (n: number) => void;
  options?: number[];
}

export const DEFAULT_OPTIONS_QTY = [20, 50, 100];

export function PerPageQtyPicker({
  quantity,
  setQuantity,
  options = DEFAULT_OPTIONS_QTY,
}: PerPageQtyPickerProps) {
  return (
    <Box display="flex" alignItems="center">
      <ErrorBoundary size="compact">
        <Text mr={4}>View items per page:</Text>
        <Box as="ul" listStyleType="none" display="flex">
          {options.map((n) => (
            <Box key={n} as="li" aria-current={quantity === n}>
              <Text
                role="button"
                onClick={() => {
                  setQuantity(n);
                }}
                cursor="pointer"
                borderRadius="50%"
                mr={1}
                h={8}
                w={8}
                lineHeight={8}
                textAlign="center"
                whiteSpace="nowrap"
                background={quantity === n ? 'primaryBlue.500' : 'none'}
                color={quantity === n ? 'white' : 'black'}
                _hover={{
                  opacity: quantity === n ? '1' : '0.6',
                }}
              >
                {n}
              </Text>
            </Box>
          ))}
        </Box>
      </ErrorBoundary>
    </Box>
  );
}
