import { Box, Button, useDisclosure } from '@chakra-ui/react';
import { PaginatedTable } from 'components/PaginatedTable';
import { useConfirmationDialog } from 'hooks/useConfirmationDialog';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Column, Row } from 'react-table';
import { QueryState } from 'types/queryState';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import {
  getQueryParamsObject,
  parseFilters,
  parseSort,
  querifyFilters,
  querifySort,
  QueryParamsObject,
} from 'utils/urlSearchParamsUtils';
import { ColumnMetaData, useGridFiltering } from 'hooks/useGridFiltering';
import { SortState, useGridSorting } from 'hooks/useGridSorting';
import { useLayoutPaginatedTable } from 'hooks/useLayoutPaginatedTable';
import { useUsers } from 'queries/admin/useUsers';
import { User } from 'types/user';
import { useSearchParams } from 'react-router-dom';
import { useRemoveUser } from 'mutations/admin/useRemoveUser';
import { useAuth0 } from '@auth0/auth0-react';
import { queryToUserField, userFieldToQuery } from './userTableParams';
import { useStore } from 'contexts/storeContext';
import * as _ from 'lodash-es';
import { focusToField } from 'utils/browserUtils';
import { UserProfileModal } from 'features/user-profile-modal/UserProfileModal';

function UsersTable() {
  const { getColumnWidth } = useLayoutPaginatedTable(900);
  const [searchParams, setSearchParams] = useSearchParams({
    sort: '',
  });
  const { user } = useAuth0();
  const queryResult = useUsers({ refetchOnMount: false });
  const { removeUser, isRemoving } = useRemoveUser();
  const isLoading =
    queryResult.isLoading || queryResult.isFetching || isRemoving;

  const { openConfirmationDialog } = useConfirmationDialog();
  const [state, dispatch] = useStore();
  const [userIdSelected, setUserIdSelected] = useState('');
  const {
    isOpen: isOpenProfile,
    onOpen: onOpenProfile,
    onClose: onCloseProfile,
  } = useDisclosure();

  const handleDelete = useCallback(
    (user: User) => {
      openConfirmationDialog({
        title: 'Remove user',
        message: `Do you want to remove user with email ${user.emailAddress}?`,
        onConfirm: () => removeUser(user.emailAddress, queryResult.refetch),
        blockScrollOnMount: false,
        confirmButtonLabel: 'Delete',
      });
    },
    [removeUser, openConfirmationDialog, queryResult.refetch]
  );

  const rolesList = useMemo(() => {
    let allRoles: string[] = [];
    queryResult.data?.forEach((user) => {
      allRoles = allRoles.concat(user.roles);
    });
    return _.uniq(allRoles);
  }, [queryResult.data]);

  const handleEdit = useCallback(
    (user: User) => {
      if (!_.isEmpty(user.id)) {
        setUserIdSelected(user.id);
        onOpenProfile();
      }
    },
    [onOpenProfile]
  );

  const columnsMetaData: { [key: string]: ColumnMetaData } = {
    emailAddress: { dataType: 'text' },
    name: { dataType: 'text' },
    rolesLabel: { dataType: 'select', selectOptions: rolesList },
    delete: {
      dataType: 'text',
      isSortable: false,
      isFilterable: false,
      hideSettingsButton: true,
    },
  };

  const updateSearchParams = useCallback(
    (updating: object) => {
      setSearchParams({
        ...searchParams,
        ...updating,
      });
    },
    [searchParams, setSearchParams]
  );

  const onFilterChange = useCallback(
    (filters: QueryParamsObject) => {
      dispatch(_.pick(filters, ['fieldToFocus']));
      updateSearchParams(
        querifyFilters(_.omit(filters, ['fieldToFocus']), userFieldToQuery)
      );
    },
    [dispatch, updateSearchParams]
  );

  const onSortChange = useCallback(
    (sorting: SortState<User>) => {
      updateSearchParams({ sort: querifySort(sorting, userFieldToQuery) });
    },
    [updateSearchParams]
  );

  const gridFiltering = useGridFiltering<User>({
    columnsMetaData,
    initialFilters: {
      ...parseFilters(getQueryParamsObject(searchParams), queryToUserField),
      tab: searchParams.get('tab'),
    },
    onFilterChange,
  });

  const gridSorting = useGridSorting<User>({
    getQueryState: () => queryResult,
    initialSort: parseSort(searchParams.get('sort') || '', queryToUserField),
    onSortChange,
  });

  const adminUserColumns = useMemo(
    () =>
      [
        {
          Header: 'Name',
          accessor: 'name',
          width: getColumnWidth(35, 280),
        },
        {
          Header: 'Email',
          accessor: 'emailAddress',
          width: getColumnWidth(30, 280),
        },
        {
          Header: 'Roles',
          accessor: 'rolesLabel',
          width: getColumnWidth(25, 240),
        },
        {
          accessor: 'delete',
          width: getColumnWidth(10, 120),
          Cell: ({ row }: { row: Row<User> }) =>
            user?.email !== row.original.emailAddress ? (
              <Box display="flex" justifyContent="center">
                <Button
                  aria-label="Edit Button"
                  onClick={() => handleEdit(row.original)}
                  fontSize="md"
                  rounded="md"
                  h="24px"
                  w="24px"
                  minW="24px"
                  p="0"
                  mr={1}
                >
                  <EditIcon fontSize="inherit" />
                </Button>
                <Button
                  aria-label="Delete Button"
                  onClick={() => handleDelete(row.original)}
                  fontSize="md"
                  variant="redSquareButton"
                  rounded="md"
                  h="24px"
                  w="24px"
                  minW="24px"
                  p="0"
                >
                  <ClearIcon fontSize="inherit" />
                </Button>
              </Box>
            ) : null,
        },
      ] as Column<User>[],
    // eslint-disable-next-line
    [getColumnWidth]
  );

  useEffect(() => {
    if (state && state.fieldToFocus) {
      focusToField(state.fieldToFocus);
    }
  }, [state]);

  return (
    <>
      <PaginatedTable
        clientSide
        tableName="admin_users_table"
        gridFiltering={gridFiltering}
        gridSorting={gridSorting}
        columns={adminUserColumns}
        queryState={
          {
            ...queryResult,
            data: isLoading ? [] : queryResult.data || [],
            isLoading,
          } as QueryState<User>
        }
        isResizable
      />
      <UserProfileModal
        isOpen={isOpenProfile}
        onClose={onCloseProfile}
        userId={userIdSelected}
        isEnableRole={true}
      />
    </>
  );
}

export default UsersTable;
