import { GridItem } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { FormInput } from 'components/FormInput';

type Props = {
  startTabIndex: number;
};

export const CodesForm: React.FC<Props> = ({ startTabIndex }) => {
  const { register } = useFormContext();

  return (
    <>
      <GridItem>
        <FormInput
          label="Ship Via"
          tabIndex={startTabIndex + 1}
          {...register('shipVia')}
        />
      </GridItem>
      <GridItem>
        <FormInput
          label="Carrier SCAC Code"
          tabIndex={startTabIndex + 2}
          {...register('carrierScacCode')}
        />
      </GridItem>
    </>
  );
};
