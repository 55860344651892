import { Text } from '@chakra-ui/react';

export function UpdateItemMastersInstruction() {
  return (
    <Text as="div" fontWeight={500} mb={4}>
      This file contains your updated SKU information. Item master definition is
      a prerequisite for PO creation and receiving new inventory. The
      information provided will be subject to verification during the receiving
      process.
      <Text fontWeight="bold">
        Please create a ticket with Standvast if you require updating a value
        beyond what is listed within this template.
      </Text>
    </Text>
  );
}
