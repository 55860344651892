import React from 'react';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Textarea,
  TextareaProps,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import get from 'lodash-es/get';

export type FormTextareaProps = TextareaProps & {
  label?: string;
  placeholder?: string;
  error?: string;
  hideLabel?: boolean;
};

export const FormTextarea = React.forwardRef<
  HTMLTextAreaElement,
  FormTextareaProps
>(({ label, placeholder, error, hideLabel, ...props }, ref) => {
  const {
    formState: { errors: formErrors },
  } = useFormContext();
  const errorMessage =
    error ?? (get(formErrors, props.name ?? '')?.message as string);

  return (
    <FormControl variant="floating" isInvalid={!!errorMessage}>
      {label && !hideLabel && <FormLabel>{label}</FormLabel>}
      <Textarea placeholder={placeholder} {...props} ref={ref} />
      {errorMessage && (
        <FormErrorMessage mb={2}>{errorMessage}</FormErrorMessage>
      )}
    </FormControl>
  );
});
