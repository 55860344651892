import { Box, BoxProps } from '@chakra-ui/react';
import { useRef, useState, useEffect, useMemo } from 'react';

type Props = Required<Pick<BoxProps, 'w'>> &
  Partial<BoxProps> & {
    children: React.ReactNode;
  };

export function Flyout({ children, w, ...props }: Props) {
  const [hasEllipsis, setHasEllipsis] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const coords = useMemo(() => ({ left: 0, right: 0 }), []);

  const handleFlyoutMouseOver = () => {
    if (isExpanded || !hasEllipsis) return;

    setIsExpanded(true);
  };

  const handleFlyoutMouseOut = () => {
    setIsExpanded(false);
  };

  useEffect(() => {
    const el = wrapperRef.current;
    const cell = el?.closest('td');
    const rowCells = Array.from(
      cell?.closest('tr')?.children ?? []
    ) as HTMLTableCellElement[];

    if (!cell || rowCells.length < 2) return;

    const cellIndex = rowCells.indexOf(cell);
    const contentEl = el?.children[0]! as HTMLDivElement;
    setHasEllipsis(contentEl.offsetWidth < contentEl.scrollWidth);

    if (rowCells[cellIndex + 1]) {
      coords.right = -rowCells[cellIndex + 1].offsetWidth;
      coords.left = 0;
    } else {
      coords.left = -rowCells[cellIndex - 1].offsetWidth / 2;
      coords.right = 0;
    }
  }, [wrapperRef, coords]);

  return (
    <Box
      position="relative"
      onMouseOver={handleFlyoutMouseOver}
      ref={wrapperRef}
      {...props}
      w={w}
      zIndex={isExpanded ? '1' : 'auto'}
      cursor={hasEllipsis && !isExpanded ? 'pointer' : 'default'}
    >
      <Box textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
        {children}
      </Box>
      {isExpanded && hasEllipsis && (
        <Box
          position="absolute"
          bgColor="white"
          p={2}
          top={-2}
          borderColor="primaryBlue.500"
          borderWidth="1px"
          onMouseOut={handleFlyoutMouseOut}
          {...coords}
        >
          {children}
        </Box>
      )}
    </Box>
  );
}
