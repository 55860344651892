import axios from 'axios';
import { useState } from 'react';

export function useUpdateUserRoles() {
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  async function updateUserRoles(userId: string, roleIds: string[] | []) {
    try {
      setIsUpdating(true);
      await axios.post(`/admin/users/${userId}/roles`, { role_ids: roleIds });
    } finally {
      setIsUpdating(false);
    }
  }

  async function deleteUserRoles(userId: string, roleIds: string[] | []) {
    try {
      setIsUpdating(true);
      await axios.delete(`/admin/users/${userId}/roles`, {
        data: { role_ids: roleIds },
      });
    } finally {
      setIsUpdating(false);
    }
  }

  return {
    updateUserRoles,
    deleteUserRoles,
    isUpdating,
  };
}
