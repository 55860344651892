import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { OrderDetailsModal } from 'features/order-details/OrderDetailsModal';
import { ErrorBoundary } from 'components/ErrorBoundary';

export function OrderDetailsPage() {
  const { salesOrderNumber } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const urlSegments = location.pathname.split('/');
  const firstUrlSegment = urlSegments[1] ?? '';
  const lastUrlSegment = urlSegments.at(-1);

  const handleClose = () => {
    navigate(`/${firstUrlSegment}${location.search}`);
  };

  const handleAbort = () => {
    navigate(`/${firstUrlSegment}/${salesOrderNumber}${location.search}`);
  };

  const handleEdit = () => {
    navigate(`/${firstUrlSegment}/${salesOrderNumber}/edit${location.search}`);
  };

  const handleCancel = () => {
    navigate(
      `/${firstUrlSegment}/${salesOrderNumber}/cancel${location.search}`
    );
  };

  const handleReRoute = () => {
    navigate(
      `/${firstUrlSegment}/${salesOrderNumber}/re-route${location.search}`
    );
  };

  return (
    <ErrorBoundary size="full">
      <OrderDetailsModal
        salesOrderNumber={salesOrderNumber!}
        onClose={handleClose}
        onAbort={handleAbort}
        onEdit={handleEdit}
        onCancel={handleCancel}
        onReRoute={handleReRoute}
        isCancelling={lastUrlSegment === 'cancel'}
        isEditing={lastUrlSegment === 'edit'}
      />
    </ErrorBoundary>
  );
}
