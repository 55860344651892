import { ComponentStyleConfig } from '@chakra-ui/react';

export const linkTheme: ComponentStyleConfig = {
  variants: {
    headerLink: {
      fontSize: 'md',
      color: 'secondaryOrange.500',
      alignItems: 'center',
      display: 'flex',
      mb: 1,
    },
    navLink: {
      h: '100%',
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
      _hover: {
        textDecoration: 'none',
      },
    },
  },
};
