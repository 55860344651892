import {
  Box,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  TextProps,
} from '@chakra-ui/react';
import { InlineText } from 'components/InlineText';
import { Shimmer } from 'components/Shimmer';
import { UNIT_OF_MEASURE_EACHES } from 'constants/unitOfMeasure';
import { ItemMaster } from 'types/itemMaster';
import { convertDecimalPoundsToPoundsAndOunces } from 'utils/measurementUtils';

type Props = {
  itemMaster?: ItemMaster;
};

export function Measurements({ itemMaster }: Props) {
  const unitOfMeasureForSizes =
    itemMaster?.unitOfMeasure === UNIT_OF_MEASURE_EACHES ? 'FT' : '';

  const measures = [
    ['Weight', itemMaster?.weight, itemMaster?.unitOfMeasureWeight],
    ['Length', itemMaster?.length, unitOfMeasureForSizes],
    ['Width', itemMaster?.width, unitOfMeasureForSizes],
    ['Height', itemMaster?.height, unitOfMeasureForSizes],
  ] as const;

  return (
    <SimpleGrid
      gridTemplateColumns={['repeat(2, auto)', null, 'repeat(5, auto)']}
      mt={10}
      width="100%"
    >
      {measures.map(([label, value, unit]) => (
        <Box key={label} mb={[5, null, 0]}>
          <Measurement value={value} unit={unit} label={label} />
        </Box>
      ))}
    </SimpleGrid>
  );
}

const Measurement = ({
  value,
  label,
  unit = '',
  numberColor,
}: {
  value?: number | string;
  label: string;
  unit?: string;
  numberColor?: TextProps['color'];
}) => (
  <Stat variant="primaryWithThinLabel" whiteSpace="nowrap">
    <StatNumber fontSize={['3xl', null, '4xl']} color={numberColor}>
      <Shimmer w="130px" h="30px" isLoaded={value !== undefined}>
        {typeof value === 'string' || !['LBS', 'FT'].includes(unit) ? (
          value
        ) : value != null && unit === 'LBS' ? (
          <MeasurementValueInPounds value={value} />
        ) : value != null && unit === 'FT' ? (
          <>{+value.toFixed(2)}”</>
        ) : null}
      </Shimmer>
    </StatNumber>
    <StatLabel fontSize="sm">{label}</StatLabel>
  </Stat>
);

const MeasurementValueInPounds = ({ value }: { value: number }) => {
  const { pounds, ounces } = convertDecimalPoundsToPoundsAndOunces(value);

  return (
    <>
      {pounds}
      <MeasurementUnit>lbs</MeasurementUnit>
      {ounces > 0 ? (
        <>
          {' '}
          {ounces}
          <MeasurementUnit>oz</MeasurementUnit>
        </>
      ) : null}
    </>
  );
};

const MeasurementUnit = ({ children }: TextProps) => (
  <InlineText fontWeight="bold" fontSize={['xl', null, '2xl']}>
    {children}
  </InlineText>
);
