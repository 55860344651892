import { useContext } from 'react';
import { ConfirmationDialogContext } from 'contexts/confirmationDialogContext';

export const useConfirmationDialog = () => {
  const context = useContext(ConfirmationDialogContext);

  return {
    openConfirmationDialog: context.showConfirmation,
  };
};
