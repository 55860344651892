import { useForm, UseFormProps } from 'react-hook-form';
import { UserProfileModify } from 'types/userProfile';

export const useUserProfileForm = ({
  defaultValues,
  ...formProps
}: UseFormProps<UserProfileModify>) => {
  const reactHookFormContext = useForm<UserProfileModify>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues,
    ...formProps,
  });

  return reactHookFormContext;
};
