import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { SearchResultsDto } from 'types/search';
import { mapDtoToSearchResults } from './mappers';
import { sendSearchEvent } from 'utils/monitorEvents';

export const MINIMUM_QUERY_LENGTH = 4;

export function useSearch(query = '') {
  return useQuery(
    ['search', query],
    async () => {
      const { data } = await axios.get<SearchResultsDto>(`/search/${query}`);
      sendSearchEvent(query);
      return data;
    },
    {
      select: mapDtoToSearchResults,
      enabled: query.trim().length >= MINIMUM_QUERY_LENGTH,
    }
  );
}
