import { useMemo } from 'react';
import get from 'lodash-es/get';
import { useLocation } from 'react-router-dom';
import { Link } from 'components/Link';
import { useGridSorting } from 'hooks/useGridSorting';
import { useGridPagination } from 'hooks/useGridPagination';
import { NetworkOrderPageParams } from 'features/network-orders/networkOrderPageParams';
import { ColumnMetaData, useGridFiltering } from 'hooks/useGridFiltering';
import { PaginatedTable } from 'components/PaginatedTable';
import { formatDate } from 'utils/dateUtils';
import { useLayoutPaginatedTable } from 'hooks/useLayoutPaginatedTable';
import { BigLoading } from 'components/BigLoading';
import { Box, Flex, Text } from '@chakra-ui/react';
import { useShipVias } from 'queries/shipVias/useShipVias';
import { NetworkOrder } from 'types/networkOrder';
import { useNetworkOrders } from 'queries/networkOrders/useNetworkOrders';
import { NETWORK_ORDER_STATUSES } from 'constants/network-order-statuses';

export interface NetworkOrdersGridProps extends NetworkOrderPageParams {}

export function NetworkOrdersGrid(props: NetworkOrdersGridProps) {
  const location = useLocation();
  const availableStatus = [...NETWORK_ORDER_STATUSES];
  const initialStatus = get(props.initialFilters, 'status');
  const { data: shipVias } = useShipVias();
  const gridSorting = useGridSorting<NetworkOrder>({
    getQueryState: () => queryState,
    initialSort: props.initialSort,
    onSortChange: props.onSortByChange,
  });

  const gridPagination = useGridPagination({
    initialPage: props.initialPage,
    initialPageSize: props.initialPageSize,
    onPaginationChange: props.onPaginationChange,
  });

  const columnsMetaData: {
    [key in keyof Partial<NetworkOrder>]: ColumnMetaData;
  } = {
    noName: { dataType: 'text' },
    status: {
      dataType: 'select',
      selectOptions: availableStatus as Array<string>,
      formatOptions: true,
    },
    shipVia: {
      dataType: 'select',
      selectOptions: shipVias?.map(({ shipVia }) => shipVia) ?? [],
    },
    shipmentCount: { dataType: 'number' },
    createdAt: { dataType: 'date-range' },
  };

  const gridFiltering = useGridFiltering<NetworkOrder>({
    columnsMetaData,
    initialFilters: props.initialFilters,
    onFilterChange: props.onFilterChange,
  });

  const filters = {
    ...gridFiltering.filters,
    status: initialStatus ? initialStatus.split(',') : availableStatus,
  };

  const queryState = useNetworkOrders({
    pagination: { skip: gridPagination.skip, limit: gridPagination.limit },
    filters,
    sort: gridSorting.sort,
  });

  const { widthWindow, getColumnWidth } = useLayoutPaginatedTable(780);

  const columns = useMemo(
    () => [
      {
        Header: 'Order Number',
        accessor: 'noName',
        width: getColumnWidth(25, 160),
        Cell: ({ value }: { value: string }) => (
          <Link
            to={`/network-orders/${encodeURIComponent(value)}${
              location.search
            }`}
            fontWeight="bold"
            _hover={{ textDecoration: 'underline' }}
          >
            {value}
          </Link>
        ),
      },
      {
        Header: 'Status',
        id: 'status',
        accessor: 'status',
        width: getColumnWidth(20, 140),
      },
      {
        Header: 'Created At',
        accessor: 'createdAt',
        width: getColumnWidth(25, 160), //
        Cell: ({ value }: { value: string }) => <>{formatDate(value)}</>,
        getValue: formatDate,
      },
      {
        Header: 'Shipment Count',
        accessor: 'shipmentCount',
        isNumeric: true,
        width: getColumnWidth(15, 180), //
      },
      {
        Header: 'Ship Via',
        accessor: 'shipVia',
        width: getColumnWidth(15, 140),
      },
    ],
    [location.search, getColumnWidth]
  );

  return widthWindow ? (
    <Box>
      <Flex
        justifyContent="space-between"
        alignItems={[null, 'center']}
        flexDirection={['column', null, 'row']}
      >
        <Text as="h2" variant="pageTitle">
          Orders
        </Text>
      </Flex>
      <PaginatedTable
        columns={columns}
        gridPagination={gridPagination}
        gridSorting={gridSorting}
        gridFiltering={gridFiltering}
        queryState={queryState}
        tableName="network_orders"
        isResizable
      />
    </Box>
  ) : (
    <BigLoading />
  );
}
