import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { SalesOrderSummaryDto } from 'types/salesOrders';
import { mapDtoToOrderSummary } from './mappers';

export function useOrderSummary() {
  return useQuery(
    ['salesOrderSummary'],
    async () => {
      const { data } = await axios.get<SalesOrderSummaryDto>(
        '/salesorder/summary'
      );

      return data;
    },
    {
      select: mapDtoToOrderSummary,
    }
  );
}
