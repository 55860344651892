import {
  Box,
  Collapse,
  IconButton,
  SimpleGrid,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { ErrorBoundary } from 'components/ErrorBoundary';
import SharedStyles from 'styles/shared.module.css';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
  NetworkOrderDetails as NetworkOrderDetailsType,
  NetworkOrderStatus,
} from 'types/networkOrder';
import { Address } from 'features/order-details/components/ViewOrder/Address';
import { NetworkOrderLinesTable } from './components/ViewOrder/NetworkOrderLinesTable';
import { StatusChangeList } from 'features/order-details/components/ViewOrder/StatusChangeList';
import { useMemo } from 'react';
import {
  PROCESSING_STATUS,
  INCOMPLETE_STATUS,
  BAD_STATUS,
  SUCCESS_STATUS,
} from 'constants/network-order-statuses';
import { ShipmentsTable } from './components/ViewOrder/ShipmentsTable';
import { StatusHistoryEntry } from 'types/status';
import { useCopyToClipboard } from 'hooks/useCopyToClipboard';

type Props = {
  order?: NetworkOrderDetailsType;
};

export function NetworkOrderDetails({ order }: Props) {
  const { isOpen: isVisibleShipTo, onToggle: onToggleVisibleShipTo } =
    useDisclosure();

  const { copy } = useCopyToClipboard(
    Object.values(order?.address ?? {}).join('\n'),
    {
      toastOptions: {
        status: 'info',
        description: 'Ship to address copied',
      },
    }
  );

  const EyeIcon = isVisibleShipTo ? VisibilityOffIcon : VisibilityIcon;

  const latestStatusColor = useMemo(() => {
    const status = (order?.status || '') as NetworkOrderStatus;

    if (PROCESSING_STATUS.includes(status)) return 'gray';
    else if (INCOMPLETE_STATUS.includes(status)) return 'secondaryOrange';
    else if (BAD_STATUS.includes(status)) return 'red';
    else if (SUCCESS_STATUS.includes(status)) return 'green';
    return 'gray';
  }, [order?.status]);

  const statusHistory = useMemo(() => {
    if (!order) return;
    return [
      {
        status: order.status,
        status_date: order.statusUpdatedAt,
      },
    ].filter(Boolean) as StatusHistoryEntry<NetworkOrderStatus>[];
  }, [order]);

  return (
    <Box aria-label="Network Order Details">
      <SimpleGrid
        columns={[1, null, 2]}
        mt={5}
        mb={[5, null, 3]}
        spacing={[3, null, 2]}
      >
        <StatusChangeList
          latestStatusColor={latestStatusColor}
          history={statusHistory}
        />
        <Box>
          <ErrorBoundary>
            <Text
              variant="boldBlue"
              fontSize="xl"
              display="flex"
              alignItems="center"
            >
              Ship To
              <IconButton
                ml={0.5}
                aria-label="Toggle Visible Ship To"
                size="sm"
                variant="ghost"
                color="primaryBlue.500"
                icon={<EyeIcon fontSize="small" />}
                onClick={onToggleVisibleShipTo}
              />
              <IconButton
                aria-label="Copy Ship To"
                size="sm"
                variant="ghost"
                color="primaryBlue.500"
                icon={<ContentCopyIcon fontSize="small" />}
                onClick={copy}
              />
            </Text>
            <Collapse in={isVisibleShipTo} animateOpacity unmountOnExit>
              <Address address={order?.address} />
            </Collapse>
          </ErrorBoundary>
        </Box>
      </SimpleGrid>

      <Box
        className={SharedStyles['horizontal-scroll']}
        mx={-2}
        overflow="auto"
      >
        <ErrorBoundary>
          <NetworkOrderLinesTable order={order} mt={30} />
        </ErrorBoundary>
        {order?.shipments?.length ? (
          <ErrorBoundary>
            <ShipmentsTable data={order.shipments} />
          </ErrorBoundary>
        ) : null}
      </Box>
    </Box>
  );
}
