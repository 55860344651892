import { Box, Button, Text } from '@chakra-ui/react';
import { SUBMIT_TEMPLATE_URL } from 'constants/templateLinks';

export function SendTemplates() {
  return (
    <Box mb={4}>
      <Text as="h3" variant="pageHeading" mb={2}>
        3. Send
      </Text>
      <Text fontWeight={500} mb={2}>
        Submit to the help center.
      </Text>

      <Box>
        <Button
          size="md"
          variant="cta"
          onClick={() => window.open(SUBMIT_TEMPLATE_URL, '_blank')}
          mt={2}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
}
