import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { InventoryItem, PaginatedInventoryResponseDto } from 'types/inventory';
import { mapDtoToInventoryItem } from './mappers';
import { SortState } from 'hooks/useGridSorting';
import { inventoryFieldToQuery } from 'features/inventory-list/inventoryPageParams';
import {
  parseAdvancedFilters,
  querifyFiltersForApi,
  querifySort,
} from 'utils/urlSearchParamsUtils';
import { QueryState } from 'types/queryState';

export type InventoryQueryResult = QueryState<InventoryItem>;

export function useInventory(
  pagination: {
    skip: number;
    limit: number;
  },
  filters: { [key: string]: string } = {},
  sort?: SortState<InventoryItem>
): InventoryQueryResult {
  const sortQuery = querifySort(sort, inventoryFieldToQuery)
    .replace('-range', '')
    .replace('-select', '');
  const filtersQuery = parseAdvancedFilters(
    querifyFiltersForApi(filters, inventoryFieldToQuery)
  );
  const queryResult = useQuery(
    [
      'inventory',
      ...Object.values({ ...pagination, ...filtersQuery }),
      sortQuery,
    ],
    async () => {
      const {
        data: { items, total_count, total_filtered_count },
      } = await axios.get<PaginatedInventoryResponseDto>('/inventory', {
        params: {
          sort: sortQuery,
          ...pagination,
          ...filtersQuery,
        },
      });

      return [items, total_count, total_filtered_count] as const;
    }
  );

  const [data = [], count = 0, filteredCount = 0] = queryResult.data ?? [];

  return {
    ...queryResult,
    data: data.map(mapDtoToInventoryItem),
    count,
    filteredCount,
  } as InventoryQueryResult;
}
