import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { MsaDocument } from 'types/msa';

export function useMsaDocuments(
  queryOptions?: Parameters<typeof useQuery<MsaDocument[]>>[2]
) {
  return useQuery<MsaDocument[]>(
    ['msa-documents'],
    async () => {
      const { data } = await axios.get<MsaDocument[]>('/admin/msa');
      return data;
    },
    queryOptions
  );
}
