import { Center, Stat, StatLabel, StatNumber, Tooltip } from '@chakra-ui/react';
import LaunchIcon from '@mui/icons-material/Launch';
import { Link, LinkProps } from './Link';
import { Shimmer } from './Shimmer';
import HelpIcon from '@mui/icons-material/Help';

interface StatLinkProps extends LinkProps {
  number: number;
  label: string;
  isLoading?: boolean;
  tooltip?: string;
}

export const StatLink = ({
  number,
  label,
  isLoading,
  color = 'secondaryOrange.500',
  tooltip,
  ...linkProps
}: StatLinkProps) => {
  return (
    <Stat
      size="4xl"
      sx={{
        dl: {
          display: 'flex',
          flexDir: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        },
      }}
    >
      <Link color={color} {...linkProps}>
        <Center fontSize="md">
          <Shimmer h="30px" w="60px" my={3} isLoaded={!isLoading}>
            <StatNumber mr={1.5}>{number.toLocaleString('en-US')}</StatNumber>
            <LaunchIcon fontSize="inherit" />
          </Shimmer>
        </Center>
      </Link>
      <StatLabel display="flex" alignItems="center" whiteSpace="nowrap">
        {label}
        {tooltip ? (
          <Tooltip label={tooltip}>
            <HelpIcon sx={{ ml: 0.5 }} fontSize="small" />
          </Tooltip>
        ) : null}
      </StatLabel>
    </Stat>
  );
};
