import { createContext, useContext, useReducer } from 'react';

const STORE_CHANGED_ACTION = 'STORE_CHANGED_ACTION';

const StoreContext = createContext({ state: null, dispatch: null } as {
  state: any;
  dispatch: any;
});

function reducer(state: any, action: any) {
  switch (action.type) {
    case STORE_CHANGED_ACTION:
      return { ...state, ...action.data };
    default:
      return state;
  }
}

export function useStore() {
  const { state, dispatch } = useContext(StoreContext);

  return [
    state,
    (data: any) => {
      dispatch({ data, type: STORE_CHANGED_ACTION });
    },
  ];
}

export function StoreProvider({
  children,
  initialState,
}: {
  children: React.ReactNode;
  initialState?: any;
}) {
  const [state, dispatch] = useReducer(reducer, initialState ?? null);

  return (
    <StoreContext.Provider value={{ state, dispatch }}>
      {children}
    </StoreContext.Provider>
  );
}
