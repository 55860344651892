/* istanbul ignore file */
import { Box, Text } from '@chakra-ui/react';
import withLayout from 'hoc/withLayout';
import { PRIMARY_BLUE_500 } from 'theme/ui-palette';

function NotImplementedComponent() {
  return (
    <Box>
      <Text as="h2" fontSize="5xl" fontWeight="300" color={PRIMARY_BLUE_500}>
        Unavailable Page
      </Text>
      <Text fontSize="xl">This page was not implemented yet.</Text>
    </Box>
  );
}

export const NotImplementedPage = withLayout(NotImplementedComponent);
