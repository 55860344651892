import { Box } from '@chakra-ui/react';

type AsteriskIconProp = {
  width?: string;
};

const AsteriskIcon = ({ width = '8px' }: AsteriskIconProp) => {
  return (
    <>
      &nbsp;
      <Box>
        <svg
          viewBox="0 0.587 24 22.827"
          xmlns="http://www.w3.org/2000/svg"
          width={width}
        >
          <path
            d="M9.797 0.587v8.872L1.361 6.718 0 10.906l8.437 2.742 -5.215 7.177 3.565 2.588 5.214 -7.177 5.213 7.176 3.563 -2.588 -5.215 -7.175L24 10.907l-1.361 -4.188 -8.438 2.741V0.589h-4.405V0.587z"
            fill="#E53E3E"
          />
        </svg>
      </Box>
    </>
  );
};

export default AsteriskIcon;
