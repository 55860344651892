import { ComponentStyleConfig } from '@chakra-ui/react';

export const cardTheme: ComponentStyleConfig = {
  baseStyle: {
    borderRadius: '2xl',
    p: 8,
  },
  variants: {
    outline: {
      border: '2px solid',
      borderColor: 'secondaryTeal.400',
    },
  },
  defaultProps: {
    variant: 'outline',
  },
};
