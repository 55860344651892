import withLayout from 'hoc/withLayout';
import { Box, Flex } from '@chakra-ui/react';
import { DownloadTemplates } from 'features/template/DownloadTemplates';
import { InstructionTemplates } from 'features/template/Instruction';
import { ModifyTemplates } from 'features/template/ModifyTemplates';
import { SendTemplates } from 'features/template/SendTemplates';

const TemplatesPage = () => {
  return (
    <Box>
      <Flex
        gap={{ base: 12, lg: 24 }}
        direction={{ base: 'column', lg: 'row' }}
      >
        <Flex direction="column" flex={1} gap={4} position="relative">
          <InstructionTemplates />
          <Box>
            <DownloadTemplates />
            <ModifyTemplates />
            <SendTemplates />
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
};

export default withLayout(TemplatesPage);
