import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import {
  PaginatedPurchaseOrdersResponseDto,
  PurchaseOrder,
} from 'types/purchaseOrders';
import { mapDtoToPurchaseOrder } from './mappers';
import { SortState } from 'hooks/useGridSorting';
import {
  parseAdvancedFilters,
  querifyFiltersForApi,
  querifySort,
} from 'utils/urlSearchParamsUtils';
import { poFieldToQuery } from 'features/purchase-orders/purchaseOrderPageParams';
import { isEmpty } from 'lodash-es';
import { QueryState } from 'types/queryState';

export type PurchaseOrderQueryResult = QueryState<PurchaseOrder>;

export function usePurchaseOrders(
  pagination: {
    skip: number;
    limit: number;
  },
  filters: { [key: string]: string | string[] } = {},
  sort?: SortState<PurchaseOrder>
): PurchaseOrderQueryResult {
  const sortQuery = querifySort(sort, poFieldToQuery)
    .replace('-range', '')
    .replace('-select', '');
  const { status, ...restOfFilters } = filters;
  const filtersQuery = parseAdvancedFilters(
    querifyFiltersForApi(restOfFilters, poFieldToQuery)
  );

  const queryResult = useQuery(
    [
      'purchaseOrders',
      ...Object.values({ status, ...pagination, ...filtersQuery }),
      sortQuery,
    ],
    async () => {
      const {
        data: { items, total_count, total_filtered_count },
      } = await axios.get<PaginatedPurchaseOrdersResponseDto>(
        '/purchaseorder',
        {
          params: {
            sort: sortQuery,
            ...(!isEmpty(status) ? { latest_status: status } : undefined),
            ...pagination,
            ...filtersQuery,
          },
        }
      );

      return [items, total_count, total_filtered_count] as const;
    }
  );

  const [data = [], count = 0, filteredCount = 0] = queryResult.data ?? [];

  return {
    ...queryResult,
    data: data.map(mapDtoToPurchaseOrder),
    count,
    filteredCount,
  } as PurchaseOrderQueryResult;
}
