import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import { Box, IconButton } from '@chakra-ui/react';
import { Modal } from 'components/Modal';
import { useAppContext } from 'contexts/appContext';
import { SECONDARY_ORANGE_500 } from 'theme/ui-palette';

function IdeaSubmission() {
  const { disclosureIdeaSubmission: disclosure } = useAppContext();
  return (
    <>
      <IconButton
        title="Click here to submit your idea for the portal!"
        aria-label="Idea Submission"
        onClick={disclosure.onOpen}
        icon={<TipsAndUpdatesIcon sx={{ color: SECONDARY_ORANGE_500 }} />}
        variant="ghost"
        size="sm"
      />
      <Modal
        isOpen={disclosure.isOpen}
        onClose={disclosure.onClose}
        showCloseButton={true}
        size={['full', '2xl']}
        modalContentProps={{
          'aria-label': 'Idea Submission Modal',
          height: '100%',
        }}
        modalBodyProps={{
          position: 'relative',
          flex: '1 0 auto',
          p: 0,
        }}
        scrollVisible={true}
      >
        <Box
          position="absolute"
          inset="0"
          height="100%"
          width="100%"
          display="flex"
          justifyContent="center"
          overflow="hidden"
        >
          <iframe
            data-testid="ideaForm"
            title="Standvast Idea Form"
            src="https://docs.google.com/forms/d/e/1FAIpQLSdBenpMLes-gHDFIxN3ZR9ssTJgiDXg5Lizqbv67SECa4xlfA/viewform?embedded=true"
            width="640"
          ></iframe>
        </Box>
      </Modal>
    </>
  );
}

export default IdeaSubmission;
