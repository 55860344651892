import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

export function useCancelPurchaseOrder(poNumber: string) {
  const queryClient = useQueryClient();
  return useMutation(
    () => {
      return axios.delete(`/purchaseorder/${poNumber}`);
    },
    {
      onSuccess: () =>
        queryClient.invalidateQueries({ queryKey: ['purchaseOrders'] }),
    }
  );
}
