import { useNavigate, useLocation } from 'react-router-dom';
import { SchedulePurchaseOrderModal } from 'features/schedule-purchase-order/SchedulePurchaseOrderModal';
import { CONFIG } from 'utils/config';
import { usePortal } from 'hooks/usePortal';

export function SchedulePurchaseOrderPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const urlSegments = location.pathname.split('/');
  const firstUrlSegment = urlSegments[1] ?? '';
  const { isPortalReadOnly } = usePortal();

  const handleClose = () => {
    navigate(`/${firstUrlSegment}`);
  };

  return CONFIG().featureToggles.enableSchedulePurchaseOrder &&
    !isPortalReadOnly ? (
    <SchedulePurchaseOrderModal onClose={handleClose} />
  ) : null;
}
