export const CARRIER_TRACKING = [
  {
    url: 'https://www.fedex.com/apps/fedextrack/?action=track&trackingnumber=@tracking_number',
    startCode: ['FED', 'FDX'],
  },
  {
    url: 'https://wwwapps.ups.com/tracking/tracking.cgi?tracknum=@tracking_number',
    startCode: ['UPS'],
  },
  {
    url: 'https://tools.usps.com/go/TrackConfirmAction?tLabels=@tracking_number',
    startCode: ['USP'],
  },
  {
    url: 'https://www.ontrac.com/tracking/?number=@tracking_number',
    startCode: ['ONT'],
  },
];
