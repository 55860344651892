import { ComponentStyleConfig } from '@chakra-ui/react';

export const statTheme: ComponentStyleConfig = {
  baseStyle: {
    number: {
      fontWeight: 800,
    },
  },
  variants: {
    primary: {
      number: {
        color: 'primaryBlue.500',
      },
    },
    // Ideally, these two variants should get merged into a single one.
    // If Tim approves it, we'll keep one and discard the other.
    primaryWithThinLabel: {
      number: {
        color: 'primaryBlue.500',
      },
      label: {
        fontWeight: 300,
      },
    },
    link: {
      number: {
        color: 'secondaryOrange.500',
      },
    },
  },
  sizes: {
    '8xl': {
      label: {
        fontSize: 'xl',
        fontWeight: 400,
      },
      number: {
        fontSize: '8xl',
      },
    },
    '4xl': {
      label: {
        fontSize: 'md',
        fontWeight: 400,
      },
      number: {
        fontSize: '4xl',
      },
    },
    '2xl': {
      label: {
        fontSize: 'sm',
        fontWeight: 300,
      },
      number: {
        fontSize: '2xl',
        lineHeight: 'normal',
      },
    },
  },
  defaultProps: {
    size: '2xl',
  },
};
