import { Text } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { TransportationDetailsForm } from 'features/reports/transportation-details/TransportationDetailsForm';
import { FormProvider, useForm } from 'react-hook-form';
import { exportTransporationDetailsSchema } from 'schemas/exportTransportationDetails';

export function TransportationDetailsPage() {
  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(exportTransporationDetailsSchema),
  });

  return (
    <FormProvider {...methods}>
      <Text mb={3} as="h2" variant="pageTitle">
        Transportation Details
      </Text>
      <TransportationDetailsForm />
    </FormProvider>
  );
}
