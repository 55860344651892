import {
  Button,
  Flex,
  FlexProps,
  Image,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import emptyStateIcon from 'assets/empty-state-small.svg';
import networkErrorStateDesktopIcon from 'assets/network-error-state--desktop.svg';
import networkErrorStateMobileIcon from 'assets/network-error-state--mobile.svg';

export type ExceptionStateProps = FlexProps & {
  stateType: 'empty' | 'error';
  customErrorMessage?: string;
  customIcon?: string;
  customTitle?: string;
  customTryAgainText?: string;
  retryHandler?: () => void;
  isCenteredOnMobile?: boolean;
};

export function ExceptionState({
  stateType,
  customTitle,
  customIcon,
  customErrorMessage,
  customTryAgainText,
  retryHandler,
  isCenteredOnMobile = false,
  ...props
}: ExceptionStateProps) {
  const { icon, title, message } = useExceptionStateValues(stateType);

  return (
    <Flex
      gap={10}
      mt={10}
      flexWrap={['wrap', null, 'nowrap']}
      justifyContent="center"
      alignItems="flex-start"
      {...props}
    >
      <Image src={customIcon ?? icon} alt={customTitle ?? title} />
      <Flex
        direction="column"
        alignItems={[
          isCenteredOnMobile ? 'center' : 'flex-start',
          null,
          'flex-start',
        ]}
        textAlign={[isCenteredOnMobile ? 'center' : 'left', null, 'left']}
      >
        <Text
          as="h2"
          variant="pageTitle"
          fontSize={['xl', '2xl']}
          fontWeight="400"
        >
          {customTitle ?? title}
        </Text>
        <Text fontSize={['md', 'lg']} fontWeight="400" mt={2} role="alert">
          {customErrorMessage ?? message}
        </Text>
        {retryHandler && (
          <Button
            variant="link"
            onClick={retryHandler}
            fontSize={['md', 'lg']}
            mt={5}
          >
            {customTryAgainText ?? 'Click here to try again.'}
          </Button>
        )}
      </Flex>
    </Flex>
  );
}

const useExceptionStateValues = (
  stateType: ExceptionStateProps['stateType']
) => {
  const networkErrorStateIcon = useBreakpointValue(
    { base: networkErrorStateMobileIcon, md: networkErrorStateDesktopIcon },
    {
      fallback: 'base',
    }
  )!;

  return {
    icon: stateType === 'empty' ? emptyStateIcon : networkErrorStateIcon,
    title: getExceptionStateText(stateType, 'title'),
    message: getExceptionStateText(stateType, 'message'),
  };
};

const getExceptionStateText = (
  stateType: ExceptionStateProps['stateType'],
  valueType: 'title' | 'message'
) =>
  ({
    title: stateType === 'empty' ? 'Nothing to display' : 'Connection error',
    message:
      stateType === 'empty'
        ? 'Looks like you have nothing to display. If you suspect this is in error, please contact customer service.'
        : "There's an issue loading what you're looking for.",
  }[valueType]);
