import {
  Box,
  Text,
  BoxProps,
  useStyleConfig,
  Divider,
  Button,
  Spacer,
  Flex,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { ErrorBoundary } from './ErrorBoundary';

type CardCTA = {
  label: string;
  href: string;
  icon?: React.ReactNode;
  onClick?: () => void;
};

interface Props extends BoxProps {
  title: string;
  variant?: string;
  children: React.ReactNode;
  primaryCTA?: CardCTA;
  secondaryCTA?: CardCTA;
}

export function DashboardCard({
  title,
  variant,
  children,
  primaryCTA,
  secondaryCTA,
  ...props
}: Props) {
  const styles = useStyleConfig('DashboardCard', { variant });

  return (
    <Box
      __css={styles}
      {...props}
      height="100%"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      <ErrorBoundary>
        <Text as="h2" variant="boldBlue" fontSize="2xl">
          {title}
        </Text>
        <Divider />
        <Box flexGrow="1">{children}</Box>
        {primaryCTA || secondaryCTA ? (
          <Flex
            gap={5}
            mt={2}
            flexDirection={{ base: 'column-reverse', md: 'row-reverse' }}
          >
            {primaryCTA ? (
              <Button
                as={Link}
                to={primaryCTA.href}
                size="block"
                variant="cta"
                onClick={primaryCTA.onClick ?? (() => {})}
              >
                {primaryCTA.label}
                {primaryCTA.icon}
              </Button>
            ) : (
              <Spacer />
            )}
            {secondaryCTA ? (
              <Button
                as={Link}
                to={secondaryCTA.href}
                size="block"
                onClick={secondaryCTA.onClick ?? (() => {})}
              >
                {secondaryCTA.label}
                {secondaryCTA.icon}
              </Button>
            ) : (
              <Spacer />
            )}
          </Flex>
        ) : null}
      </ErrorBoundary>
    </Box>
  );
}
