import { useAuth0 } from '@auth0/auth0-react';
import { AUTH0_ROLES } from 'constants/auth0Roles';

export const useAuth0Roles = (
  expectOneOfRoles: Array<string> = [
    AUTH0_ROLES.STANDVAST_ADMIN,
    AUTH0_ROLES.SUPER_USER,
  ]
): boolean => {
  const { user } = useAuth0();
  const normalizedExpectedRoles = expectOneOfRoles.map((role) =>
    role.toLowerCase()
  );

  const userRoles: string[] =
    (user && user[`${window.location.origin}/roles`]) || [];
  const hasOneOfRoles = userRoles.some((r) => {
    const normalizedRole = r.toLowerCase();
    return normalizedExpectedRoles.includes(normalizedRole);
  });

  return hasOneOfRoles;
};
