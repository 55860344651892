import 'react-datepicker/dist/react-datepicker.css';
import { forwardRef, MouseEventHandler, Ref, useId, useState } from 'react';
import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputProps,
  ResponsiveValue,
  Tooltip,
} from '@chakra-ui/react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { Hidden } from './Hidden';
import AsteriskIcon from './AsteriskIcon';
import { parseDateInUTC, toISOInUTC } from 'utils/dateUtils';
import HelpIcon from '@mui/icons-material/Help';
import { Nullable } from 'types/utilities';

export type BaseFormDatePickerProps = {
  onMouseEnter?: MouseEventHandler;
  variant?: ResponsiveValue<string>;
  size?: 'xs' | 'sm' | 'md';
  iconSize?: 'medium' | 'small';
  'aria-label'?: string;
  error?: string;
  label?: string;
  isRequired?: boolean;
  tooltip?: string;
  placeholder?: string;
};

export type FormDatePickerProps = BaseFormDatePickerProps &
  Omit<
    ReactDatePickerProps<never, false>,
    | 'selected'
    | 'onChange'
    | 'defaultValue'
    | 'placeholderText'
    | 'selectsRange'
  > & {
    defaultValue?: string | Date;
    onChange?: (value: string, date?: Date | null) => void;
  };

export function FormDatePicker({
  variant = 'outline',
  size = 'md',
  iconSize = 'medium',
  defaultValue,
  onChange,
  isClearable,
  'aria-label': ariaLabel,
  error,
  onMouseEnter,
  disabled,
  label,
  isRequired,
  tooltip,
  placeholder,
  ...props
}: FormDatePickerProps) {
  const id = useId();

  const [date, setDate] = useState<Nullable<Date>>(
    defaultValue ? parseDateInUTC(defaultValue as string | Date) : null
  );

  const handleChange = (newDate: Nullable<Date>) => {
    setDate(newDate);
    if (onChange) {
      onChange(newDate ? toISOInUTC(newDate) : '', newDate);
    }
  };

  return (
    <FormControl isInvalid={!!error}>
      {label ? (
        <FormLabel display="flex" opacity={disabled ? 0.4 : 1}>
          {label}
          {tooltip ? (
            <Tooltip label={tooltip}>
              <HelpIcon
                fontSize="inherit"
                sx={{
                  marginLeft: '0.3rem',
                  width: '1.3rem',
                  height: '1.3rem',
                }}
              />
            </Tooltip>
          ) : null}
          {isRequired ? <AsteriskIcon width="0.375rem" /> : null}
        </FormLabel>
      ) : null}

      <Flex
        alignItems="center"
        sx={{
          '& .react-datepicker-popper': {
            zIndex: 2,
          },
        }}
      >
        <DatePicker
          {...props}
          placeholderText={placeholder}
          id={id}
          selectsRange={false}
          selected={date}
          onChange={handleChange}
          disabled={disabled}
          customInput={
            <AdapterInput
              isDisabled={disabled}
              aria-label={ariaLabel}
              type={isClearable ? 'search' : 'text'}
              size={size}
              variant={variant}
              onMouseEnter={onMouseEnter}
            />
          }
        />
        <Flex as="label" htmlFor={id} marginLeft="1">
          <Hidden>{ariaLabel}</Hidden>
          <CalendarTodayIcon fontSize={iconSize} />
        </Flex>
      </Flex>
      {error && <FormErrorMessage>{error}</FormErrorMessage>}
    </FormControl>
  );
}

const AdapterInput = forwardRef(
  (props: InputProps, ref: Ref<HTMLInputElement>) => (
    <Input ref={ref} {...props} />
  )
);
