import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Tooltip,
  useBreakpointValue,
} from '@chakra-ui/react';
import { Flyout } from 'components/Flyout';
import { Modal } from 'components/Modal';
import { PaginatedTable } from 'components/PaginatedTable';
import { useCallback, useMemo, useState } from 'react';
import { Column } from 'react-table';
import { QueryState } from 'types/queryState';
import ClearIcon from '@mui/icons-material/Clear';
import { CopyableText } from 'components/CopyableText';
import { useLayoutPaginatedTable } from 'hooks/useLayoutPaginatedTable';
import { useConfirmationDialog } from 'hooks/useConfirmationDialog';
import { useListInvitations } from 'queries/admin/useListInvitations';
import { Invitation } from 'types/invitation';
import { useInviteUser } from 'mutations/admin/useInviteUser';
import { useRevokeInvitation } from 'mutations/admin/useRevokeInvitation';

const InvitationModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
}> = ({ isOpen, onClose }) => {
  const [email, setEmail] = useState('');
  const { mutateAsync: sendInvitation, isLoading: isInviting } =
    useInviteUser();

  const sendInvitationHandler = useCallback(() => {
    sendInvitation(
      {
        emailAddress: email,
      },
      { onSuccess: onClose }
    );
  }, [email, onClose, sendInvitation]);

  return (
    <Modal
      title="Invite User"
      isOpen={isOpen}
      onClose={onClose}
      size={['full', null, '6xl']}
      showCloseButton
      buttons={[
        {
          label: 'Send Invitation',
          handler: sendInvitationHandler,
          isDisabled: isInviting,
        },
      ]}
    >
      <FormControl>
        <FormLabel>Email address</FormLabel>
        <Input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          readOnly={isInviting}
        />
      </FormControl>
    </Modal>
  );
};

const InvitationsTable: React.FC = () => {
  const { getColumnWidth } = useLayoutPaginatedTable(0);
  const columnPercentages = useBreakpointValue(
    {
      base: [45, 40, 15],
      md: [30, 60, 10],
      xl: [30, 65, 5],
    },
    { fallback: 'base' }
  )!;

  const invitationsResult = useListInvitations({ refetchOnMount: false });

  const { mutateAsync: deleteInvitation, isLoading: isRevoking } =
    useRevokeInvitation();

  const [createInvitationOpen, setCreateInvitationOpen] = useState(false);
  const { openConfirmationDialog } = useConfirmationDialog();

  const handleDelete = useCallback(
    (invitationId: string) => {
      openConfirmationDialog({
        title: 'Cancel Invitation',
        message: `Are you sure you want to cancel this invitation?`,
        onConfirm: () => deleteInvitation({ id: invitationId }),
        blockScrollOnMount: false,
        cancelButtonLabel: 'No',
        confirmButtonLabel: 'Yes',
      });
    },
    [deleteInvitation, openConfirmationDialog]
  );

  const handleInviteUser = useCallback(() => {
    setCreateInvitationOpen(true);
  }, [setCreateInvitationOpen]);

  const handleCloseInvite = useCallback(() => {
    setCreateInvitationOpen(false);
    invitationsResult.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setCreateInvitationOpen, invitationsResult.refetch]);

  const adminInvitationsColumns = useMemo(
    () =>
      [
        {
          Header: 'Email',
          accessor: 'emailAddress',
          width: getColumnWidth(columnPercentages[0]),
          Cell: ({ value: email_address }: { value: string }) => (
            <Box display="flex" alignItems="center" h="100%">
              <Flyout w="250px" maxW="100%">
                {email_address}
              </Flyout>
            </Box>
          ),
        },
        {
          Header: 'Invitation URL',
          accessor: 'invitationUrl',
          width: getColumnWidth(columnPercentages[1]),
          Cell: ({ value: invitation_url }: { value: string }) => (
            <CopyableText
              text={invitation_url}
              copiedMessage="Invitation URL copied."
              h="100%"
              alignItems="center"
              textProps={{
                sx: {
                  maxW: '95%',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                },
              }}
            />
          ),
        },
        {
          accessor: 'id',
          width: getColumnWidth(columnPercentages[2]),
          Cell: ({ value: id }: { value: string }) => (
            <Box display="flex" alignItems="center" justifyContent="center">
              <Tooltip label="Cancel Invitation">
                <Button
                  aria-label="Delete Button"
                  onClick={() => handleDelete(id)}
                  fontSize="md"
                  variant="redSquareButton"
                  rounded="md"
                  h="24px"
                  w="24px"
                  minW="24px"
                  p="0"
                >
                  <ClearIcon fontSize="inherit" />
                </Button>
              </Tooltip>
            </Box>
          ),
        },
      ] as Column<any>[],
    // eslint-disable-next-line
    [getColumnWidth, columnPercentages]
  );

  const isLoading =
    invitationsResult.isLoading || invitationsResult.isFetching || isRevoking;

  return (
    <>
      <Stack>
        <Button
          onClick={handleInviteUser}
          fontSize="md"
          variant="redSquareButton"
          alignSelf="flex-end"
          rounded="md"
        >
          Invite User
        </Button>
        <PaginatedTable
          columns={adminInvitationsColumns}
          queryState={
            {
              data: isLoading ? [] : invitationsResult.data || [],
              isLoading,
            } as QueryState<Invitation>
          }
          tableName="admin_users_table"
          isResizable
        />
      </Stack>
      <InvitationModal
        isOpen={createInvitationOpen}
        onClose={handleCloseInvite}
      />
    </>
  );
};

export default InvitationsTable;
