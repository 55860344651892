import { SimpleGrid } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { FormInput } from 'components/FormInput';
import { SalesOrder } from 'types/salesOrders';
import { isReshipOrder } from 'utils/salesOrdersUtils';
import { StorefrontSelect } from './StorefrontSelect';

type Props = {
  startTabIndex: number;
};

export const BasicInfoForm: React.FC<Props> = ({ startTabIndex }) => {
  const { register, getValues } = useFormContext<SalesOrder>();
  const draftOrder = getValues();

  return (
    <SimpleGrid columns={[1, 2]} columnGap={8}>
      <FormInput
        tabIndex={startTabIndex + 1}
        label="Sales Order #"
        {...register('soNumber')}
        isReadOnly={Boolean(isReshipOrder(draftOrder.soNumber))}
        isRequired
        tooltip="The order number from your system."
      />
      <FormInput
        tabIndex={startTabIndex + 2}
        label="Purchase Order #"
        {...register('poNumber')}
        tooltip="Optional. A secondary reference number for this order."
      />
      <StorefrontSelect tabIndex={startTabIndex + 3} />
    </SimpleGrid>
  );
};
