import { UNIT_OF_MEASURE_EACHES } from 'constants/unitOfMeasure';
import {
  CrudActionType,
  DtoFieldError,
  DtoFieldErrorSegment,
} from 'hooks/useCrudActionUserFeedback';
import {
  PurchaseOrder,
  PurchaseOrderLine,
  PurchaseOrderLineDto,
  PurchaseOrderDto,
} from 'types/purchaseOrders';
import { formatDate } from 'utils/dateUtils';

export function mapLinesToDtos(
  lines: PurchaseOrderLine[]
): PurchaseOrderLineDto[] {
  return lines?.map((line) => ({
    line_number: line.lineNo,
    sku: line.sku,
    description: line.description,
    uom: UNIT_OF_MEASURE_EACHES,
    external_item_number: line.customerItemNumber,
    order_qty: +line.orderQty,
    received_qty: line.receivedQty,
    short_qty: line.shortQty,
    damaged_qty: line.damagedQty,
    overage_qty: line.overageQty,
  }));
}

export function mapPurchaseOrderToDto(order: PurchaseOrder): PurchaseOrderDto {
  return {
    po_number: order.poNumber,
    vendor_name: order.vendorName,
    container_numbers: order.containerNumbers.map(
      ({ containerNumber }) => containerNumber
    ),
    created_date: order.createdDate,
    target_dc: order.warehouseID,
    requested_delivery_date: formatDate(order.requestedDate, 'YYYY-MM-DD'),
    purchase_order_lines: mapLinesToDtos(order.lines),
    delivery_address: {
      name: order.deliveryAddress?.name ?? '',
      address: order.deliveryAddress?.address ?? '',
      city: order.deliveryAddress?.city ?? '',
      state: order.deliveryAddress?.state ?? '',
      zip_code: order.deliveryAddress?.zipCode ?? '',
      country: order.deliveryAddress?.country ?? '',
      email: order.deliveryAddress?.email ?? '',
      phone_number: order.deliveryAddress?.phoneNumber ?? '',
    },
    status_history: order.statusChangesHistory?.map((entry) => ({
      status: entry.status,
      date: entry.status_date,
      status_error_message: '',
    })),
  };
}

export function mapFieldDtoErrorToRhfPath(
  error: DtoFieldError,
  action?: CrudActionType
) {
  const path = [];
  const segments = error.loc?.slice(2) ?? [];

  for (let i = 0; i < segments.length; i++) {
    const segment = segments[i];
    let mappedSegment = null;

    if (i === 0) {
      mappedSegment = mapTopLevelDtoSegmentToRhfSegment(segment, action);
    } else if (segments[0] === 'purchase_order_lines' && i === 1) {
      mappedSegment = segment;
    } else if (segments[0] === 'purchase_order_lines' && i === 2) {
      mappedSegment = mapLinesDtoSegmentToRhfSegment(segment);
    }

    path.push(mappedSegment);
  }

  return path.length === 0 || path.some((s) => s === null)
    ? null
    : path.join('.');
}

function mapTopLevelDtoSegmentToRhfSegment(
  segment: DtoFieldErrorSegment,
  action?: CrudActionType
) {
  return (
    {
      requested_delivery_date: 'requestedDate',
      purchase_order_lines: 'lines',
      ...(action === 'CREATE'
        ? {
            target_dc: 'warehouseID',
            po_number: 'poNumber',
          }
        : {}),
    }[segment] ?? null
  );
}

function mapLinesDtoSegmentToRhfSegment(segment: DtoFieldErrorSegment) {
  return (
    {
      sku: 'sku',
      external_item_number: 'customerItemNumber',
      order_qty: 'orderQty',
    }[segment] ?? null
  );
}
