import { Box } from '@chakra-ui/react';
import { PaginatedTable } from 'components/PaginatedTable';
import { useMemo } from 'react';
import { Row } from 'react-table';
import { QueryState } from 'types/queryState';
import {
  SalesOrderShippingBoxesDetail,
  ShippingBoxItem,
} from 'types/salesOrders';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

type Props = {
  data: SalesOrderShippingBoxesDetail[];
};

export function OrderShippingBoxesTable({ data }: Props) {
  const columns = useMemo(
    () => [
      {
        id: 'expander',
        width: 40,
        Cell: ({ row }: any) => {
          return (
            <Box
              {...row.getToggleRowExpandedProps()}
              display="flex"
              justifyContent="center"
            >
              {row.isExpanded ? (
                <ExpandMoreIcon fontSize="small" />
              ) : (
                <ChevronRightIcon fontSize="small" />
              )}
            </Box>
          );
        },
      },
      {
        Header: 'Package',
        accessor: 'packageNumber',
        width: 250,
      },
      {
        Header: 'Tracking #',
        accessor: 'trackingNumber',
        width: 250,
      },
    ],
    []
  );

  return (
    <PaginatedTable
      aria-label="Shipping Boxes Table"
      columns={columns}
      queryState={
        {
          data,
          isLoading: !data,
        } as QueryState<SalesOrderShippingBoxesDetail>
      }
      tableName="shipping_boxes_table"
      isResizable
      renderSubRow={(row: Row<SalesOrderShippingBoxesDetail>) => (
        <ShippingBoxesDetailTable data={row.original.items} />
      )}
    />
  );
}

export function ShippingBoxesDetailTable({
  data,
}: {
  data: ShippingBoxItem[];
}) {
  const columns = useMemo(
    () => [
      {
        Header: 'SKU',
        accessor: 'sku',
        width: 250,
      },
      {
        Header: 'QTY',
        accessor: 'qty',
        width: 100,
      },
    ],
    []
  );

  return (
    <PaginatedTable
      mt={-4}
      mb={-1}
      pl={[4, 8]}
      columns={columns}
      queryState={
        {
          data,
          isLoading: !data,
        } as QueryState<ShippingBoxItem>
      }
      tableName="shipping_box_items_table"
      isResizable
    />
  );
}
