import { useEffect } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { Modal } from 'components/Modal';
import { useHandlerInterceptor } from 'hooks/useHandlerInterceptor';
import { SchedulePurchaseOrder } from './components/SchedulePurchaseOrder';

type Props = {
  onClose: () => void;
};

export function SchedulePurchaseOrderModal(props: Props) {
  const { onOpen: handleOpen, ...disclosure } = useDisclosure();
  const [handleClose, setCloseInterceptor] = useHandlerInterceptor(() => {
    disclosure.onClose();
    props.onClose();
  });

  useEffect(() => {
    handleOpen();
  }, [handleOpen]);

  return (
    <Modal
      title="Create PO"
      isOpen={disclosure.isOpen}
      onClose={handleClose}
      buttons={[]}
      size={['full', null, '7xl']}
    >
      <SchedulePurchaseOrder
        onAbort={props.onClose}
        onConfirm={props.onClose}
        setCloseInterceptor={setCloseInterceptor}
      />
    </Modal>
  );
}
