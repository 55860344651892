import { AxiosError } from 'axios';
import { useEffect } from 'react';
import { useDisclosure, useToast } from '@chakra-ui/react';
import { Modal } from 'components/Modal';
import { usePurchaseOrder } from 'queries/purchaseOrders/usePurchaseOrder';
import { useHandlerInterceptor } from 'hooks/useHandlerInterceptor';
import { isChangeable } from 'utils/purchaseOrdersUtils';
import { CancelPurchaseOrderPrompt } from './components/CancelPurchaseOrder/CancelPurchaseOrderPrompt';
import { PurchaseOrderDetails } from './components/ViewPurchaseOrder/PurchaseOrderDetails';
import { ModifyPurchaseOrder } from './components/ModifyPurchaseOrder/ModifyPurchaseOrder';
import { ExceptionState } from 'components/ExceptionState';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { sendViewPurchaseOrderDetailsEvent } from 'utils/monitorEvents';
import { usePortal } from 'hooks/usePortal';

type Props = {
  purchaseOrderNumber?: string;
  onClose: () => void;
  onAbort: () => void;
  onEdit: () => void;
  onCancel: () => void;
  isCancelling: boolean;
  isEditing: boolean;
};

export function PurchaseOrderDetailsModal(props: Props) {
  const { onOpen: handleOpen, ...disclosure } = useDisclosure();
  const {
    data: order,
    isFetching,
    error,
  } = usePurchaseOrder(props.purchaseOrderNumber!);
  const [handleClose, setCanClose] = useHandlerInterceptor(() => {
    disclosure.onClose();
    props.onClose();
  });
  const toast = useToast();
  const { isPortalReadOnly } = usePortal();

  useEffect(() => {
    if (!props.isEditing) setCanClose(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isEditing]);

  useEffect(() => {
    handleOpen();
  }, [handleOpen]);

  useEffect(
    () => sendViewPurchaseOrderDetailsEvent(order?.poNumber ?? ''),
    [order?.poNumber]
  );

  const isLoading = !order || isFetching;

  const doneButton = {
    label: 'Done',
    handler: handleClose,
  };
  const viewingModeButtons = [
    {
      label: 'Modify Order',
      handler: () => {
        props.onEdit();
      },
      visible: order && isChangeable(order),
      isDisabled: !order?.warehouseID,
    },
    {
      label: 'Cancel Order',
      handler: () => {
        props.onCancel();
      },
      disabledStateHandler: () =>
        toast({
          status: 'error',
          title: 'Error',
          description:
            'Cannot cancel at this time as the order has been received.',
        }),
      isDisabled: isLoading || !isChangeable(order),
    },
    doneButton,
  ];

  return (
    <Modal
      title={
        props.isCancelling
          ? ''
          : `${props.isEditing ? 'Modify ' : ''}PO #${
              props.purchaseOrderNumber
            }`
      }
      isOpen={disclosure.isOpen}
      onClose={handleClose}
      buttons={
        props.isCancelling || props.isEditing || isPortalReadOnly
          ? [doneButton]
          : viewingModeButtons
      }
      size={['full', null, props.isCancelling ? '3xl' : '7xl']}
      showCloseButton={!props.isCancelling}
      modalBodyProps={{
        display: props.isCancelling ? ['flex', null, 'block'] : 'block',
        mb: props.isCancelling ? -6 : 0,
      }}
    >
      {(error as AxiosError)?.response?.status === 404 ? (
        <ExceptionState
          customTitle="Purchase Order Not Found"
          stateType="empty"
        />
      ) : order && props.isCancelling ? (
        <ErrorBoundary>
          <CancelPurchaseOrderPrompt
            order={order}
            onConfirm={handleClose}
            onAbort={props.onAbort}
          />
        </ErrorBoundary>
      ) : order && props.isEditing ? (
        <ErrorBoundary>
          <ModifyPurchaseOrder
            order={order}
            onConfirm={props.onAbort}
            onAbort={props.onAbort}
            setCanClose={setCanClose}
          />
        </ErrorBoundary>
      ) : (
        <PurchaseOrderDetails order={isLoading ? undefined : order} />
      )}
    </Modal>
  );
}
