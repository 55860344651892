import axios from 'axios';
import { FileType } from 'utils/fileTypes';

export const useMsaFile = () => {
  const getPdfFile = async (fileName: string) => {
    const { data } = await axios.get(`/admin/msa/${fileName}`, {
      responseType: 'blob',
    });
    const blob = new Blob([data], { type: FileType.Pdf });
    return blob;
  };

  return {
    getPdfFile,
  };
};
