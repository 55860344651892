import { Organization, OrganizationDto } from 'types/organization';

export function mapDtoToOrganization(dto: OrganizationDto): Organization {
  return {
    id: dto.id,
    name: dto.name,
    uuid: dto.uuid,
    code: dto.code,
    auth0OrganizationId: dto.auth0_organization_id,
    auth0OrganizationName: dto.auth0_organization_name,
  };
}
