import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { InventorySummaryDto } from 'types/inventory';
import { mapDtoToInventorySummary } from './mappers';

export function useInventorySummary() {
  return useQuery(
    ['inventorySummary'],
    async () => {
      const { data } = await axios.get<InventorySummaryDto[]>(
        '/inventory/summary'
      );

      return data;
    },
    {
      select: (summaries: InventorySummaryDto[]) =>
        summaries.map(mapDtoToInventorySummary),
    }
  );
}
