import { Box, Text } from '@chakra-ui/react';

export function ModifyTemplates() {
  return (
    <Box mb={4}>
      <Text as="h3" variant="pageHeading" mb={2}>
        2. Modify
      </Text>
      <Text fontWeight={500} mb={2}>
        Make a copy of the template. Modify it offline with your information.
      </Text>
    </Box>
  );
}
