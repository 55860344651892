import { BigLoading } from 'components/BigLoading';
import { PaginatedTable } from 'components/PaginatedTable';
import { REPORT_TYPES } from 'constants/reports';
import { ColumnMetaData, useGridFiltering } from 'hooks/useGridFiltering';
import { useGridPagination } from 'hooks/useGridPagination';
import { useGridSorting } from 'hooks/useGridSorting';
import { useLayoutPaginatedTable } from 'hooks/useLayoutPaginatedTable';
import { useEffect, useMemo } from 'react';
import { Column } from 'react-table';
import { useDownloadReport } from 'utils/reportUtils';
import { AsnPageParams, dictFieldToQuery } from './asnPageParams';
import { AsnItem } from 'types/asn';
import { QueryState } from 'types/queryState';
import { formatDate } from 'utils/dateUtils';
import { Box, Flex, Text } from '@chakra-ui/react';
import { ButtonGroup } from 'components/ButtonGroup';

const columnsPosition: Record<keyof AsnItem, number> = {
  tenantSoNumber: 0,
  carrierService: 1,
  maxLabelCreatedOn: 2,
  trackingNbrs: 3,
};

export function AsnGrid(props: AsnPageParams) {
  const { widthWindow, getColumnWidth } = useLayoutPaginatedTable(900);
  const {
    buildParameters,
    data,
    getRefreshDate,
    getTotalOfRecordsFromReport,
    isDownloadingReport,
    isFetching,
    onDownloadReport,
    resources,
  } = useDownloadReport();
  const totalOfRecords = getTotalOfRecordsFromReport(data);
  const refreshDate = getRefreshDate(data);
  const buttons = [
    {
      label: resources.labels.button,
      handler: () => {
        onDownloadReport(
          REPORT_TYPES.ASN,
          buildParameters(dictFieldToQuery, {
            ...filters,
            pageSize: totalOfRecords.toString(),
            sortBy: props.sortBy || '',
          })
        );
      },
      isLoading: isDownloadingReport,
      tooltip: isDownloadingReport
        ? resources.labels.tooltip.isDownloading
        : resources.labels.tooltip.idle,
    },
  ];

  const queryState: QueryState<AsnItem> = {
    data:
      data?.map(
        (item): AsnItem => ({
          tenantSoNumber: item[columnsPosition.tenantSoNumber],
          carrierService: item[columnsPosition.carrierService],
          maxLabelCreatedOn: formatDate(
            item[columnsPosition.maxLabelCreatedOn]
          ),
          trackingNbrs: item[columnsPosition.trackingNbrs],
        })
      ) || [],
    isLoading: isFetching,
    count: data?.length || 0,
    filteredCount: totalOfRecords,
  } as any;

  const columnsMetaData: { [key in keyof AsnItem]: ColumnMetaData } = {
    tenantSoNumber: { dataType: 'text' },
    carrierService: { dataType: 'text' },
    maxLabelCreatedOn: { dataType: 'date-range' },
    trackingNbrs: { dataType: 'text' },
  };

  const gridFiltering = useGridFiltering<AsnItem>({
    columnsMetaData,
    initialFilters: props.initialFilters,
    onFilterChange: props.onFilterChange,
  });

  const gridPagination = useGridPagination({
    initialPage: props.initialPage,
    initialPageSize: props.initialPageSize,
    onPaginationChange: props.onPaginationChange,
  });

  const gridSorting = useGridSorting<AsnItem>({
    getQueryState: () => queryState,
    initialSort: props.initialSort,
    onSortChange: props.onSortByChange,
  });

  const filters = { ...gridFiltering.filters };

  const columns = useMemo<Column<AsnItem>[]>(
    () => [
      {
        Header: 'SO Number',
        accessor: 'tenantSoNumber',
        width: getColumnWidth(20, 250),
      },
      {
        Header: 'Carrier Service',
        accessor: 'carrierService',
        width: getColumnWidth(20, 250),
      },
      {
        Header: 'Created On',
        accessor: 'maxLabelCreatedOn',
        width: getColumnWidth(20, 250),
      },
      {
        Header: 'Tracking Numbers',
        accessor: 'trackingNbrs',
        width: getColumnWidth(40, 350),
      },
    ],
    [getColumnWidth]
  );

  useEffect(() => {
    setTimeout(() => {
      onDownloadReport(
        REPORT_TYPES.ASN,
        buildParameters(dictFieldToQuery, {
          ...filters,
          pageSkip: gridPagination.skip.toString(),
          pageSize: gridPagination.pageSize.toString(),
          sortBy: props.sortBy || '',
        }),
        'json'
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return widthWindow ? (
    <Box>
      <Flex
        justifyContent="space-between"
        alignItems={[null, 'flex-start', 'center']}
        flexDirection={['column', null, 'row']}
        gap={4}
      >
        <Flex alignItems="center" justifyContent="space-between" width="full">
          <Text mb={[buttons.length ? 4 : 0, 0]} as="h2" variant="pageTitle">
            ASNs
          </Text>
          {refreshDate ? (
            <Text mb={[buttons.length ? 4 : 0, 0]} variant="thinGray">
              This report was last refreshed at {refreshDate}
            </Text>
          ) : null}
        </Flex>
        {buttons.length ? (
          <ButtonGroup
            buttons={buttons}
            wrapperProps={{
              display: 'flex',
              flexDirection: ['column-reverse', null, 'row'],
              width: 'auto',
            }}
            commonButtonProps={{ fontSize: 'lg' }}
            commonButtonWrapperProps={{ mr: 0 }}
          />
        ) : null}
      </Flex>
      <PaginatedTable
        columns={columns}
        gridSorting={gridSorting}
        gridFiltering={gridFiltering}
        gridPagination={gridPagination}
        queryState={queryState}
        tableName="asn"
        isResizable
      />
    </Box>
  ) : (
    <BigLoading />
  );
}
