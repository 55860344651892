import { createContext, useContext } from 'react';

type ContextType = {
  token: string | undefined;
};

const AuthContext = createContext<ContextType>({
  token: undefined,
});

export const useAuthToken = (): string | undefined => {
  const ctx = useContext(AuthContext);
  return ctx.token;
};

export const AuthContextProvider = AuthContext.Provider;
