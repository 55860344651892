import { SimpleGrid } from '@chakra-ui/react';
import { FormInput } from 'components/FormInput';
import { LabelControl } from 'components/LabelControl';
import { useFormContext } from 'react-hook-form';
import { UserProfile } from 'types/userProfile';

type UserProfileGeneralProps = {
  emailAddress: string | undefined;
  startTabIndex: number;
};

export function UserProfileGeneral({
  emailAddress,
  startTabIndex,
}: UserProfileGeneralProps) {
  const { register } = useFormContext<UserProfile>();

  return (
    <SimpleGrid columns={[1, 2]} columnGap={8} rowGap={0}>
      <FormInput
        tabIndex={startTabIndex + 1}
        label="First Name"
        aria-label="First Name"
        placeholder="Please enter your first name"
        {...register('firstName')}
        isRequired
      />
      <FormInput
        tabIndex={startTabIndex + 2}
        label="Last Name / Family Name"
        aria-label="Last Name / Family Name"
        placeholder="Please enter your last name / family name"
        {...register('lastName')}
        isRequired
      />
      <FormInput
        tabIndex={startTabIndex + 3}
        label="Username"
        aria-label="Nickname"
        placeholder="Optional: Enter a username / nickname"
        {...register('nickName')}
      />
      <FormInput
        tabIndex={startTabIndex + 4}
        label="Pronouns"
        aria-label="Pronouns"
        placeholder="Optional: Enter your preferred pronouns"
        {...register('pronouns')}
      />
      <LabelControl label="Email Address" value={emailAddress || ''} />
      <FormInput
        tabIndex={startTabIndex + 5}
        label="Phone Number"
        aria-label="Phone Number"
        placeholder="Optional: Enter a contact phone number"
        {...register('phoneNumber')}
      />
      <FormInput
        tabIndex={startTabIndex + 6}
        label="Department"
        aria-label="Department"
        placeholder="Optional: Enter your department"
        {...register('department')}
      />
      <FormInput
        tabIndex={startTabIndex + 7}
        label="Job Title"
        aria-label="Job Title"
        placeholder="Optional: Enter your job title"
        {...register('jobTitle')}
      />
    </SimpleGrid>
  );
}
