import { ComponentStyleConfig } from '@chakra-ui/react';

export const textTheme: ComponentStyleConfig = {
  baseStyle: {
    fontWeight: 300,
  },
  variants: {
    boldBlue: {
      color: 'primaryBlue.500',
      fontWeight: 'bold',
    },
    pageTitle: {
      color: 'primaryBlue.500',
      fontWeight: 300,
      fontSize: { base: '3xl', md: '2.5rem' },
    },
    pageHeading: {
      color: 'primaryBlue.500',
      fontWeight: 500,
      fontSize: { base: 'xl', md: '1.5rem' },
    },
    unitOfMeasure: {
      color: 'primaryBlue.500',
      fontWeight: 800,
      fontSize: 'sm',
    },
    thinGray: {
      color: 'secondaryGray.500',
      fontWeight: 300,
    },
    fieldError: {
      color: 'errorRed',
      fontWeight: 400,
      fontSize: 'sm',
    },
    link: {
      color: '#2d47f9',
      fontWeight: 400,
    },
  },
};
