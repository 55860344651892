/* istanbul ignore file */
/* eslint-disable no-template-curly-in-string */
import * as yup from 'yup';

yup.setLocale({
  mixed: {
    required: '${path} is required',
    notType: 'Invalid value',
  },
  string: {
    min: 'Min length: ${min}',
    max: 'Max length: ${max}',
  },
  number: {
    min: 'Must be >= ${min}',
    max: 'Max <= ${max}',
  },
});
