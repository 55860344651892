import { Box, Button, Link, Text } from '@chakra-ui/react';
import { CREATE_ITEM_MASTER_URL } from 'constants/templateLinks';

export function CreateItemMastersPrepareTemplate() {
  return (
    <Box mb={4}>
      <Text as="h3" variant="pageHeading" mb={2}>
        1. Prepare your item master records creation file
      </Text>
      <Text fontWeight={500} mb={2}>
        Prepare your item master records creation file using our template. Save
        a copy in case you need to edit it later.
      </Text>
      <Box>
        <Button
          as={Link}
          variant="outline"
          size="md"
          target="_blank"
          href={CREATE_ITEM_MASTER_URL}
        >
          Download Template
        </Button>
      </Box>
    </Box>
  );
}
