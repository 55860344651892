import { SortState } from 'hooks/useGridSorting';
import { NetworkOrder } from 'types/networkOrder';
import { QueryParamsObject } from 'utils/urlSearchParamsUtils';

export interface NetworkOrderPageParams {
  initialSort?: SortState<NetworkOrder>;
  onSortByChange?: (sorting: SortState<NetworkOrder>) => void;
  initialPage?: number;
  onPageChange?: (page: number) => void;
  initialPageSize?: number;
  onPaginationChange?: (pagination: { page: number; pageSize: number }) => void;
  initialFilters?: QueryParamsObject;
  onFilterChange?: (filters: QueryParamsObject) => void;
}

export const dictFieldToQuery = {
  noName: 'order-number',
  status: 'status-select',
  createdAt: 'created-range',
  shipmentCount: 'minmax-shipment-count',
  shipVia: 'ship-via-select',
} as { [key in keyof NetworkOrder]: string };

export const dictFieldToSortQuery = {
  ...dictFieldToQuery,
  createdAt: 'created-at-range',
} as { [key in keyof NetworkOrder]: string };

export function queryToNetworkOrderField(
  queryKey: string,
  queryWordSeparator: string = '-'
): keyof NetworkOrder | '' {
  const dictQueryToField = {
    'order-number': 'noName',
    'status-select': 'status',
    'created-range': 'createdAt',
    'minmax-shipment-count': 'shipmentCount',
    'ship-via-select': 'shipVia',
  } as { [key: string]: keyof NetworkOrder };
  const field =
    dictQueryToField[queryKey.replaceAll(queryWordSeparator, '-')] || '';
  return field;
}

export function networkOrderFieldToQuery(
  orderField: string,
  queryWordSeparator: string = '-'
): string {
  const queryKey =
    dictFieldToQuery[orderField as keyof NetworkOrder] || orderField;
  return queryKey.replaceAll('-', queryWordSeparator);
}

export function networkOrderFieldToSortQuery(
  orderField: string,
  queryWordSeparator: string = '-'
): string {
  const queryKey =
    dictFieldToSortQuery[orderField as keyof NetworkOrder] || orderField;
  return queryKey.replaceAll('-', queryWordSeparator);
}
