import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { Invitation, InvitationDto } from 'types/invitation';

import { mapDtoToInvitation } from './mappers';

export function useListInvitations(
  queryOptions?: Parameters<typeof useQuery<Invitation[]>>[2]
) {
  return useQuery<Invitation[]>(
    ['invitations'],
    async () => {
      const {
        data: { items },
      } = await axios.get<{ items: InvitationDto[] }>('/admin/invitations');
      return items?.map(mapDtoToInvitation);
    },
    queryOptions
  );
}
