import React from 'react';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Select,
  SelectProps,
  Tooltip,
} from '@chakra-ui/react';
import HelpIcon from '@mui/icons-material/Help';
import { useFormContext } from 'react-hook-form';
import get from 'lodash-es/get';
import AsteriskIcon from './AsteriskIcon';
import { GRAY_500 } from 'theme/ui-palette';

export type FormSelectOption = {
  label?: string;
  value?: string;
  selected?: boolean;
};

export type FormSelectProps = SelectProps & {
  label?: string;
  placeholder?: string;
  error?: string;
  hideLabel?: boolean;
  tooltip?: string;
  options: FormSelectOption[];
  isRequired?: boolean;
};

export const FormSelect = React.forwardRef<HTMLSelectElement, FormSelectProps>(
  (
    {
      label,
      placeholder,
      error,
      options,
      hideLabel,
      tooltip,
      isRequired,
      ...props
    },
    ref
  ) => {
    const {
      watch,
      formState: { errors: formErrors },
    } = useFormContext();

    const errorMessage =
      error ?? (get(formErrors, props.name ?? '')?.message as string);

    const currentValue = watch(props.name ?? '');

    return (
      <FormControl variant="floating" isInvalid={!!errorMessage}>
        {label && !hideLabel ? (
          <FormLabel
            display="flex"
            opacity={props.isDisabled ? 0.4 : 1}
            position="relative"
            zIndex={1}
          >
            {label}
            {tooltip ? (
              <Tooltip label={tooltip}>
                <HelpIcon
                  fontSize="inherit"
                  sx={{
                    marginLeft: '0.3rem',
                    width: '1.3rem',
                    height: '1.3rem',
                  }}
                />
              </Tooltip>
            ) : null}
            {isRequired ? <AsteriskIcon width="0.375rem" /> : null}
          </FormLabel>
        ) : null}

        <Select
          placeholder={placeholder}
          aria-label={label}
          {...props}
          color={!currentValue ? GRAY_500 : props.color}
          ref={ref}
        >
          <option hidden disabled value=""></option>
          {options.map((option, index) => (
            <option
              aria-selected={currentValue === option.value}
              key={option.value || option.label || index}
              value={option.value || option.label}
              style={{ color: '#1A202C' }}
            >
              {option.label}
            </option>
          ))}
        </Select>
        {errorMessage && (
          <FormErrorMessage mb={2}>{errorMessage}</FormErrorMessage>
        )}
      </FormControl>
    );
  }
);
