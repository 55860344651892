import { TenantDto, Tenant, StorefrontDto, Storefront } from 'types/tenant';

export function mapDtoToStorefront(dto: StorefrontDto): Storefront {
  return {
    name: dto.name,
    displayName: dto.display_name,
  };
}

export function mapDtoToTenant(dto: TenantDto): Tenant {
  return {
    ...dto,
    storefronts: dto.storefronts?.map(mapDtoToStorefront) ?? [],
  };
}
