import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { PaginatedOrdersResponseDto, SalesOrder } from 'types/salesOrders';
import { SortState } from 'hooks/useGridSorting';
import { orderFieldToQuery } from 'features/orders-list/orderPageParams';
import { mapDtoToSalesOrder } from './mappers';
import {
  parseAdvancedFilters,
  querifyFiltersForApi,
  querifySort,
} from 'utils/urlSearchParamsUtils';
import { isEmpty } from 'lodash-es';
import { QueryState } from 'types/queryState';

export type OrdersQueryResult = QueryState<SalesOrder>;

export function useSalesOrders(
  pagination: {
    skip: number;
    limit: number;
  },
  filters: { [key: string]: string | string[] },
  sort?: SortState<SalesOrder>
): OrdersQueryResult {
  const sortQuery = querifySort(sort, orderFieldToQuery)
    .replace('-range', '')
    .replace('-select', '');
  const { status, ...restOfFilters } = filters;
  const filtersQuery = parseAdvancedFilters(
    querifyFiltersForApi(restOfFilters as any, orderFieldToQuery)
  );
  const queryResult = useQuery(
    [
      'salesOrders',
      ...Object.values({
        status,
        ...pagination,
        ...filtersQuery,
      }),
      sortQuery,
    ],
    async () => {
      const {
        data: { items, total_count, total_filtered_count },
      } = await axios.get<PaginatedOrdersResponseDto>('/salesorder', {
        params: {
          sort: sortQuery,
          ...(!isEmpty(status) ? { latest_status: status } : undefined),
          ...pagination,
          ...filtersQuery,
        },
      });

      return [items, total_count, total_filtered_count] as const;
    }
  );

  const [data = [], count = 0, filteredCount = 0] = queryResult.data ?? [];

  return {
    ...queryResult,
    data: data.map(mapDtoToSalesOrder),
    count,
    filteredCount,
  } as OrdersQueryResult;
}
