import { useCallback, useEffect } from 'react';
import { Outlet, useSearchParams } from 'react-router-dom';
import {
  networkOrderFieldToQuery,
  NetworkOrderPageParams,
  queryToNetworkOrderField,
} from 'features/network-orders/networkOrderPageParams';
import { SortState } from 'hooks/useGridSorting';
import {
  QueryParamsObject,
  getQueryParamsObject,
  parseFilters,
  parseSort,
  querifyFilters,
  querifySort,
} from 'utils/urlSearchParamsUtils';
import { useStore } from 'contexts/storeContext';
import * as _ from 'lodash-es';
import { focusToField } from 'utils/browserUtils';
import { NetworkOrdersGrid } from 'features/network-orders/NetworkOrdersGrid';
import { NetworkOrder } from 'types/networkOrder';

export function NetworkOrdersPage() {
  const [searchParams, setSearchParams] = useSearchParams({
    sortBy: '-created-range',
  });
  const [state, dispatch] = useStore();

  const updateSearchParams = useCallback(
    (updating: object) => {
      setSearchParams({
        ...getQueryParamsObject(searchParams),
        page: '1',
        ...updating,
      });
    },
    [searchParams, setSearchParams]
  );

  const onFilterChange = useCallback(
    (filters: QueryParamsObject) => {
      dispatch(_.pick(filters, ['fieldToFocus']));
      updateSearchParams(
        querifyFilters(
          _.omit(filters, ['fieldToFocus']),
          networkOrderFieldToQuery
        )
      );
    },
    [dispatch, updateSearchParams]
  );

  const onSortByChange = useCallback(
    (sorting: SortState<NetworkOrder>) => {
      updateSearchParams({
        sortBy: querifySort(sorting, networkOrderFieldToQuery),
      });
    },
    [updateSearchParams]
  );

  const gridParams: NetworkOrderPageParams = {
    initialSort: parseSort(
      searchParams.get('sortBy') || '',
      queryToNetworkOrderField
    ),
    onSortByChange,
    initialPage: parseInt(searchParams.get('page') || '', 10),
    initialPageSize: parseInt(searchParams.get('pageSize') || '', 10),
    onPaginationChange: (pagination) => updateSearchParams(pagination),
    initialFilters: parseFilters(
      getQueryParamsObject(searchParams),
      queryToNetworkOrderField
    ),
    onFilterChange,
  };

  useEffect(() => {
    if (state && state.fieldToFocus) {
      focusToField(state.fieldToFocus);
    }
  }, [state]);

  return (
    <>
      <NetworkOrdersGrid {...gridParams} />
      <Outlet />
    </>
  );
}
