import { OptionSelect } from 'components/FormSelectMulti';
import { rolesDto } from 'types/role';
import { UserRole } from 'types/userProfile';

export function mapRoleToDto(rolesData: rolesDto): UserRole[] {
  return rolesData.roles.map((role) => ({
    id: role.id,
    name: role.name,
    description: role.description,
  }));
}

export function mapRoleDtoToOption(rolesData: rolesDto): OptionSelect[] {
  return rolesData.roles.map((role) => ({
    value: role.id,
    label: role.name,
  }));
}
