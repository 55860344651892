import { Text } from '@chakra-ui/react';

export function CreateItemMastersInstruction() {
  return (
    <Text as="div" fontWeight={500} mb={4}>
      This file contains your SKU information. Item master definition is a
      prerequisite for PO creation and receiving new inventory. The information
      provided will be subject to verification during the receiving process.
      <Text fontWeight="bold">
        Please provide <i>fulfillment dimensions</i>, meaning the dimensions
        reflect the good as it will be packaged (e.g., a blanket should have
        <i> folded</i> dimensions).
      </Text>
    </Text>
  );
}
