import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { mapRoleToDto } from './mappers';
import { rolesDto } from 'types/role';

export function useRoles() {
  return useQuery(
    ['role'],
    async () => {
      const { data } = await axios.get<rolesDto>('/admin/roles');

      return data;
    },
    {
      select: mapRoleToDto,
    }
  );
}
