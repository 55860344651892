export const getBannerStorageKey = () => {
  return `@standvast/warning_banner_closed`;
};

export const getModalIsFullScreenStorageKey = () => {
  return `@standvast/modal_full_screen`;
};

export const getTableStorageColumnsKey = (tableName: string) => {
  return `@standvast/paginated_table/${tableName}/column_widths`;
};

export const getTableStorageHiddenColumnsKey = (tableName: string) => {
  return `@standvast/paginated_table/${tableName}/hidden_columns`;
};

export const persistBannerClosed = () => {
  sessionStorage.setItem(getBannerStorageKey(), 'true');
};

export const persistModalIsFullScreen = (isFullScreen: boolean) => {
  sessionStorage.setItem(
    getModalIsFullScreenStorageKey(),
    isFullScreen.toString()
  );
};

export const persistTableColumnWidths = (
  tableName: string,
  columnWidths: Record<string, number>
) => {
  if (Object.keys(columnWidths).length > 0) {
    localStorage.setItem(
      getTableStorageColumnsKey(tableName),
      JSON.stringify(columnWidths)
    );
  }
};

export const persistTableHiddenColumns = (
  tableName: string,
  hiddenColumns: Array<string>
) =>
  localStorage.setItem(
    getTableStorageHiddenColumnsKey(tableName),
    JSON.stringify(hiddenColumns)
  );

export const retrieveBannerClosed = () => {
  return sessionStorage.getItem(getBannerStorageKey());
};

export const retrieveModalIsFullScreen = () => {
  const modalIsFullScreen = sessionStorage.getItem(
    getModalIsFullScreenStorageKey()
  );
  return modalIsFullScreen === true.toString() ? true : false;
};

export const retrieveTableColumnWidths = (
  tableName: string
): Record<string, number> => {
  const columnWidths = localStorage.getItem(
    getTableStorageColumnsKey(tableName)
  );
  if (columnWidths) {
    return JSON.parse(columnWidths);
  }
  return {};
};

export const retrieveTableHiddenColumns = (
  tableName: string
): Array<string> => {
  const hiddenColumns = localStorage.getItem(
    getTableStorageHiddenColumnsKey(tableName)
  );
  if (hiddenColumns) {
    return JSON.parse(hiddenColumns);
  }
  return [];
};
