import { Box, Text } from '@chakra-ui/react';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import { ErrorBoundary } from './ErrorBoundary';

export interface PagePickerProps {
  page: number;
  setPage: (n: number) => void;
  totalPages: number;
}

export function PagePicker({ page, setPage, totalPages }: PagePickerProps) {
  const hasPrevious = page > 1;
  const handlePreviousPageClick = () => {
    setPage(page - 1);
  };
  const handleFirstPageClick = () => {
    setPage(1);
  };

  const hasNext = page < totalPages;
  const handleNextPageClick = () => {
    setPage(page + 1);
  };
  const handleLastPageClick = () => {
    setPage(totalPages);
  };

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <ErrorBoundary size="compact">
        {hasPrevious && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            color="primaryBlue.500"
          >
            <Box
              as={SkipPreviousIcon}
              aria-label="First page"
              role="button"
              cursor="pointer"
              onClick={handleFirstPageClick}
              _hover={{
                opacity: '0.6',
              }}
            />
            <Box
              as={NavigateBeforeIcon}
              aria-label="Previous page"
              role="button"
              cursor="pointer"
              onClick={handlePreviousPageClick}
              _hover={{
                opacity: '0.6',
              }}
            />
          </Box>
        )}
        <Text
          display="flex"
          alignItems="center"
          justifyContent="center"
          mr={['8px', '15px']}
          ml={['8px', '15px']}
        >
          Page {page} of {totalPages}
        </Text>
        {hasNext && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            color="primaryBlue.500"
          >
            <Box
              as={NavigateNextIcon}
              aria-label="Next page"
              role="button"
              cursor="pointer"
              onClick={handleNextPageClick}
              _hover={{
                opacity: '0.6',
              }}
            />
            <Box
              as={SkipNextIcon}
              aria-label="Last page"
              role="button"
              cursor="pointer"
              onClick={handleLastPageClick}
              _hover={{
                opacity: '0.6',
              }}
            />
          </Box>
        )}
      </ErrorBoundary>
    </Box>
  );
}
