import axios from 'axios';
import { useState } from 'react';

export function useRemoveUser() {
  const [isRemoving, setIsRemoving] = useState<boolean>(false);

  async function removeUser(userEmail: string, refetech: Function) {
    try {
      setIsRemoving(true);
      await axios.delete(`/admin/users/${userEmail}`);
      await refetech();
    } finally {
      setIsRemoving(false);
    }
  }

  return {
    removeUser,
    isRemoving,
  };
}
