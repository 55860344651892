/* istanbul ignore file */
import { datadogRum } from '@datadog/browser-rum';
import { Category } from 'types/monitorEvents';

// Authentication
export const sendLoginEvent = () =>
  datadogRum.addAction('login', {
    category: Category.General,
    method: 'username-and-password',
  });

export const sendTypeOnSearchTextInputEvent = () =>
  datadogRum.addAction('type_on_search_text_input', {
    category: Category.General,
  });

export const sendSearchEvent = (term: string) =>
  datadogRum.addAction('search', {
    category: Category.General,
    search_term: term,
  });

// Sales Orders
export const sendViewSalesOrderDetailsEvent = (orderNumber: string) =>
  datadogRum.addAction('view_details', {
    category: Category.SalesOrders,
    order_number: orderNumber,
  });

export const sendCreateSalesOrderEvent = (
  orderType: string,
  orderNumber: string
) =>
  datadogRum.addAction('create_sales_order', {
    category: Category.SalesOrders,
    order_type: orderType,
    order_number: orderNumber,
  });

export const sendUpdateSalesOrderEvent = (orderNumber: string) =>
  datadogRum.addAction('update_sales_order', {
    category: Category.SalesOrders,
    order_number: orderNumber,
  });

export const sendConfirmCancelSalesOrderEvent = (orderNumber: string) =>
  datadogRum.addAction('cancel_sales_order', {
    category: Category.SalesOrders,
    order_number: orderNumber,
  });

// Purchase Orders
export const sendViewPurchaseOrderDetailsEvent = (orderNumber: string) =>
  datadogRum.addAction('view_details', {
    category: Category.PurchaseOrders,
    order_number: orderNumber,
  });

export const sendSchedulePurchaseOrderEvent = () =>
  datadogRum.addAction('create_purchase_order', {
    category: Category.PurchaseOrders,
  });

export const sendUpdatePurchaseOrderEvent = (orderNumber: string) =>
  datadogRum.addAction('update_purchase_order', {
    category: Category.PurchaseOrders,
    order_number: orderNumber,
  });

export const sendConfirmCancelPurchaseOrderEvent = (orderNumber: string) =>
  datadogRum.addAction('cancel_purchase_order', {
    category: Category.PurchaseOrders,
    order_number: orderNumber,
  });

// Inventory
export const sendViewInventoryItemDetailsEvent = (sku: string) =>
  datadogRum.addAction('view_details', {
    category: Category.Inventory,
    sku,
  });

// User Profile
export const sendUpdateUserProfileEvent = (userId: string) =>
  datadogRum.addAction('update_user_profile', {
    category: Category.UserProfile,
    user_id: userId,
  });
