/* istanbul ignore file */
import * as yup from 'yup';

export const freightInfoSchema = yup
  .object({
    carrierScacCode: yup
      .string()
      .label('Carrier SCAC Code')
      .max(10)
      .nullable()
      .optional(),
    arn: yup.string().label('ARN (optional)').max(50).nullable().optional(),
    asn: yup.string().label('ASN (optional)').max(50).nullable().optional(),
    printGtin14Label: yup.boolean().label('GTIN 14 Label?').required(),
    printUpcLabel: yup.boolean().label('UPC Label?').required(),
  })
  .required();
