import { useMemo } from 'react';
import { Modal } from 'components/Modal';
import { Stepper } from 'components/Stepper';
import { useUploadMsaContext } from 'hooks/useUploadMsa';
import { useCrudActionUserFeedback } from 'hooks/useCrudActionUserFeedback';
import { useMsaUpload } from 'mutations/admin/useMsaUpload';
import { useToast } from '@chakra-ui/react';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export function UploadMsaModal({ isOpen, onClose }: Props) {
  const {
    file,
    effectiveDateRange,
    activeStep,
    setActiveStep,
    steps,
    resetState,
  } = useUploadMsaContext();
  const { mutateAsync, isLoading } = useMsaUpload();
  const toast = useToast();

  const close = () => {
    onClose();
    resetState();
  };

  const upload = useCrudActionUserFeedback({
    mutateAsync: () =>
      mutateAsync({
        file: file!,
        effectiveStartDate: effectiveDateRange[0],
        effectiveEndDate: effectiveDateRange[1],
      }),
    successMessage: 'MSA document successfully uploaded',
    successCallback: close,
    customErrorHandler: (error) => {
      const errorData = (error.response?.data as { detail?: string }) ?? {};
      toast({
        status: 'error',
        title: 'Error',
        description: errorData.detail ?? error.message,
      });
    },
  });

  const isReadyToUpload = useMemo(
    () =>
      file &&
      effectiveDateRange.length === 2 &&
      effectiveDateRange.every(Boolean),
    [file, effectiveDateRange]
  );

  return (
    <Modal
      title="Upload a MSA"
      isOpen={isOpen}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      onClose={close}
      size={['full', null, '6xl']}
      showCloseButton
      buttons={[
        {
          label: 'Cancel',
          handler: close,
        },
        {
          label: 'Upload',
          variant: 'cta',
          handler: upload,
          isDisabled: !isReadyToUpload,
          isLoading,
        },
      ]}
    >
      <Stepper
        minH="220px"
        mt={[6, 0]}
        steps={steps}
        value={activeStep}
        onChange={setActiveStep}
      />
    </Modal>
  );
}
