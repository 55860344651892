/* istanbul ignore file */
import * as yup from 'yup';
import { buildAddressCommonSchema } from './addressCommon';

export const shipToAddressSchema = buildAddressCommonSchema(false).concat(
  yup
    .object({
      phoneNumber: yup
        .string()
        .label('Phone number')
        .max(20)
        .when('country', {
          is: (val: string) => val === 'US',
          then: (schema) => schema.nullable().optional(),
          otherwise: (schema) => schema.required(),
        }),
      email: yup
        .string()
        .email()
        .label('Customer email')
        .max(120)
        .nullable()
        .optional(),
    })
    .required()
);
