import { isWithinInterval, parseISO, endOfDay, startOfDay } from 'date-fns';
import { Option } from 'hooks/useGridFiltering';
import { Row } from 'react-table';

export function getSelectOptions(options: string[] | Option[] | undefined) {
  return (
    options?.map((option) =>
      typeof option === 'string' ? option : option.value
    ) ?? []
  );
}

export function filterSelect<T extends object>(
  rows: Row<T>[],
  id: string,
  filterValue: string[]
) {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return filterValue.some((value) => rowValue.includes(value));
  });
}

export function filterDateRange<T extends object>(
  rows: Row<T>[],
  id: string,
  dateRange: string[] | undefined
) {
  if (!dateRange) return rows;
  const parsedStartDate = parseISO(dateRange[0]);
  const parsedEndDate = parseISO(dateRange[1]);

  return rows.filter((row) => {
    const rowValue = row.values[id];
    const parsedRowValue = parseISO(rowValue);
    return isWithinInterval(parsedRowValue, {
      start: parsedStartDate,
      end: parsedEndDate,
    });
  });
}

export function filterDate<T extends object>(
  rows: Row<T>[],
  id: string,
  filterValue: string
) {
  if (!filterValue) return rows;

  const parsedFilterValue = parseISO(filterValue);
  const startOfDayFilterValue = startOfDay(parsedFilterValue);
  const endOfDayFilterValue = endOfDay(parsedFilterValue);

  return rows.filter((row) => {
    const rowValue = row.values[id];
    const parsedRowValue = parseISO(rowValue);
    return isWithinInterval(parsedRowValue, {
      start: startOfDayFilterValue,
      end: endOfDayFilterValue,
    });
  });
}
