import { SalesOrder, SalesOrderStatus } from 'types/salesOrders';
import { useMemo } from 'react';
import cloneDeep from 'lodash-es/cloneDeep';
import { useNavigate } from 'react-router-dom';
import { generateReshipSONumber } from 'utils/salesOrdersUtils';

export function useCreateReshipSalesOrder(order?: SalesOrder) {
  const navigate = useNavigate();

  const newReshipOrder = useMemo(() => {
    if (order) {
      let newOrder = cloneDeep(order);
      newOrder.soNumber = generateReshipSONumber(newOrder.soNumber);
      newOrder.status = SalesOrderStatus.Received;
      return newOrder;
    }
  }, [order]);

  const createReshipOrder = () => {
    navigate('/orders/create', { state: newReshipOrder });
  };

  return {
    order: newReshipOrder,
    create: createReshipOrder,
  };
}
