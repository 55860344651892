import { ComponentStyleConfig } from '@chakra-ui/react';

export const dividerTheme: ComponentStyleConfig = {
  baseStyle: {
    borderStyle: 'solid',
    mt: 2,
  },
  sizes: {
    sm: {
      borderBottomWidth: 1,
    },
    md: {
      borderBottomWidth: 2,
    },
    lg: {
      borderBottomWidth: 3,
    },
  },
  variants: {
    teal: {
      borderColor: 'secondaryTeal.400',
      opacity: 1,
    },
  },
  defaultProps: {
    size: 'md',
    variant: 'teal',
  },
};
