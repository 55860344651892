import { Reports } from 'types/report';

export const REPORT_TYPES: Record<string, Reports> = {
  ASN: 'asn',
  INVENTORY: 'inventory',
  PURCHASE_ORDERS: 'poitems',
  RETURNS: 'returns',
  SALES_ORDERS: 'soitems',
};

export const REPORT_FILENAMES: Record<Reports, string> = {
  asn: 'asn_details',
  inventory: 'inventory_details',
  poitems: 'po_details',
  returns: 'returns_details',
  soitems: 'so_details',
};
