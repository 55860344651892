import { useToast } from '@chakra-ui/react';
import { AxiosError, AxiosResponse } from 'axios';
import { FieldValues, Path, UseFormSetError } from 'react-hook-form';

export type SetHandlerInterceptor = (
  interceptor: ((handler: InterceptedHandler) => void) | undefined
) => void;

export type CrudActionType = 'CREATE' | 'UPDATE' | 'DELETE';

export type DtoFieldErrorSegment = string | number;

export type DtoFieldError = {
  ctx?: { [k: string]: any };
  loc: DtoFieldErrorSegment[];
  msg: string;
  type: string;
};

type PydanticErrorResponse = {
  detail?: DtoFieldError[];
};

type ImportErrorResponse = {
  errors?: { details: string[]; field_level_errors?: DtoFieldError[] }[];
};

export type PydanticValidationError = AxiosError<PydanticErrorResponse>;

export type InterceptedHandler = () => void;

type UseCrudActionUserFeedbackOptions<T extends FieldValues> = {
  mutateAsync: () => Promise<AxiosResponse<ImportErrorResponse>>;
  successMessage: string;
  actionType?: CrudActionType;
  mapFieldDtoErrorToRhfPath?: (
    error: DtoFieldError,
    action?: CrudActionType
  ) => string | null;
  setFieldError?: UseFormSetError<T>;
  successCallback?: () => void;
  errorCallback?: () => void;
  startCallback?: () => void;
  finallyCallback?: () => void;
  customErrorHandler?: (err: AxiosError) => void;
};

export function useCrudActionUserFeedback<T extends FieldValues>(
  options: UseCrudActionUserFeedbackOptions<T>
) {
  const toast = useToast();

  return () => {
    let fieldLevelErrors: DtoFieldError[] = [];

    options.startCallback?.();

    options
      .mutateAsync()
      .then((response) => {
        if (!response.data?.errors?.length) {
          toast({
            status: 'success',
            title: 'Success',
            description: options.successMessage,
          });
          options.successCallback?.();
        } else {
          const errors = response.data.errors;
          const errorMessages = errors
            .map((err) => err.details)
            .flat()
            .join('<br />');
          fieldLevelErrors = errors
            .map((err) => err.field_level_errors)
            .flat()
            .filter((errors) => errors) as DtoFieldError[];

          throw new Error(errorMessages);
        }
      })
      .catch((err: PydanticValidationError) => {
        const data = err.response?.data ?? {};
        const allErrors = data.detail ?? fieldLevelErrors;

        if (allErrors?.length && options.mapFieldDtoErrorToRhfPath) {
          const errors = allErrors.map((err) => ({
            path: options.mapFieldDtoErrorToRhfPath?.(err, options.actionType),
            error: err,
          }));

          errors.forEach(({ error, path }) => {
            if (!options.setFieldError || path === null) {
              toast({
                status: 'error',
                title: 'Error',
                description: error.msg,
              });
            } else {
              options.setFieldError(path as Path<T>, {
                type: 'custom',
                message: error.msg,
              });
            }
          });
        } else if (!options.customErrorHandler) {
          toast({
            status: 'error',
            title: 'Error',
            description: err.message,
          });
        } else {
          options.customErrorHandler(err);
        }

        options.errorCallback?.();
      })
      .finally(() => {
        options.finallyCallback?.();
      });
  };
}
