import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { TenantDto, Tenant } from 'types/tenant';
import { mapDtoToTenant } from './mappers';

export function useTenant(
  queryOptions?: Omit<Parameters<typeof useQuery<Tenant[]>>[2], 'select'>
) {
  return useQuery(
    ['tenant'],
    async () => {
      const { data } = await axios.get<TenantDto>(`/tenant/current`);
      return data;
    },
    {
      ...queryOptions,
      select: mapDtoToTenant,
    }
  );
}
