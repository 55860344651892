/* istanbul ignore file */
import { GridItem, SimpleGrid } from '@chakra-ui/react';
import { FreightInfoForm } from './FreightInfoForm';
import { FormDatePicker } from 'components/FormDatePicker';
import { useFormContext } from 'react-hook-form';
import { SalesOrder } from 'types/salesOrders';
import { parseDate } from 'utils/dateUtils';
import { SectionCollapsable } from 'components/SectionCollapsable';
import { WarehouseSelect } from './WarehouseSelect';

type Props = {
  startTabIndex: number;
};

export const CreateFreightOrderForm: React.FC<Props> = ({ startTabIndex }) => {
  const {
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<SalesOrder>();

  const dueDateValue = watch('dueDate');

  return (
    <SimpleGrid columns={[1, 2]} columnGap={8}>
      <FormDatePicker
        tabIndex={startTabIndex + 1}
        tooltip="Leave blank if pickup will be arranged later."
        label="Ship By Date"
        aria-label="Ship By Date"
        isClearable
        variant="flushed"
        defaultValue={parseDate(dueDateValue)}
        onChange={(value) =>
          setValue('dueDate', value, {
            shouldDirty: true,
            shouldValidate: true,
          })
        }
        error={errors.dueDate?.message}
      />
      <WarehouseSelect orderType="Freight" tabIndex={startTabIndex + 2} />
      <GridItem colSpan={2} mt={8}>
        <SectionCollapsable title="Freight Information">
          <FreightInfoForm startTabIndex={startTabIndex + 2} />
        </SectionCollapsable>
      </GridItem>
    </SimpleGrid>
  );
};
