import { SpaceProps } from '@chakra-ui/react';
import { Flyout } from 'components/Flyout';
import { PaginatedTable } from 'components/PaginatedTable';
import { ToggleVisibility } from 'components/ToggleVisibility';
import { getFirstShipmentDetails } from 'queries/salesOrders/mappers';
import { useMemo } from 'react';
import { Column, Row } from 'react-table';
import { QueryState } from 'types/queryState';
import { SalesOrder } from 'types/salesOrders';
import { formatDate } from 'utils/dateUtils';

interface Props extends SpaceProps {
  order?: SalesOrder;
}

export function OrderInfoTable({ order }: Props) {
  const orderInfoColumns = useMemo(
    () =>
      [
        {
          Header: 'Order Date',
          accessor: 'createdDate',
          width: 130,
          Cell: ({ value: createdDate }: { value: string }) => (
            <Flyout w="142px">{formatDate(createdDate)}</Flyout>
          ),
        },
        {
          Header: 'Ship Via',
          accessor: 'shipVia',
          width: 120,
          Cell: ({ value: shipVia }: { value: string }) => (
            <Flyout w="142px">{shipVia}</Flyout>
          ),
        },
        order?.type === 'Parcel'
          ? {
              Header: 'Carrier',
              width: 120,
              Cell: ({ row }: { row: Row<SalesOrder> }) => (
                <>
                  {getFirstShipmentDetails(row.original?.trackingNumbersDetail)
                    ?.carrier ?? ''}
                </>
              ),
            }
          : null,
        order?.type === 'Parcel'
          ? {
              Header: 'Carrier Service',
              width: 180,
              Cell: ({ row }: { row: Row<SalesOrder> }) => (
                <>
                  {getFirstShipmentDetails(row.original?.trackingNumbersDetail)
                    ?.service ?? ''}
                </>
              ),
            }
          : null,
        {
          Header: 'Purchase Order',
          accessor: 'poNumber',
          width: 200,
          Cell: ({ value: poNumber }: { value: string }) => (
            <Flyout w="180px">{poNumber}</Flyout>
          ),
        },
        {
          Header: 'Customer Ref #',
          accessor: 'customerNumber',
          width: 200,
          Cell: ({ value: customerNumber }: { value?: string }) => (
            <ToggleVisibility bypass={!customerNumber?.trim()} mr={-1}>
              {customerNumber}
            </ToggleVisibility>
          ),
        },
        {
          Header: 'Customer Name',
          accessor: 'customerName',
          width: 250,
          Cell: ({ value: customerName }: { value?: string }) => (
            <ToggleVisibility mr={-1}>
              <Flyout w="250px">{customerName}</Flyout>
            </ToggleVisibility>
          ),
        },
      ].filter(Boolean) as Column<any>[],
    [order]
  );

  return (
    <PaginatedTable
      columns={orderInfoColumns}
      queryState={
        {
          data: [order] || [],
          isLoading: !order,
        } as QueryState<SalesOrder>
      }
      tableName="order_info_table"
      isResizable
    />
  );
}
