import { mapDtoToSalesOrderSearchResult } from 'queries/salesOrders/mappers';
import { mapDtoToPurchaseOrderSearchResult } from 'queries/purchaseOrders/mappers';
import { SearchResults, SearchResultsDto } from 'types/search';

export function mapDtoToSearchResults(
  searchResults: SearchResultsDto
): SearchResults {
  return {
    salesOrders: searchResults.sales_orders.map(mapDtoToSalesOrderSearchResult),
    purchaseOrders: searchResults.purchase_orders.map(
      mapDtoToPurchaseOrderSearchResult
    ),
    items: searchResults.items,
  };
}
