import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { SalesOrderClosedSummaryDto } from 'types/salesOrders';
import { mapDtoToOrderShippedSummary } from './mappers';

export function useOrderClosedSummary() {
  return useQuery(
    ['salesOrderShippedSummary'],
    async () => {
      const { data } = await axios.get<SalesOrderClosedSummaryDto>(
        '/salesorder/summary-shipped'
      );
      return data;
    },
    {
      select: mapDtoToOrderShippedSummary,
    }
  );
}
