import axios from 'axios';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { mapDtoToNetworkOrderDetails } from './mappers';
import {
  NetworkOrderDetails,
  NetworkOrderDetailsDto,
} from 'types/networkOrder';

export function useNetworkOrder(
  orderName?: string,
  options?: UseQueryOptions<NetworkOrderDetails>
) {
  return useQuery<NetworkOrderDetails>(
    ['networkOrder', orderName],
    async () => {
      const { data } = await axios.get<NetworkOrderDetailsDto>(
        `/oms/network_order/${orderName}`
      );
      return mapDtoToNetworkOrderDetails(data);
    },
    {
      ...options,
      enabled: Boolean(orderName),
    }
  );
}
