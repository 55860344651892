import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { InventoryItemDetailsModal } from 'features/inventory-item-details/InventoryItemDetailsModal';
import { ErrorBoundary } from 'components/ErrorBoundary';

export function InventoryItemDetailsPage() {
  const { sku } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const urlSegments = location.pathname.split('/');
  const firstUrlSegment = urlSegments[1] ?? '';

  const handleClose = () => {
    navigate(`/${firstUrlSegment}${location.search}`);
  };

  return sku ? (
    <ErrorBoundary size="full">
      <InventoryItemDetailsModal sku={sku} onClose={handleClose} />
    </ErrorBoundary>
  ) : null;
}
