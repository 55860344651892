import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { CancelSalesOrderErrorResultDto } from 'types/salesOrders';

type Options = {
  invalidateQueries?: boolean;
};
export function useCancelSalesOrder({
  invalidateQueries = true,
}: Options = {}) {
  const queryClient = useQueryClient();
  return useMutation<
    any,
    AxiosError<CancelSalesOrderErrorResultDto>,
    string,
    unknown
  >((soNumber) => axios.delete(`/salesorder/${soNumber}`), {
    onSuccess: () => {
      if (invalidateQueries)
        queryClient.invalidateQueries({ queryKey: ['salesOrders'] });
    },
  });
}
