import {
  Box,
  Flex,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
} from '@chakra-ui/react';
import { Shimmer } from 'components/Shimmer';
import { StatLink } from 'components/StatLink';
import { useNetworkOrders } from 'queries/networkOrders/useNetworkOrders';
import { useMemo } from 'react';
import { NetworkOrderStatus } from 'types/networkOrder';
import { getLastMonthDateRange } from 'utils/dateUtils';

export const ClosedNetworkOrders = () => {
  const defaultFilters = useMemo(() => {
    const lastMonthDateRange = getLastMonthDateRange();
    return {
      created_range: `${lastMonthDateRange.from}_${lastMonthDateRange.to}`,
    };
  }, []);

  const { count: totalComplete, isLoading: isLoadingTotalComplete } =
    useNetworkOrders({
      pagination: { skip: 0, limit: 1 },
      filters: {
        ...defaultFilters,
        status: [NetworkOrderStatus.Complete],
      },
    });
  const { count: totalPartial, isLoading: isLoadingTotalPartial } =
    useNetworkOrders({
      pagination: { skip: 0, limit: 1 },
      filters: {
        ...defaultFilters,
        status: [NetworkOrderStatus.Partial],
      },
    });
  const { count: totalCancelled, isLoading: isLoadingTotalCancelled } =
    useNetworkOrders({
      pagination: { skip: 0, limit: 1 },
      filters: {
        ...defaultFilters,
        status: [NetworkOrderStatus.Cancelled],
      },
    });

  const isLoading =
    isLoadingTotalComplete || isLoadingTotalPartial || isLoadingTotalCancelled;
  const totalClosedOrders = totalComplete + totalPartial + totalCancelled;

  return (
    <Box py={5}>
      <Flex
        textAlign="center"
        gap={5}
        flexDirection={{ base: 'column', md: 'row' }}
      >
        <Stat size="8xl" variant="primary">
          <Shimmer h="90px" my={8} isLoaded={!isLoading}>
            <StatNumber>{totalClosedOrders.toLocaleString('en-US')}</StatNumber>
          </Shimmer>
          <StatLabel>Total Closed Orders</StatLabel>
        </Stat>
        <SimpleGrid columns={2} flex={1} gap={1}>
          <StatLink
            label="Complete"
            number={totalComplete}
            to={`/network-orders?status_select=${NetworkOrderStatus.Complete}&created_range=${defaultFilters.created_range}`}
            isLoading={isLoadingTotalComplete}
            tooltip="All items on the order have shipped."
          />
          <StatLink
            label="Partial"
            number={totalPartial}
            to={`/network-orders?status_select=${NetworkOrderStatus.Partial}&created_range=${defaultFilters.created_range}`}
            isLoading={isLoadingTotalPartial}
            tooltip="Some items have been shipped. Standvast will not attempt to ship the remaining items."
          />
          <StatLink
            label="Canceled"
            number={totalCancelled}
            to={`/network-orders?status_select=${NetworkOrderStatus.Cancelled}&created_range=${defaultFilters.created_range}`}
            isLoading={isLoadingTotalCancelled}
            tooltip="None of the items are available. Standvast will not attempt to ship this order again."
          />
        </SimpleGrid>
      </Flex>
    </Box>
  );
};
