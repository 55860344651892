/* istanbul ignore file */
import { GridItem, SimpleGrid } from '@chakra-ui/react';
import { ShipViaSelect } from './ShipViaSelect';
import ShipOptionSelect from './ShipOptionSelect';
import { RATE_SHOP_OPTION } from 'queries/shipVias/useShipVias';
import { useFormContext } from 'react-hook-form';
import { SalesOrder } from 'types/salesOrders';
import { GiftMessageForm } from './GiftMessageForm';
import CarrierAccountSelect from './CarrierAccountSelect';
import { WarehouseSelect } from './WarehouseSelect';

type Props = {
  startTabIndex: number;
};

export const CreateParcelOrderForm: React.FC<Props> = ({ startTabIndex }) => {
  const { watch } = useFormContext<SalesOrder>();

  const shipViaValue = watch('shipVia');

  return (
    <SimpleGrid columns={[1, 2]} columnGap={8}>
      <GridItem colSpan={[1, shipViaValue === RATE_SHOP_OPTION ? 1 : 2]}>
        <ShipViaSelect tabIndex={startTabIndex + 1} />
      </GridItem>
      {shipViaValue === RATE_SHOP_OPTION ? (
        <ShipOptionSelect tabIndex={startTabIndex + 2} />
      ) : null}
      <GridItem>
        <WarehouseSelect orderType="Parcel" tabIndex={startTabIndex + 3} />
      </GridItem>
      <GridItem>
        <CarrierAccountSelect tabIndex={startTabIndex + 4} />
      </GridItem>
      <GridItem colSpan={2} mt={8}>
        <GiftMessageForm tabIndex={startTabIndex + 5} />
      </GridItem>
    </SimpleGrid>
  );
};
