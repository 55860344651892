import { Control, useFieldArray } from 'react-hook-form';
import { PurchaseOrder } from 'types/purchaseOrders';

export const useContainerNumbers = (control: Control<PurchaseOrder>) => {
  const {
    fields: containerNumberFields,
    append: appendContainerNumber,
    remove: removeContainerNumber,
  } = useFieldArray({
    control,
    name: 'containerNumbers',
  });

  return {
    containerNumberFields,
    appendContainerNumber,
    removeContainerNumber,
  };
};
