import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { SalesOrder } from 'types/salesOrders';
import { mapSalesOrderToDto } from './mappers';
import { isAfter, add } from 'date-fns';

export function useCreateSalesOrder() {
  const queryClient = useQueryClient();

  const getDueDate = () => {
    const now = new Date();
    const nowUTC = Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      now.getUTCHours(),
      now.getUTCMinutes(),
      now.getUTCSeconds()
    );
    const sixPM = Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      18,
      0,
      0
    );

    let dueDate = add(sixPM, { hours: 9, minutes: 59 });
    if (isAfter(nowUTC, sixPM)) {
      dueDate = add(dueDate, { days: 1 });
    }
    return dueDate.toISOString();
  };

  return useMutation<any, any, SalesOrder>({
    mutationFn: (order: SalesOrder) => {
      if (order.type === 'Parcel') {
        order.dueDate = getDueDate();
      }
      const mappedOrder = mapSalesOrderToDto(order);

      return axios.post(`/salesorder/${order.type.toLowerCase()}`, {
        request_transaction_id: '',
        ...(order.type === 'Freight'
          ? { order: mappedOrder }
          : { orders: [mappedOrder] }),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['salesOrders'] });
    },
  });
}
