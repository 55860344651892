import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { Invitation } from 'types/invitation';

export function useRevokeInvitation() {
  const queryClient = useQueryClient();
  return useMutation<any, any, Pick<Invitation, 'id'>>({
    mutationFn: (invitationId: Pick<Invitation, 'id'>) => {
      return axios.delete(`/admin/invitations/${invitationId.id}`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['invitations'] });
    },
  });
}
