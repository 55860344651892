import {
  Box,
  Flex,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
} from '@chakra-ui/react';
import { ExceptionState } from 'components/ExceptionState';
import { Shimmer } from 'components/Shimmer';
import { StatLink } from 'components/StatLink';
import { useOrderSummary } from 'queries/salesOrders/useOrderSummary';

export const PendingOrders = () => {
  const { data: summary, isLoading, error, refetch } = useOrderSummary();
  return (
    <Box py={5}>
      {!isLoading && !!error ? (
        <ExceptionState stateType="error" retryHandler={refetch} />
      ) : (
        <Flex
          textAlign="center"
          gap={5}
          flexDirection={{ base: 'column', md: 'row' }}
        >
          <Stat size="8xl" variant="primary">
            <Shimmer h="90px" my={8} isLoaded={!isLoading}>
              <StatNumber>
                {summary?.totalPending?.toLocaleString?.('en-US') ?? 0}
              </StatNumber>
            </Shimmer>
            <StatLabel>Total Open Sales Orders</StatLabel>
          </Stat>
          <SimpleGrid columns={2} flex={1} gap={1}>
            <StatLink
              label="Received"
              number={summary?.totalReceived ?? 0}
              to="/orders?latest_status=Received,Received-Updated"
              isLoading={isLoading}
            />
            <StatLink
              label="Picking Started"
              number={summary?.totalStarted ?? 0}
              to="/orders?latest_status=Picking-Started"
              isLoading={isLoading}
            />
            <StatLink
              label="Awaiting Carrier"
              number={summary?.totalWaiting ?? 0}
              to="/orders?latest_status=Picked,Labelled,Loaded"
              isLoading={isLoading}
            />
            <StatLink
              label="Exceptions"
              number={summary?.totalExceptions ?? 0}
              to="/orders?latest_status=Carrier-Error,Picked-Short,Loaded-Short,Cancellation-Requested"
              isLoading={isLoading}
            />
          </SimpleGrid>
        </Flex>
      )}
    </Box>
  );
};
