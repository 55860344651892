import { NetworkOrderStatus } from 'types/networkOrder';

export const NETWORK_ORDER_STATUSES = Object.freeze([
  NetworkOrderStatus.BackOrder,
  NetworkOrderStatus.Cancelled,
  NetworkOrderStatus.Complete,
  NetworkOrderStatus.Error,
  NetworkOrderStatus.Partial,
  NetworkOrderStatus.Processing,
  NetworkOrderStatus.Received,
]);

export const PROCESSING_STATUS = Object.freeze([
  NetworkOrderStatus.Received,
  NetworkOrderStatus.Processing,
  NetworkOrderStatus.BackOrder,
]);

export const INCOMPLETE_STATUS = Object.freeze([
  NetworkOrderStatus.Partial,
  NetworkOrderStatus.Cancelled,
]);

export const BAD_STATUS = Object.freeze([NetworkOrderStatus.Error]);

export const SUCCESS_STATUS = Object.freeze([NetworkOrderStatus.Complete]);
