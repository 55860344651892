import { Box, Text } from '@chakra-ui/react';
import MsaTable from 'features/admin/msa-table/MsaTable';

export function MsaDocumentsPage() {
  return (
    <Box>
      <Text as="h2" variant="pageTitle" mb={-4}>
        MSA Documents
      </Text>
      <MsaTable />
    </Box>
  );
}
