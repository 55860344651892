import { Text, TextProps } from '@chakra-ui/react';
import { Shimmer } from 'components/Shimmer';
import { AddressInfo } from 'types/address';

type Props = {
  address?: Partial<AddressInfo>;
};

export function Address({ address }: Props) {
  const AddressLine = (props: TextProps & { children?: React.ReactNode }) =>
    props.children ? (
      <Text lineHeight={['shorter', null, 'short']} {...props}>
        {props.children}
      </Text>
    ) : null;

  if (!address) {
    return (
      <>
        <Shimmer w="150px" my={2} />
        <Shimmer w="210px" my={2} />
        <Shimmer w="180px" my={2} />
      </>
    );
  }

  return (
    <>
      <AddressLine>{address.name}</AddressLine>
      <AddressLine>{address.address}</AddressLine>
      <AddressLine>{address.address2}</AddressLine>
      <AddressLine>{address.address3}</AddressLine>
      <AddressLine>
        {address.city} {address.state} {address.zipCode}
      </AddressLine>
      <AddressLine mb={2}>{address.country}</AddressLine>
      <AddressLine>{address.email}</AddressLine>
      <AddressLine>{address.phoneNumber}</AddressLine>
    </>
  );
}
