import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { UserDto, User } from 'types/user';
import { mapDtoToUser } from './mappers';

export function useUsers(
  queryOptions?: Parameters<typeof useQuery<User[]>>[2]
) {
  return useQuery<User[]>(
    ['users'],
    async () => {
      const {
        data: { items },
      } = await axios.get<{ items: UserDto[] }>('/admin/users');
      return items?.map(mapDtoToUser);
    },
    queryOptions
  );
}
