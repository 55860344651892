/* istanbul ignore file */
import {
  TransportationDateType,
  TransportationReportType,
} from 'types/transportationDetails';
import * as yup from 'yup';

export const exportTransporationDetailsSchema = yup
  .object({
    type: yup
      .mixed<TransportationReportType>()
      .oneOf(Object.values(TransportationReportType), 'Type is required')
      .label('Type')
      .required(),
    dateType: yup
      .mixed<TransportationDateType>()
      .oneOf(Object.values(TransportationDateType), 'Date Range is required')
      .label('Date Range')
      .required(),
    dateRange: yup
      .array()
      .of(yup.string().required())
      .test(
        'is-valid-date-range',
        () => 'Custom Date Range is required',
        function (state = []) {
          return this.parent.dateType === TransportationDateType.CustomDateRange
            ? state.length === 2
            : true;
        }
      ),
    fc: yup
      .array()
      .of(yup.string().required())
      .test(
        'is-valid-fc',
        () => 'FC is required',
        function (state = []) {
          return state.length > 0;
        }
      ),
  })
  .required();

export type ExportTransporationDetails = yup.InferType<
  typeof exportTransporationDetailsSchema
>;
