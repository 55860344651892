import { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import useWindowDimensions from './useWindowDimensions';

export const useLayoutPaginatedTable = (minWidthPixelStretch: number = 0) => {
  const [containerWidth, setContainerWidth] = useState<number>(0);
  const { width: widthWindow } = useWindowDimensions();
  const isStretched = useMemo(() => {
    if (!containerWidth) return false;
    return containerWidth > minWidthPixelStretch;
  }, [containerWidth, minWidthPixelStretch]);

  const getColumnWidth = useCallback(
    (percentWidth: number, minWidthColumn: number = 0) => {
      const columnWidth = (percentWidth * containerWidth) / 100;
      return minWidthColumn === 0 || isStretched ? columnWidth : minWidthColumn;
    },
    [containerWidth, isStretched]
  );

  useLayoutEffect(() => {
    const mainBox = document.getElementById('mainBox');
    if (mainBox) {
      const mainBoxStyle = getComputedStyle(mainBox, null);
      const paddingLeft = mainBoxStyle
        .getPropertyValue('padding-left')
        .replace('px', '');
      const paddingRight = mainBoxStyle
        .getPropertyValue('padding-right')
        .replace('px', '');
      setContainerWidth(
        mainBox.clientWidth - Number(paddingLeft) - Number(paddingRight)
      );
    }
  }, [widthWindow]);

  return { widthWindow, isStretched, getColumnWidth };
};
