import { ComponentStyleConfig } from '@chakra-ui/react';

export const alertTheme: ComponentStyleConfig = {
  variants: {
    solid: {
      container: {
        borderRadius: 'md',
        width: 'auto',
      },
    },
  },
};
