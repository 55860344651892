import { useState } from 'react';

export type SetHandlerInterceptor = (
  interceptor: ((handler: InterceptedHandler) => void) | undefined
) => void;

export type InterceptedHandler = () => void;

export function useHandlerInterceptor(handler: InterceptedHandler) {
  const [interceptor, setInterceptor] =
    useState<(handler: InterceptedHandler) => void>();

  const interceptedHandler = () => {
    if (interceptor) interceptor(handler);
    else handler();
  };

  return [
    interceptedHandler,
    ((handler) => setInterceptor(() => handler)) as SetHandlerInterceptor,
  ] as const;
}
