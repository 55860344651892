import { Box } from '@chakra-ui/react';

type LabelValueProps = {
  label: string;
  value: string;
};

export function LabelValue({ label, value }: LabelValueProps) {
  return (
    <Box mb={2}>
      <Box color="primaryBlue.500" as="span" mr={4}>
        {label}:
      </Box>
      <Box as="span" aria-label={label}>
        {value}
      </Box>
    </Box>
  );
}
