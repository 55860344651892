import React from 'react';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Switch,
  SwitchProps,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import get from 'lodash-es/get';

export type FormSwitchProps = SwitchProps & {
  label?: string;
  error?: string;
  hideLabel?: boolean;
};

export const FormSwitch = React.forwardRef<HTMLInputElement, FormSwitchProps>(
  ({ label, error, hideLabel, ...props }, ref) => {
    const {
      formState: { errors: formErrors },
    } = useFormContext();
    const errorMessage =
      error ?? (get(formErrors, props.name ?? '')?.message as string);

    return (
      <FormControl variant="floating" isInvalid={!!errorMessage}>
        {label && !hideLabel && <FormLabel>{label}</FormLabel>}
        <Switch aria-label={label} {...props} ref={ref} mt={4} size="lg" />
        {errorMessage && (
          <FormErrorMessage mb={2}>{errorMessage}</FormErrorMessage>
        )}
      </FormControl>
    );
  }
);
