import { useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ItemMastersGrid } from 'features/item-master-list/ItemMastersGrid';
import {
  itemMasterFieldToQuery,
  ItemMasterPageParams,
  queryToItemMasterField,
} from 'features/item-master-list/itemMasterPageParams';
import { SortState } from 'hooks/useGridSorting';
import { ItemMaster } from 'types/itemMaster';
import {
  QueryParamsObject,
  getQueryParamsObject,
  parseFilters,
  parseSort,
  querifyFilters,
  querifySort,
} from 'utils/urlSearchParamsUtils';
import { useStore } from 'contexts/storeContext';
import * as _ from 'lodash-es';
import { focusToField } from 'utils/browserUtils';

export function ItemMastersPage() {
  const [searchParams, setSearchParams] = useSearchParams({
    sortBy: '',
    page: '',
    pageSize: '',
  });
  const [state, dispatch] = useStore();

  const updateSearchParams = useCallback(
    (updating: object) =>
      setSearchParams({
        ...getQueryParamsObject(searchParams),
        page: '1',
        ...updating,
      }),
    [searchParams, setSearchParams]
  );

  const onFilterChange = useCallback(
    (filters: QueryParamsObject) => {
      dispatch(_.pick(filters, ['fieldToFocus']));
      updateSearchParams(
        querifyFilters(
          _.omit(filters, ['fieldToFocus']),
          itemMasterFieldToQuery
        )
      );
    },
    [dispatch, updateSearchParams]
  );

  const onSortByChange = useCallback(
    (sorting: SortState<ItemMaster>) => {
      updateSearchParams({
        sortBy: querifySort(sorting, itemMasterFieldToQuery),
      });
    },
    [updateSearchParams]
  );

  const gridParams: ItemMasterPageParams = {
    initialSort: parseSort(
      searchParams.get('sortBy') || '',
      queryToItemMasterField
    ),
    onSortByChange,
    initialPage: parseInt(searchParams.get('page') || '', 10),
    initialPageSize: parseInt(searchParams.get('pageSize') || '', 10),
    onPaginationChange: (pagination) => updateSearchParams(pagination),
    initialFilters: parseFilters(
      getQueryParamsObject(searchParams),
      queryToItemMasterField
    ),
    onFilterChange,
  };

  useEffect(() => {
    if (state && state.fieldToFocus) {
      focusToField(state.fieldToFocus);
    }
  }, [state]);

  return <ItemMastersGrid {...gridParams} />;
}
