/* istanbul ignore file */
import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import InvitationsTable from 'features/admin/InvitationsTable';
import UsersTable from 'features/admin/UsersTable';
import MSAsTable from 'features/admin/msa-table/MsaTable';
import { useMsaDocuments } from 'queries/admin/useMsaDocuments';
import { useCallback, useEffect, useMemo } from 'react';
import { AUTH0_ROLES } from 'constants/auth0Roles';
import { useAuth0Roles } from 'hooks/useAuth0Roles';

type TabDefinition = {
  type: 'users' | 'invitations' | 'msa';
  label: string;
  component: React.FC;
};

export const AdminPage: React.FC = () => {
  const [searchParams] = useSearchParams({
    tab: 'users',
  });
  const navigate = useNavigate();
  const toast = useToast();
  const isAdmin = useAuth0Roles([AUTH0_ROLES.STANDVAST_ADMIN]);

  const { data, isLoading } = useMsaDocuments({
    refetchOnMount: false,
    enabled: isAdmin,
  });

  const tabs = useMemo(() => {
    return [
      { type: 'users', label: 'Users', component: UsersTable },
      {
        type: 'invitations',
        label: 'Invitations',
        component: InvitationsTable,
      },
      isAdmin && {
        type: 'msa',
        label: 'MSAs',
        component: () => <MSAsTable enableDelete enableUpload />,
      },
    ].filter(Boolean) as TabDefinition[];
  }, [isAdmin]);

  const defaultIndex = tabs.findIndex(
    (t) => t.type === searchParams.get('tab')
  );

  const reportEmptyMSA = useCallback(() => {
    const id = 'empty-msa-toast';
    if (!isLoading && !data?.length && !toast.isActive(id) && isAdmin) {
      toast({
        id,
        status: 'warning',
        title: 'Warning',
        description:
          "There is no current MSA uploaded for this tenant. Please upload this tenant's current MSA.",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, data]);

  useEffect(() => {
    reportEmptyMSA();
  }, [reportEmptyMSA]);

  return (
    <Tabs
      variant="enclosed"
      colorScheme="primaryBlue"
      defaultIndex={defaultIndex !== -1 ? defaultIndex : 0}
      isManual
      isLazy
    >
      <Text as="h2" variant="pageTitle">
        Admin
      </Text>
      <TabList mt={[5, null, 2]}>
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            onClick={() => {
              reportEmptyMSA();
              navigate(`?tab=${tab.type}`);
            }}
            fontSize={{ base: 'xl', md: '2xl' }}
          >
            {tab.label}
          </Tab>
        ))}
      </TabList>
      <TabPanels>
        {tabs.map((tab, index) => (
          <TabPanel key={index} p={0}>
            <tab.component />
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};
