import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { Invitation } from 'types/invitation';
import { mapInvitationToDto } from './mappers';

export function useInviteUser() {
  const queryClient = useQueryClient();
  return useMutation<any, any, Pick<Invitation, 'emailAddress'>>({
    mutationFn: (invitation: Pick<Invitation, 'emailAddress'>) => {
      const mappedInvitation = mapInvitationToDto(invitation);
      return axios.post('/admin/invitations', mappedInvitation);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['invitations'] });
    },
  });
}
