import { Box, Button, ButtonProps } from '@chakra-ui/react';
import AddBoxIcon from '@mui/icons-material/AddBox';

type Props = ButtonProps & {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
};

export const AddBoxButton: React.FC<Props> = ({
  onClick,
  children,
  ...props
}) => (
  <Button
    fontSize="sm"
    mt={3}
    p={0}
    borderWidth={0}
    onClick={onClick}
    _hover={{ backgroundColor: 'transparent', color: 'primaryBlue.900' }}
    {...props}
  >
    <Box mr={2}>
      <AddBoxIcon />
    </Box>
    {children}
  </Button>
);
