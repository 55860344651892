/* istanbul ignore file */
import { Auth0Provider, AppState } from '@auth0/auth0-react';
import { useStore } from 'contexts/storeContext';
import { useNavigate } from 'react-router-dom';
import { CONFIG } from 'utils/config';

export const Auth0ProviderWithRedirectCallback = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const navigate = useNavigate();
  const [_, dispatch] = useStore();
  const onRedirectCallback = (appState?: AppState) => {
    dispatch({ firstLogin: true });
    navigate((appState && appState.returnTo) || window.location.pathname);
  };

  return (
    <Auth0Provider
      onRedirectCallback={onRedirectCallback}
      domain={CONFIG().auth0.domain}
      clientId={CONFIG().auth0.clientId}
      redirectUri={window.location.origin}
      audience={CONFIG().auth0.audience}
    >
      {children}
    </Auth0Provider>
  );
};
