import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { OrganizationDto } from 'types/organization';
import { mapDtoToOrganization } from './mappers';

export const useListOrganizations = () => {
  return useQuery(['organizations'], async () => {
    const response = await axios.get<OrganizationDto[]>('/tenant');
    return response.data.map(mapDtoToOrganization);
  });
};
