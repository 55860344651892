import { useMemo } from 'react';
import {
  Checkbox,
  CheckboxGroup,
  CheckboxGroupProps,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Stack,
  Tooltip,
  useControllableState,
} from '@chakra-ui/react';
import AsteriskIcon from './AsteriskIcon';
import HelpIcon from '@mui/icons-material/Help';

const ALL_OPTION = { label: 'All', value: 'all' };

export type FormCheckboxGroupProps = CheckboxGroupProps & {
  label?: string;
  error?: string;
  hideLabel?: boolean;
  isRequired?: boolean;
  tooltip?: string;
  options: {
    label: string;
    value: string;
    selected?: boolean;
  }[];
};

export const FormCheckboxGroup = ({
  label,
  error,
  hideLabel,
  isRequired,
  isDisabled,
  options: defaultOptions,
  tooltip,
  value,
  onChange,
  ...props
}: FormCheckboxGroupProps) => {
  const realValue = useMemo(() => {
    return value?.length === defaultOptions.length
      ? [ALL_OPTION.value]
      : value ?? [];
  }, [value, defaultOptions]);

  const [internalValue, setInternalValue] = useControllableState<
    (string | number)[]
  >({
    value: realValue,
    onChange,
  });

  const options = useMemo(() => {
    const newOptions = [...defaultOptions];
    newOptions.push(ALL_OPTION);
    return newOptions;
  }, [defaultOptions]);

  function change(selected: (string | number)[]) {
    const previousHasAllOption = internalValue.some(
      (item) => item.toString() === ALL_OPTION.value
    );
    const hasAllOption = selected.includes(ALL_OPTION.value);
    const isSelectAll =
      selected.length === defaultOptions.length && !hasAllOption;
    if ((!previousHasAllOption && hasAllOption) || isSelectAll) {
      setInternalValue(defaultOptions.map((option) => option.value));
    } else {
      setInternalValue(selected.filter((item) => item !== ALL_OPTION.value));
    }
  }

  return (
    <FormControl isInvalid={!!error} isDisabled={isDisabled}>
      {label && !hideLabel ? (
        <FormLabel mb={2} display="flex">
          {label}
          {tooltip ? (
            <Tooltip label={tooltip}>
              <HelpIcon
                fontSize="inherit"
                sx={{
                  marginLeft: '0.3rem',
                  width: '1.3rem',
                  height: '1.3rem',
                }}
              />
            </Tooltip>
          ) : null}
          {isRequired && <AsteriskIcon width="0.375rem" />}
        </FormLabel>
      ) : null}
      <CheckboxGroup
        isDisabled={isDisabled}
        {...props}
        value={internalValue}
        onChange={change}
      >
        <Stack columnGap={5} rowGap={3} direction="row" flexWrap="wrap">
          {options?.map((option) => (
            <Checkbox value={option.value} key={option.value}>
              {option.label}
            </Checkbox>
          ))}
        </Stack>
      </CheckboxGroup>
      {error && (
        <FormErrorMessage mb={2} sx={{ textWrap: 'wrap' }}>
          {error}
        </FormErrorMessage>
      )}
    </FormControl>
  );
};
