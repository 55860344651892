import {
  InventoryItem,
  InventoryItemDto,
  InventorySummary,
  InventorySummaryDto,
} from 'types/inventory';

enum Warehouses {
  BWI1 = 'Columbia, MD',
  RNO2 = 'Reno, NV',
  MEM1 = 'Memphis, TN',
  LGB1 = 'Long Beach, CA',
}

export function mapDtoToInventoryItem(item: InventoryItemDto): InventoryItem {
  return {
    sku: item.sku,
    warehouseId: item.dc_name,
    description: item.description,
    promisedQty: item.qty_allocated,
    availableQty: item.qty_available_to_promise,
    onHoldQty: item.qty_on_hold,
    damagedQty: item.qty_damaged,
    totalQty: item.qty_on_hand,
    uom: item.uom,
    conversionFactor: item.conversion_factor,
  };
}

export function mapDtoToInventorySummary(
  summary: InventorySummaryDto
): InventorySummary {
  return {
    warehouseId: summary.dc_name,
    warehouseName:
      Warehouses[summary.dc_name as keyof typeof Warehouses] ??
      summary.dc_display_name,
    skuCount: summary.sku_count,
    skuQtyOnHand: summary.total_qty,
    avgCubicFtPerSku: summary.avg_cubic_ft_per_sku,
    totalCubicFtAllSkus: Math.round(summary.total_volume_cubic_ft),
    avgWeightPerSku: summary.avg_cubic_ft_per_sku,
    totalWeightAllSkus: Math.round(summary.total_weight),
  };
}
