import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { CountryDto, Country } from 'types/country';
import { mapDtoToCountry } from './mappers';

export function useCountries(
  queryOptions?: Omit<Parameters<typeof useQuery<Country[]>>[2], 'select'>
) {
  return useQuery(
    ['countries'],
    async () => {
      const {
        data: { items },
      } = await axios.get<{ items: CountryDto[] | undefined }>('/countries');

      return items ?? [];
    },
    {
      ...queryOptions,
      select: (data) =>
        data.map(mapDtoToCountry).filter((country) => !country.isUnassigned),
    }
  );
}
