import { extendTheme } from '@chakra-ui/react';
import { buttonTheme } from './components/buttonTheme';
import { modalTheme } from './components/modalTheme';
import { textTheme } from './components/textTheme';
import { tableTheme } from './components/tableTheme';
import { alertTheme } from './components/alertTheme';
import { cardTheme } from './components/cardTheme';
import { dividerTheme } from './components/dividerTheme';
import { inputTheme } from './components/inputTheme';
import { formLabelTheme } from './components/formLabelTheme';
import { selectTheme } from './components/selectTheme';
import { statTheme } from './components/statTheme';
import { linkTheme } from './components/linkTheme';
import { layoutBoxTheme } from './components/layoutBoxTheme';
import { listTheme } from './components/listTheme';
import * as palette from './ui-palette';

export const theme = extendTheme({
  colors: {
    errorRed: palette.ERROR_RED,
    primaryBlue: {
      100: palette.PRIMARY_BLUE_100,
      200: palette.PRIMARY_BLUE_200,
      300: palette.PRIMARY_BLUE_300,
      400: palette.PRIMARY_BLUE_400,
      500: palette.PRIMARY_BLUE_500,
      600: palette.PRIMARY_BLUE_600,
      700: palette.PRIMARY_BLUE_700,
      800: palette.PRIMARY_BLUE_800,
      900: palette.PRIMARY_BLUE_900,
    },
    primaryOrange: {
      100: palette.PRIMARY_ORANGE_100,
      200: palette.PRIMARY_ORANGE_200,
      300: palette.PRIMARY_ORANGE_300,
      400: palette.PRIMARY_ORANGE_400,
      500: palette.PRIMARY_ORANGE_500,
      600: palette.PRIMARY_ORANGE_600,
      700: palette.PRIMARY_ORANGE_700,
      800: palette.PRIMARY_ORANGE_800,
      900: palette.PRIMARY_ORANGE_900,
    },
    secondaryGreen: {
      100: palette.SECONDARY_GREEN_100,
      200: palette.SECONDARY_GREEN_200,
      300: palette.SECONDARY_GREEN_300,
      400: palette.SECONDARY_GREEN_400,
      500: palette.SECONDARY_GREEN_500,
      600: palette.SECONDARY_GREEN_600,
      700: palette.SECONDARY_GREEN_700,
      800: palette.SECONDARY_GREEN_800,
      900: palette.SECONDARY_GREEN_900,
    },
    secondaryTeal: {
      100: palette.SECONDARY_TEAL_100,
      200: palette.SECONDARY_TEAL_200,
      300: palette.SECONDARY_TEAL_300,
      400: palette.SECONDARY_TEAL_400,
      500: palette.SECONDARY_TEAL_500,
      600: palette.SECONDARY_TEAL_600,
      700: palette.SECONDARY_TEAL_700,
      800: palette.SECONDARY_TEAL_800,
      900: palette.SECONDARY_TEAL_900,
    },
    secondaryOrange: {
      100: palette.SECONDARY_ORANGE_100,
      200: palette.SECONDARY_ORANGE_200,
      300: palette.SECONDARY_ORANGE_300,
      400: palette.SECONDARY_ORANGE_400,
      500: palette.SECONDARY_ORANGE_500,
      600: palette.SECONDARY_ORANGE_600,
      700: palette.SECONDARY_ORANGE_700,
      800: palette.SECONDARY_ORANGE_800,
      900: palette.SECONDARY_ORANGE_900,
    },
    secondaryGray: {
      200: palette.SECONDARY_GRAY_200,
      500: palette.SECONDARY_GRAY_500,
      800: palette.SECONDARY_GRAY_800,
      900: palette.SECONDARY_GRAY_900,
    },
  },
  fonts: {
    body: 'Inter, sans-serif',
  },
  components: {
    Modal: modalTheme,
    Button: buttonTheme,
    Text: textTheme,
    Table: tableTheme,
    Alert: alertTheme,
    DashboardCard: cardTheme,
    Divider: dividerTheme,
    Input: inputTheme,
    FormLabel: formLabelTheme,
    Select: selectTheme,
    Stat: statTheme,
    Link: linkTheme,
    LayoutBox: layoutBoxTheme,
    List: listTheme,
  },
});
