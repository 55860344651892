import { VisuallyHidden } from '@chakra-ui/react';

interface Props {
  children: React.ReactNode;
}

// Disclaimer: This component shouldn't exist.
// It was created due to an existing bug caused by the use of Chakra's VisuallyHidden component,
// that generates vertical and horizontal scrollbars much longer than the actual page content on mobile,
// completely breaking the app responsiveness.
// A dirty fix that I found was to explicitly set top and left to 0 in all places where VisuallyHidden is used.
// In order to prevent spreading this hack several times throughout the codebase, I put it in a centered place.
// This component can be safely removed as soon as one of us take the necessary time to find a proper fix.
// BTW, I've also tried to create a theme for Chakra's VisuallyHidden and use `defaultProps` to set
// these props globally, but it didn't work out.
export function Hidden({ children }: Props) {
  return (
    <VisuallyHidden top={0} left={0}>
      {children}
    </VisuallyHidden>
  );
}
