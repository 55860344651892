/* istanbul ignore file */
import * as yup from 'yup';
import { basicSalesOrderInfoSchema } from './formParts/basicSalesOrderInfo';
import { orderLinesSchema } from './formParts/orderLines';
import { carrierInfoSchema } from './formParts/carrierInfo';
import { shipToAddressSchema } from './formParts/shipToAddress';

export const createParcelSalesOrderSchema = basicSalesOrderInfoSchema
  .concat(carrierInfoSchema)
  .concat(yup.object({ shipTo: shipToAddressSchema, lines: orderLinesSchema }));
