import {
  Box,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CreateItemMastersPage } from './CreateItemMastersPage';
import { UpdateItemMastersPage } from './UpdateItemMastersPage';

type TabDefinition = {
  type: 'create' | 'update';
  label: string;
  component: React.FC;
};

const TABS: TabDefinition[] = [
  { type: 'create', label: 'Create', component: CreateItemMastersPage },
  { type: 'update', label: 'Update', component: UpdateItemMastersPage },
];

export function UploadItemMastersPage() {
  const location = useLocation();
  const navigate = useNavigate();

  const defaultIndex = TABS.findIndex((t) =>
    location.pathname.includes(`/item-masters/${t.type}`)
  );

  return (
    <Box>
      <Text mb={3} as="h2" variant="pageTitle">
        Item Master: Upload Files
      </Text>
      <Tabs
        variant="enclosed"
        colorScheme="primaryBlue"
        defaultIndex={defaultIndex !== -1 ? defaultIndex : 0}
        isManual
        isLazy
      >
        <TabList mb={4}>
          {TABS.map((tab, index) => (
            <Tab
              key={index}
              onClick={() => navigate(`/item-masters/${tab.type}`)}
              fontSize={{ base: 'xl', md: '2xl' }}
            >
              {tab.label}
            </Tab>
          ))}
        </TabList>
        <TabPanels>
          {TABS.map((tab, index) => (
            <TabPanel key={index} p={0}>
              <tab.component />
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </Box>
  );
}
