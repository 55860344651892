import {
  Box,
  SimpleGrid,
  Stack,
  Stat,
  StatLabel,
  StatNumber,
  Text,
} from '@chakra-ui/react';
import LaunchIcon from '@mui/icons-material/Launch';
import { Link } from 'components/Link';
import { useInventorySummary } from 'queries/inventory/useInventorySummary';
import { InlineText } from 'components/InlineText';
import { Shimmer } from 'components/Shimmer';
import { ExceptionState } from 'components/ExceptionState';

export const InventorySummary = () => {
  const {
    data: summaries = [],
    isLoading,
    error,
    refetch,
  } = useInventorySummary();
  const columns = isLoading ? 3 : summaries.length > 2 ? 3 : summaries.length;
  return (
    <Box py={5}>
      {!isLoading && !!error ? (
        <ExceptionState stateType="error" retryHandler={refetch} />
      ) : !isLoading && !summaries?.length ? (
        <ExceptionState stateType="empty" />
      ) : (
        <SimpleGrid gap={3} columns={[1, 1, columns, 1, columns]}>
          {(!isLoading
            ? summaries.filter((summary) => summary.skuCount > 0)
            : new Array(3).fill('')
          ).map((summary, index) => {
            return (
              <Box
                borderRightWidth={[0, 0, 2, 0, 2]}
                borderBottomWidth={[2, 2, 0, 2, 0]}
                borderStyle="solid"
                borderColor="secondaryTeal.400"
                _last={{ border: 0 }}
                key={index}
                px={1}
              >
                <Shimmer isLoaded={!isLoading} w="80%" mb={3}>
                  <Link
                    variant="headerLink"
                    to={`/inventory?dc_name_select=${summary?.warehouseId}`}
                  >
                    <Box
                      as={Text}
                      fontWeight={700}
                      mr={1}
                      display="flex"
                      alignItems="center"
                      gap={1}
                      whiteSpace="nowrap"
                    >
                      {summary?.warehouseName}
                      <LaunchIcon fontSize="inherit" />
                    </Box>
                  </Link>
                </Shimmer>
                <Stack direction="column" spacing={0} mb={[5, 5, 0, 5, 0]}>
                  <Stat variant="primary">
                    <StatNumber>
                      <Shimmer isLoaded={!isLoading} h="28px" w="40%" mb={1}>
                        {summary?.skuQtyOnHand?.toLocaleString('en-US')}
                      </Shimmer>
                    </StatNumber>
                    <StatLabel mb={1}>Total quantity</StatLabel>
                  </Stat>
                  <Stat variant="primary">
                    <StatNumber>
                      <Shimmer isLoaded={!isLoading} h="28px" w="80%" mb={1}>
                        {summary?.totalWeightAllSkus?.toLocaleString('en-US')}
                        <InlineText ml={1} variant="unitOfMeasure">
                          lbs
                        </InlineText>
                      </Shimmer>
                    </StatNumber>
                    <StatLabel mb={1}>Total weight</StatLabel>
                  </Stat>
                  <Stat variant="primary">
                    <StatNumber>
                      <Shimmer isLoaded={!isLoading} h="28px" w="60%" mb={1}>
                        {summary?.totalCubicFtAllSkus?.toLocaleString('en-US')}
                        <InlineText ml={1} variant="unitOfMeasure">
                          ft<sup>3</sup>
                        </InlineText>
                      </Shimmer>
                    </StatNumber>
                    <StatLabel mb={1}>Total volume</StatLabel>
                  </Stat>
                </Stack>
              </Box>
            );
          })}
        </SimpleGrid>
      )}
    </Box>
  );
};
