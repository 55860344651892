import {
  NetworkOrder,
  NetworkOrderDto,
  NetworkOrderDetails,
  NetworkOrderDetailsDto,
} from 'types/networkOrder';

export function mapDtoToNetworkOrder(dto: NetworkOrderDto): NetworkOrder {
  return {
    noName: dto.no_name,
    status: dto.status,
    shipVia: dto.ship_via,
    shipmentCount: dto.shipment_count,
    createdAt: dto.created_at,
    statusUpdatedAt: dto.status_updated_at,
  };
}

export function mapDtoToNetworkOrderDetails(
  dto: NetworkOrderDetailsDto
): NetworkOrderDetails {
  return {
    name: dto.name,
    poNumber: dto.po_number,
    shipVia: dto.ship_via,
    shipOption: dto.ship_option,
    targetDc: dto.target_dc,
    carrierAccountUuid: dto.carrier_account_uuid,
    dueDate: dto.due_date,
    desiredDeliveryDate: dto.desired_delivery_date,
    earliestDueDate: dto.earliest_due_date,
    instructions: dto.instructions,
    giftMessage: dto.gift_message,
    orderSource: dto.order_source,
    customerOrderReference: dto.customer_order_reference,
    status: dto.status,
    statusUpdatedAt: dto.status_updated_at,
    createdAt: dto.created_at,
    errorType: dto.error_type,
    errorMsg: dto.error_msg,
    address: {
      address: dto.address.address_1,
      address2: dto.address.address_2 ?? '',
      address3: dto.address.address_3,
      city: dto.address.city,
      country: dto.address.country,
      email: dto.address.email ?? '',
      name: dto.address.name,
      phoneNumber: dto.address.phone_number,
      state: dto.address.state,
      zipCode: dto.address.postal_code,
    },
    lineItems: dto.line_items.map((lineItem) => ({
      lineNumber: lineItem.line_number,
      qtyRequested: lineItem.qty_requested,
      qtyFulfilled: lineItem.qty_fulfilled,
      sku: lineItem.sku,
      externalItemNumber: lineItem.external_item_number,
    })),
    shipments: dto.shipments.map((shipment) => ({
      shipmentNumber: shipment.shipment_number,
      poNumber: shipment.po_number,
      targetDc: shipment.target_dc,
      status: shipment.status,
      createdAt: shipment.created_at,
    })),
  };
}
