/* istanbul ignore file */
import { RATE_SHOP_OPTION } from 'queries/shipVias/useShipVias';
import * as yup from 'yup';
import { SELECT_NODE_OPTION } from 'queries/warehouses/useWarehouses';
import { usAndTerritories } from 'features/create-order/components/CarrierAccountSelect';

export const carrierInfoSchema = yup
  .object({
    carrierAccountUUID: yup
      .string()
      .label('Carrier Account UUID')
      .max(36)
      .test(
        'is-required-carrierAccountUUID',
        () => 'Carrier Account UUID is required',
        function (state = '') {
          const isNodeOptionSelected =
            this.parent.warehouseID === SELECT_NODE_OPTION.code;
          return (
            (!isNodeOptionSelected &&
              ((this.parent.type === 'Parcel' && !!state) ||
                this.parent.type === 'Freight')) ||
            isNodeOptionSelected
          );
        }
      ),
    shipOption: yup
      .string()
      .label('Service Level')
      .test(
        'is-required-ship-option',
        () => 'Rate Shop requires a Service Level value',
        function (state = '') {
          const isRateShopOption = this.parent.shipVia === RATE_SHOP_OPTION;
          const isInternational =
            this.parent.shipTo.country &&
            !usAndTerritories.includes(this.parent.shipTo.country);
          return (
            (isRateShopOption && !!state) ||
            !isRateShopOption ||
            isInternational
          );
        }
      ),
  })
  .required();
