import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { mapUserProfileModifyToDto } from 'mutations/userProfile/mappers';
import { UserProfileModify } from 'types/userProfile';

export function useUpdateUserProfile(userId: string) {
  return useMutation<any, any, UserProfileModify>({
    mutationFn: async (profile: UserProfileModify) => {
      const UserProfileModifyDto = mapUserProfileModifyToDto(profile);
      const roles = profile.roles?.map((role) => role.value) || [];

      const updateProfileResponse = await axios.post(
        `/profile/${userId}`,
        UserProfileModifyDto
      );

      if (roles.length > 0) {
        await axios.post(`/admin/users/${userId}/roles`, { role_ids: roles });
      }

      return updateProfileResponse;
    },
  });
}
