import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { ShipVia, ShipViaDto } from 'types/shipVia';
import { mapDtoToShipVia } from './mappers';
import { useCallback } from 'react';

export const RATE_SHOP_OPTION = 'RATE_SHOP';

export function useShipVias(
  queryOptions?: Omit<Parameters<typeof useQuery<ShipVia[]>>[2], 'select'>
) {
  const queryState = useQuery(
    ['shipVias'],
    async () => {
      const {
        data: { items },
      } = await axios.get<{ items: ShipViaDto[] | undefined }>('/shipvias');

      return items ?? [];
    },
    { ...queryOptions, select: (data) => data.map(mapDtoToShipVia) }
  );

  const getDefaultShipVia = useCallback(() => {
    return (
      queryState.data?.find((option) => option.shipVia === RATE_SHOP_OPTION)
        ?.shipVia ?? ''
    );
  }, [queryState.data]);

  return {
    ...queryState,
    getDefaultShipVia,
  };
}
