/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react';
import { GridItem, SimpleGrid } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider } from 'react-hook-form';
import { ButtonGroup } from 'components/ButtonGroup';
import { useConfirmationDialog } from 'hooks/useConfirmationDialog';
import { SetHandlerInterceptor } from 'hooks/useHandlerInterceptor';
import { useUpdateSalesOrder } from 'mutations/salesOrders';
import { SalesOrder } from 'types/salesOrders';
import { modifyOrderSchema } from 'schemas/modifyOrder';
import { OrderLines } from 'features/order-lines/OrderLines';
import { ShipToForm } from './ShipToForm';
import { useCrudActionUserFeedback } from 'hooks/useCrudActionUserFeedback';
import { mapFieldDtoErrorToRhfPath } from 'mutations/salesOrders/mappers';
import { sendUpdateSalesOrderEvent } from 'utils/monitorEvents';
import { DatesForm } from './DatesForm';
import { CodesForm } from './CodesForm';
import { ShipViaForm } from './ShipViaForm';
import { useSalesOrderForm } from 'hooks/useSalesOrderForm';
import { GiftMessageForm } from 'features/create-order/components/GiftMessageForm';
import { BolBillingAddressForm } from 'features/create-order/components/BolBillingAddressForm';
import { BolDataForm } from 'features/create-order/components/BolDataForm';
import { StorefrontSelect } from 'features/create-order/components/StorefrontSelect';
import CarrierAccountSelect from 'features/create-order/components/CarrierAccountSelect';
import { SectionCollapsable } from 'components/SectionCollapsable';

type Props = {
  order: SalesOrder;
  onConfirm: () => void;
  onAbort: () => void;
  setCanClose: SetHandlerInterceptor;
};

export function ModifyOrder({ order, onAbort, onConfirm, setCanClose }: Props) {
  const [isProcessing, setIsProcessing] = useState(false);
  const { openConfirmationDialog } = useConfirmationDialog();

  const reactHookFormRet = useSalesOrderForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: order,
    resolver: yupResolver(modifyOrderSchema),
  });

  const {
    handleSubmit,
    getValues,
    formState: { isDirty, isSubmitting },
  } = reactHookFormRet;

  const isFreightOrder = order.type === 'Freight';
  const bolTooltipMessage =
    'This information will be automatically updated upon carrier pickup completion.';

  const confirmAbort = useCallback(
    (callback: () => void) => {
      if (isDirty) {
        openConfirmationDialog({
          title: 'Discard changes',
          message: `Discard changes to #${order.soNumber} before leaving?`,
          onConfirm: callback,
          blockScrollOnMount: false,
          confirmButtonLabel: 'Discard',
        });
      } else {
        callback();
      }
    },
    [isDirty, order.soNumber]
  );

  useEffect(() => {
    setCanClose(confirmAbort);
  }, [confirmAbort]);

  const { mutateAsync } = useUpdateSalesOrder(order.soNumber);

  const handleUpdateOrder = useCrudActionUserFeedback<SalesOrder>({
    mutateAsync: () => mutateAsync(getValues()),
    actionType: 'UPDATE',
    successMessage: `Order #${order.soNumber} successfully updated`,
    successCallback: () => {
      sendUpdateSalesOrderEvent(order.soNumber);
      onConfirm();
    },
    startCallback: () => {
      setIsProcessing(true);
    },
    finallyCallback: () => {
      setIsProcessing(false);
    },
    setFieldError: reactHookFormRet.setError,
    mapFieldDtoErrorToRhfPath,
  });

  if (order.type === 'Parcel' && !order.carrierAccountUUID) return null;

  return (
    <FormProvider {...reactHookFormRet}>
      <form onSubmit={handleSubmit(handleUpdateOrder)}>
        <SimpleGrid columns={1}>
          <GridItem>
            <ShipToForm startTabIndex={0} />
          </GridItem>
          {isFreightOrder ? (
            <>
              <GridItem>
                <SimpleGrid columns={[1, 2]} columnGap={8}>
                  <StorefrontSelect tabIndex={10} />
                  <DatesForm startTabIndex={10} />
                  <CodesForm startTabIndex={11} />
                </SimpleGrid>
              </GridItem>
              <GridItem mt={8}>
                <SectionCollapsable
                  title="BOL Data"
                  defaultIsOpen={false}
                  tooltip={bolTooltipMessage}
                >
                  <BolDataForm startTabIndex={13} editable={false} />
                </SectionCollapsable>
              </GridItem>
              <GridItem mt={8}>
                <SectionCollapsable
                  title="BOL Billing Address"
                  defaultIsOpen={false}
                  tooltip={bolTooltipMessage}
                >
                  <BolBillingAddressForm startTabIndex={17} editable={false} />
                </SectionCollapsable>
              </GridItem>
            </>
          ) : (
            <GridItem>
              <SimpleGrid columns={[1, 2]} columnGap={8}>
                <StorefrontSelect tabIndex={10} />
                <ShipViaForm startTabIndex={10} />
                <CarrierAccountSelect tabIndex={13} />
              </SimpleGrid>
              <SimpleGrid columns={1} mt={8}>
                <GiftMessageForm tabIndex={14} />
              </SimpleGrid>
            </GridItem>
          )}
          <GridItem mt={8}>
            <OrderLines
              startTabIndex={25}
              title="SKUs"
              isFreightOrder={isFreightOrder}
            />
          </GridItem>
          <GridItem />
          <GridItem>
            <ButtonGroup
              wrapperProps={{ display: 'grid', gridGap: 6 }}
              commonButtonProps={{ width: '100%' }}
              buttons={[
                {
                  label: 'Update Order',
                  variant: 'cta',
                  isLoading: isProcessing || isSubmitting,
                },
                {
                  label: 'Cancel',
                  handler: () => confirmAbort(onAbort),
                },
              ]}
            />
          </GridItem>
        </SimpleGrid>
      </form>
    </FormProvider>
  );
}
