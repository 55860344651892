import { GridItem, SimpleGrid, useToast } from '@chakra-ui/react';
import { ButtonGroup } from 'components/ButtonGroup';
import { FormCheckboxGroup } from 'components/FormCheckboxGroup';
import { FormDateRangePicker } from 'components/FormDateRangePicker';
import { FormSelect } from 'components/FormSelect';
import { useCrudActionUserFeedback } from 'hooks/useCrudActionUserFeedback';
import { useDownloadTransportationDetailsReport } from 'mutations/reports/useDownloadTransportationDetailsReport';
import { useWarehouses } from 'queries/warehouses/useWarehouses';
import { useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { ExportTransporationDetails } from 'schemas/exportTransportationDetails';
import {
  TransportationDateType,
  TransportationReportType,
} from 'types/transportationDetails';
import { parseDate } from 'utils/dateUtils';
import { resources } from 'utils/reportUtils';
import { subDays } from 'date-fns';

export function TransportationDetailsForm() {
  const toast = useToast();
  const {
    register,
    setValue,
    trigger,
    watch,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useFormContext<ExportTransporationDetails>();
  const dateType = watch('dateType');
  const customDateRange = watch('dateRange') ?? [];
  const selectedFC = watch('fc') ?? [];
  const [maxDate] = useState(new Date());
  const [minDate] = useState(subDays(maxDate, 90));

  const { data: warehouses } = useWarehouses({
    onSuccess: (data) => {
      setValue(
        'fc',
        data.map((item) => item.code)
      );
    },
  });

  const fcOptions = useMemo(() => {
    return (
      warehouses?.map((item) => ({
        label: item.code,
        value: item.code,
      })) ?? []
    );
  }, [warehouses]);

  const { mutateAsync: downloadReport, isLoading: isDownloading } =
    useDownloadTransportationDetailsReport();

  const exportCsv = useCrudActionUserFeedback({
    mutateAsync: () => downloadReport(getValues()),
    successMessage: 'Report successfully downloaded',
    customErrorHandler: (error) => {
      const errorData = (error.response?.data as { detail?: string }) ?? {};
      toast({
        status: 'error',
        title: 'Error',
        description: errorData.detail ?? error.message,
      });
    },
  });

  return (
    <SimpleGrid
      maxW={{ base: '100%', lg: '700px' }}
      px={2}
      as="form"
      onSubmit={handleSubmit(exportCsv)}
    >
      <GridItem>
        <FormSelect
          label="Type"
          isRequired
          placeholder="Please select a type"
          {...register('type')}
          options={[
            { label: 'Shipment', value: TransportationReportType.Shipment },
            { label: 'Billing', value: TransportationReportType.Billing },
          ]}
          tooltip='Select the transportation details you want to download. "Billing" lists each charge type for a shipment as a separate row of data. "Shipment" lists one row per shipment, with separate columns for each of the several types of charges that apply to shipments.'
        />
      </GridItem>
      <GridItem>
        <FormSelect
          label="Date Range"
          isRequired
          placeholder="Please select a date range"
          {...register('dateType')}
          options={[
            {
              label: 'Previous Week',
              value: TransportationDateType.PreviousWeek,
            },
            {
              label: 'Previous Four Weeks',
              value: TransportationDateType.PreviousFourWeeks,
            },
            {
              label: 'Custom Date Range',
              value: TransportationDateType.CustomDateRange,
            },
          ]}
          tooltip="The date range of the report. Weeks are Sunday to Saturday."
        />
      </GridItem>
      {dateType === TransportationDateType.CustomDateRange ? (
        <GridItem>
          <FormDateRangePicker
            label="Custom Date Range"
            aria-label="Custom Date Range"
            placeholder="Please select a custom date range"
            isRequired
            isClearable
            preventOpenOnFocus
            variant="flushed"
            minDate={minDate}
            maxDate={maxDate}
            defaultValue={[
              parseDate(customDateRange[0]) ?? '',
              parseDate(customDateRange[1]) ?? '',
            ]}
            onCalendarClose={() => trigger(['dateRange'])}
            onBlur={() => trigger(['dateRange'])}
            onChange={(value) => {
              setValue('dateRange', value);
            }}
            error={errors.dateRange?.message}
            tooltip="Select a custom date range within the past 90 days for the report. Please contact your Client Services Manager for assistance if you need data beyond 90 days."
          />
        </GridItem>
      ) : null}
      <GridItem>
        <FormCheckboxGroup
          tooltip="Filter the report by Fulfillment Center. Defaulted to All."
          label="Fulfillment Center"
          isRequired
          options={fcOptions}
          value={selectedFC}
          onChange={(selected) =>
            setValue('fc', selected as string[], {
              shouldDirty: true,
              shouldValidate: true,
            })
          }
          error={errors.fc?.message}
        />
      </GridItem>
      <GridItem>
        <ButtonGroup
          buttons={[
            {
              isLoading: isDownloading,
              buttonProps: { type: 'submit' },
              label: resources.labels.button,
            },
          ]}
          wrapperProps={{
            display: 'flex',
            flexDirection: ['column-reverse', null, 'row'],
            width: 'auto',
            mt: 6,
          }}
          commonButtonProps={{ fontSize: 'lg' }}
          commonButtonWrapperProps={{ mr: 0 }}
        />
      </GridItem>
    </SimpleGrid>
  );
}
