import { Box, Text } from '@chakra-ui/react';

export function InstructionTemplates() {
  return (
    <Box mb={4}>
      <Text
        as="h2"
        aria-label="Templates"
        variant="pageTitle"
        mb={{ base: 3, lg: 3 }}
      >
        Templates
      </Text>
      <Text as="div" fontWeight={500}>
        Use these templates to simplify bulk actions into Standvast.
      </Text>
    </Box>
  );
}
