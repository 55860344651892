/* istanbul ignore file */
import * as yup from 'yup';
import { basicSalesOrderInfoSchema } from './formParts/basicSalesOrderInfo';
import { carrierInfoSchema } from './formParts/carrierInfo';
import { orderLinesSchema } from './formParts/orderLines';
import { shipToAddressSchema } from './formParts/shipToAddress';

export const modifyOrderSchema = yup
  .object({
    shipTo: shipToAddressSchema,
    lines: orderLinesSchema,
    shipVia: yup.string().label('Carrier').required().max(50),
    warehouseID: yup.string().nullable().optional(),
    earliestDueDate: yup
      .string()
      .label('Earliest Due Date')
      .optional()
      .nullable(),
  })
  .concat(basicSalesOrderInfoSchema.pick(['dueDate', 'storefrontName']))
  .concat(carrierInfoSchema);
