import {
  TransportationDateType,
  TransportationReportType,
} from 'types/transportationDetails';
import { subWeeks, startOfToday } from 'date-fns';
import { formatDate, toISOInUTC } from 'utils/dateUtils';
import { ExportTransporationDetails } from 'schemas/exportTransportationDetails';

export function mapTransportationDateTypeToDateRange(
  type: TransportationDateType
) {
  let startDate: Date | null = null;
  const endDate = startOfToday();
  if (type === TransportationDateType.PreviousWeek) {
    startDate = subWeeks(endDate, 1);
  } else if (type === TransportationDateType.PreviousFourWeeks) {
    startDate = subWeeks(endDate, 4);
  }
  return startDate
    ? [
        formatDate(toISOInUTC(startDate), 'YYYY-MM-DD', { timeZone: 'UTC' }),
        formatDate(toISOInUTC(endDate), 'YYYY-MM-DD', { timeZone: 'UTC' }),
      ]
    : [];
}

export function mapTransportationDetailsToDto(
  data: ExportTransporationDetails
) {
  const dateRange =
    data.dateType === TransportationDateType.CustomDateRange
      ? data.dateRange!.map((date) =>
          formatDate(date, 'YYYY-MM-DD', { timeZone: 'UTC' })
        )
      : mapTransportationDateTypeToDateRange(data.dateType);

  if (data.type === TransportationReportType.Billing) {
    return {
      dcs: data.fc,
      bill_date_from: dateRange[0],
      bill_date_to: dateRange[1],
    };
  }
  return {
    dcs: data.fc,
    ship_date_from: dateRange[0],
    ship_date_to: dateRange[1],
  };
}
