import { Text } from '@chakra-ui/react';
import React, { ErrorInfo, ReactNode } from 'react';
import { ExceptionState } from './ExceptionState';

interface Props {
  children: ReactNode;
  size?: 'compact' | 'full';
}

interface State {
  hasError: boolean;
}

export class ErrorBoundary extends React.Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
  }

  public render() {
    return this.state.hasError ? (
      this.props.size === 'compact' ? (
        <Text>⚠️ An error has occurred</Text>
      ) : (
        <ExceptionState
          stateType="error"
          customTitle="An error has occurred"
          customErrorMessage="Something went wrong. Please try again later."
        />
      )
    ) : (
      this.props.children
    );
  }
}
