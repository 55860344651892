import { Box } from '@chakra-ui/react';
import { ComponentType, FC } from 'react';
export const MAX_WIDTH_MAIN_LAYOUT = 1400;

const withLayout = (Component: ComponentType): FC => {
  return function withLayout(): JSX.Element {
    return (
      <Box maxW={MAX_WIDTH_MAIN_LAYOUT} mx="auto">
        <Component />
      </Box>
    );
  };
};

export default withLayout;
