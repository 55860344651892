import React from 'react';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputProps,
  Tooltip,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import get from 'lodash-es/get';
import AsteriskIcon from './AsteriskIcon';
import HelpIcon from '@mui/icons-material/Help';

export type FormInputProps = InputProps & {
  label?: string;
  placeholder?: string;
  error?: string;
  hideLabel?: boolean;
  isRequired?: boolean;
  tooltip?: string;
};

export const FormInput = React.forwardRef<HTMLInputElement, FormInputProps>(
  (
    {
      label,
      placeholder,
      error,
      hideLabel,
      isRequired,
      isDisabled,
      tooltip,
      ...props
    },
    ref
  ) => {
    const {
      formState: { errors: formErrors },
    } = useFormContext();
    const errorMessage =
      error ?? (get(formErrors, props.name ?? '')?.message as string);

    return (
      <FormControl
        variant="floating"
        isInvalid={!!errorMessage}
        isDisabled={isDisabled}
      >
        {label && !hideLabel ? (
          <FormLabel display="flex" position="relative" zIndex={1}>
            {label}{' '}
            {tooltip ? (
              <Tooltip label={tooltip}>
                <HelpIcon
                  fontSize="inherit"
                  sx={{
                    marginLeft: '0.3rem',
                    width: '1.3rem',
                    height: '1.3rem',
                  }}
                />
              </Tooltip>
            ) : null}{' '}
            {isRequired ? <AsteriskIcon width="0.375rem" /> : null}
          </FormLabel>
        ) : null}
        <Input
          aria-label={label}
          placeholder={placeholder}
          {...props}
          ref={ref}
        />
        {errorMessage && (
          <FormErrorMessage mb={2} sx={{ textWrap: 'wrap' }}>
            {errorMessage}
          </FormErrorMessage>
        )}
      </FormControl>
    );
  }
);
