import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { SalesOrder } from 'types/salesOrders';
import { mapSalesOrderToDto } from './mappers';

export function useUpdateSalesOrder(soNumber: string) {
  const queryClient = useQueryClient();
  return useMutation<any, any, SalesOrder>({
    mutationFn: (order: SalesOrder) => {
      const orderDto = mapSalesOrderToDto(order);
      orderDto.ship_to_address.phone_number =
        orderDto.ship_to_address.phone_number.replace(/\D/g, '');

      if (orderDto.ship_to_address.state === '') {
        orderDto.ship_to_address.state = orderDto.ship_to_address.country;
      }

      return axios.put(`/salesorder/${soNumber}`, {
        request_transaction_id: '',
        order: orderDto,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['salesOrder'],
      });
      queryClient.invalidateQueries({
        queryKey: ['salesOrders'],
      });
    },
  });
}
