/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react';
import { GridItem, SimpleGrid } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider } from 'react-hook-form';
import { ButtonGroup } from 'components/ButtonGroup';
import { SetHandlerInterceptor } from 'hooks/useHandlerInterceptor';
import { PurchaseOrder } from 'types/purchaseOrders';
import { schedulePurchaseOrderSchema } from 'schemas/schedulePurchaseOrder';
import { OrderLines } from 'features/order-lines/OrderLines';
import { useCreatePurchaseOrder } from 'mutations/purchaseOrders/useCreatePurchaseOrder';
import { SchedulePurchaseOrderForm } from './SchedulePurchaseOrderForm';
import { useCrudActionUserFeedback } from 'hooks/useCrudActionUserFeedback';
import { mapFieldDtoErrorToRhfPath } from 'mutations/purchaseOrders/mappers';
import { sendSchedulePurchaseOrderEvent } from 'utils/monitorEvents';

type Props = {
  onConfirm: () => void;
  onAbort: () => void;
  setCloseInterceptor: SetHandlerInterceptor;
};

const sessionStorageKey = 'schedule_po_draft';

export function SchedulePurchaseOrder({
  onAbort,
  onConfirm,
  setCloseInterceptor,
}: Props) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const draft = sessionStorage.getItem(sessionStorageKey);
  const reactHookFormRet = useForm<PurchaseOrder>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: draft
      ? JSON.parse(draft)
      : { lines: [{ lineNo: '001', orderQty: 1 }] },
    resolver: yupResolver(schedulePurchaseOrderSchema),
  });
  const {
    handleSubmit,
    getValues,
    formState: { isDirty },
  } = reactHookFormRet;

  const confirmAbort = useCallback(
    (callback: () => void) => {
      if (isDirty) {
        sessionStorage.setItem(sessionStorageKey, JSON.stringify(getValues()));
      }

      callback();
    },
    [isDirty]
  );

  useEffect(() => {
    setCloseInterceptor(confirmAbort);
  }, [confirmAbort]);

  const { mutateAsync } = useCreatePurchaseOrder();

  const handleScheduleOrder = useCrudActionUserFeedback<PurchaseOrder>({
    mutateAsync: () => mutateAsync(getValues()),
    actionType: 'CREATE',
    successMessage: 'PO successfully scheduled',
    successCallback: () => {
      sendSchedulePurchaseOrderEvent();
      onConfirm();
      sessionStorage.removeItem(sessionStorageKey);
    },
    startCallback: () => {
      setIsSubmitting(true);
    },
    finallyCallback: () => {
      setIsSubmitting(false);
    },
    setFieldError: reactHookFormRet.setError,
    mapFieldDtoErrorToRhfPath,
  });

  return (
    <FormProvider {...reactHookFormRet}>
      <form onSubmit={handleSubmit(handleScheduleOrder)}>
        <SimpleGrid columns={1} gap={8} mt={{ base: 5, lg: 0 }}>
          <GridItem>
            <SchedulePurchaseOrderForm />
          </GridItem>
          <GridItem>
            <OrderLines isPurchaseOrder title="SKUs and quantities inbound" />
          </GridItem>
          <GridItem>
            <ButtonGroup
              wrapperProps={{ display: 'grid', gridGap: 6 }}
              commonButtonProps={{ width: '100%' }}
              buttons={[
                {
                  label: 'Submit Inbound',
                  variant: 'cta',
                  isLoading: isSubmitting,
                },
                {
                  label: 'Cancel',
                  handler: () => confirmAbort(onAbort),
                },
              ]}
            />
          </GridItem>
        </SimpleGrid>
      </form>
    </FormProvider>
  );
}
