import {
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  Stack,
} from '@chakra-ui/react';
import { HeadingContentFilterProps } from 'hooks/useGridFiltering';
import { useCallback, useMemo } from 'react';
import { getSelectOptions } from 'utils/tableUtils';

export function MultiSelectionFilter({
  columnId,
  defaultSelectedValues,
  columnMetaData,
  onFilterChange,
  canFilter,
}: Omit<HeadingContentFilterProps, 'defaultFilterValue'> & {
  defaultSelectedValues: string[];
  columnId: string;
}) {
  const changeFilter = useCallback(
    (values: string[]) => {
      onFilterChange(columnId, values.join(','));
    },
    [onFilterChange, columnId]
  );

  const selectOptions = useMemo(
    () => getSelectOptions(columnMetaData.selectOptions),
    [columnMetaData.selectOptions]
  );

  const selectedOptions = useMemo(() => {
    if (defaultSelectedValues.length === 0) {
      return selectOptions;
    }
    return defaultSelectedValues;
  }, [defaultSelectedValues, selectOptions]);

  const allOptionsSelected = useMemo(() => {
    return selectedOptions.length === selectOptions.length;
  }, [selectedOptions, selectOptions]);

  const isOnlySelectedOption = (option: string) => {
    return selectedOptions.length === 1 && selectedOptions[0] === option;
  };

  const handleOnlyButtonClick = (value: string) => {
    changeFilter([value]);
  };

  const handleClear = () => {
    changeFilter([]);
  };

  const onSelectionChanged = (values: string[]) => {
    if (values.length === selectOptions.length) {
      changeFilter([]);
    } else {
      changeFilter(values ?? []);
    }
  };

  if (selectOptions.length === 0) {
    return <></>;
  }

  return (
    <>
      <PopoverContent
        background="white"
        width="fit-content"
        borderRadius={2}
        outline="1px solid"
        outlineColor="primaryBlue.500"
      >
        <PopoverCloseButton />
        <PopoverBody px={4} pt={8} pb={4} fontWeight="normal" color="black">
          <Stack>
            {!allOptionsSelected && (
              <Button
                mt={-6}
                size="sm"
                onClick={handleClear}
                variant="link"
                position="absolute"
                hidden={allOptionsSelected}
              >
                Clear
              </Button>
            )}
            <CheckboxGroup
              isDisabled={!canFilter}
              value={selectedOptions}
              onChange={(selectedOptions) =>
                onSelectionChanged(selectedOptions as string[])
              }
            >
              <Stack spacing={3}>
                {columnMetaData.selectOptions?.map((optionData) => {
                  const isOptionString = typeof optionData === 'string';
                  let option = isOptionString ? optionData : optionData.value;
                  let optionLabel = isOptionString
                    ? optionData
                    : optionData.label;
                  return (
                    <Checkbox
                      size="sm"
                      value={option}
                      key={option}
                      disabled={isOnlySelectedOption(option)}
                      sx={{
                        '.multiselect-item__label:hover .multiselect-item__only-button':
                          { opacity: 1 },
                      }}
                    >
                      <Flex className="multiselect-item__label">
                        <span>
                          {columnMetaData.formatOptions
                            ? formatSelectOption(optionLabel)
                            : optionLabel}
                        </span>
                        {!isOnlySelectedOption(option) && (
                          <Button
                            variant="link"
                            size="sm"
                            ml={3}
                            className="multiselect-item__only-button"
                            opacity={0}
                            onClick={() => handleOnlyButtonClick(option)}
                          >
                            only
                          </Button>
                        )}
                      </Flex>
                    </Checkbox>
                  );
                })}
              </Stack>
            </CheckboxGroup>
          </Stack>
        </PopoverBody>
      </PopoverContent>
    </>
  );
}

const formatSelectOption = (option: string): string => {
  return option
    .split(/-| /)
    .map((word) => word[0].toUpperCase() + word.slice(1).toLocaleLowerCase())
    .join(' ');
};
