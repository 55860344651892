/* istanbul ignore file */
import * as yup from 'yup';

export const modifyUserProfile = yup.object({
  firstName: yup.string().label('First Name').required().max(150),
  lastName: yup.string().label('Last Name / Family Name').required().max(150),
  nickName: yup.string().label('Nickname').max(150),
  pronouns: yup.string().label('Pronouns').max(150),
  phoneNumber: yup.string().label('Phone Number').max(15),
  department: yup.string().label('Department').max(150),
  jobTitle: yup.string().label('Job Title').max(120),
  roles: yup.array().label('Role'),
});
