import { Button } from '@chakra-ui/react';
import { useMsaFile } from 'queries/admin/useMsaFile';
import { useState } from 'react';

type Props = {
  fileName: string;
};

export function MsaLink({ fileName }: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const { getPdfFile } = useMsaFile();

  const download = async () => {
    if (isLoading) return;
    setIsLoading(true);
    try {
      const data = await getPdfFile(fileName);
      const url = URL.createObjectURL(data);
      window.open(url, '_blank');
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  return (
    <Button
      isLoading={isLoading}
      loadingText={fileName}
      variant="link"
      fontSize="sm"
      onClick={download}
    >
      {fileName}
    </Button>
  );
}
