/* istanbul ignore file */
import * as yup from 'yup';

export const orderLinesSchema = yup
  .array()
  .min(1, 'An order must have at least one line')
  .of(
    yup.object({
      lineNo: yup.number().min(1).max(999).required().label('Line #'),
      sku: yup.string().label('SKU').required().max(50),
      customerItemNumber: yup
        .string()
        .label('Customer Item #')
        .max(50)
        .nullable(true),
      orderQty: yup.number().required().label('Order Qty').min(1),
    })
  );
