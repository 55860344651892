import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { PaginatedOrdersResponseDto, NetworkOrder } from 'types/networkOrder';
import { SortState } from 'hooks/useGridSorting';
import {
  networkOrderFieldToQuery,
  networkOrderFieldToSortQuery,
} from 'features/network-orders/networkOrderPageParams';
import { mapDtoToNetworkOrder } from './mappers';
import {
  parseOMSAdvancedFilters,
  querifyFiltersForApi,
  querifyOMSSort,
} from 'utils/urlSearchParamsUtils';
import { isEmpty } from 'lodash-es';
import { QueryState } from 'types/queryState';

export type NetworkOrdersQueryResult = QueryState<NetworkOrder>;

type UseNetworkOrdersProps = {
  pagination: {
    skip: number;
    limit: number;
  };
  filters: { [key: string]: string | string[] };
  sort?: SortState<NetworkOrder>;
};

export function useNetworkOrders(
  props: UseNetworkOrdersProps
): NetworkOrdersQueryResult {
  const { pagination, filters, sort } = props;
  const { status, ...restOfFilters } = filters;

  const sortQuery = querifyOMSSort(sort, networkOrderFieldToSortQuery);
  const filtersQuery = parseOMSAdvancedFilters(
    querifyFiltersForApi(restOfFilters as any, networkOrderFieldToQuery)
  );

  const queryResult = useQuery(
    [
      'networkOrders',
      ...Object.values({
        status,
        ...pagination,
        ...filtersQuery,
      }),
      sortQuery,
    ],
    async () => {
      const {
        data: { results, count },
      } = await axios.get<PaginatedOrdersResponseDto>(
        '/oms/network_order/search',
        {
          params: {
            ...sortQuery,
            ...(!isEmpty(status) ? { status } : undefined),
            ...pagination,
            ...filtersQuery,
          },
        }
      );

      return [results, count, count] as const;
    }
  );

  const [data = [], count = 0, filteredCount = 0] = queryResult.data ?? [];

  return {
    ...queryResult,
    data: data.map(mapDtoToNetworkOrder),
    count,
    filteredCount,
  } as NetworkOrdersQueryResult;
}
