import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { PurchaseOrderDetailDto } from 'types/purchaseOrders';
import { mapDtoToPurchaseOrder } from './mappers';

export function usePurchaseOrder(poNumber: string) {
  return useQuery(
    ['purchaseOrder', poNumber],
    async () => {
      const { data } = await axios.get<PurchaseOrderDetailDto>(
        `/purchaseorder/${encodeURIComponent(
          poNumber
        )}?show_unreconciled_qtys=True`
      );

      return data;
    },
    {
      select: mapDtoToPurchaseOrder,
      enabled: !!poNumber,
    }
  );
}
