import React from 'react';
import { Box, BoxProps, useStyleConfig } from '@chakra-ui/react';

interface Props extends BoxProps {
  variant?: string;
  children: React.ReactNode;
  ref?: React.RefObject<HTMLDivElement>;
}

export const MAIN_BOX_TOP_SPACING = 95;

export const LayoutBox = React.forwardRef<HTMLDivElement, Props>(
  ({ variant, children, ...props }, ref) => {
    const styles = useStyleConfig('LayoutBox', { variant });

    return (
      <Box __css={styles} {...props} ref={ref}>
        {children}
      </Box>
    );
  }
);
