import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { PaginatedItemMastersResponseDto, ItemMaster } from 'types/itemMaster';
import { SortState } from 'hooks/useGridSorting';
import { itemMasterFieldToQuery } from 'features/item-master-list/itemMasterPageParams';
import { mapDtoToItemMaster } from './mappers';
import {
  parseAdvancedFilters,
  querifyFiltersForApi,
  querifySort,
} from 'utils/urlSearchParamsUtils';
import { QueryState } from 'types/queryState';

export type ItemMastersQueryResult = QueryState<ItemMaster>;

export function useItemMasters(
  pagination: {
    skip: number;
    limit: number;
  },
  filters: { [key: string]: string | string[] },
  sort?: SortState<ItemMaster>
): ItemMastersQueryResult {
  const sortQuery = querifySort(sort, itemMasterFieldToQuery)
    .replace('-range', '')
    .replace('-select', '');
  const filtersQuery = parseAdvancedFilters(
    querifyFiltersForApi(filters as any, itemMasterFieldToQuery)
  );
  const queryResult = useQuery(
    [
      'itemMasters',
      ...Object.values({
        ...pagination,
        ...filtersQuery,
      }),
      sortQuery,
    ],
    async () => {
      const {
        data: { items, total_count, total_filtered_count },
      } = await axios.get<PaginatedItemMastersResponseDto>('/itemmaster', {
        params: {
          sort: sortQuery,
          ...pagination,
          ...filtersQuery,
        },
      });
      return [items, total_count, total_filtered_count] as const;
    }
  );

  const [data = [], count = 0, filteredCount = 0] = queryResult.data ?? [];

  return {
    ...queryResult,
    data: data.map(mapDtoToItemMaster),
    count,
    filteredCount,
  } as ItemMastersQueryResult;
}
