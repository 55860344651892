import {
  Box,
  Flex,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
} from '@chakra-ui/react';
import { Shimmer } from 'components/Shimmer';
import { StatLink } from 'components/StatLink';
import { useNetworkOrders } from 'queries/networkOrders/useNetworkOrders';
import { useMemo } from 'react';
import { NetworkOrderStatus } from 'types/networkOrder';
import { getLastMonthDateRange } from 'utils/dateUtils';

export const OpenNetworkOrders = () => {
  const defaultFilters = useMemo(() => {
    const lastMonthDateRange = getLastMonthDateRange();
    return {
      created_range: `${lastMonthDateRange.from}_${lastMonthDateRange.to}`,
    };
  }, []);

  const { count: totalReceived, isLoading: isLoadingTotalReceived } =
    useNetworkOrders({
      pagination: { skip: 0, limit: 1 },
      filters: { status: [NetworkOrderStatus.Received], ...defaultFilters },
    });
  const { count: totalProcessing, isLoading: isLoadingTotalProcessing } =
    useNetworkOrders({
      pagination: { skip: 0, limit: 1 },
      filters: { status: [NetworkOrderStatus.Processing], ...defaultFilters },
    });
  const { count: totalError, isLoading: isLoadingTotalError } =
    useNetworkOrders({
      pagination: { skip: 0, limit: 1 },
      filters: { status: [NetworkOrderStatus.Error], ...defaultFilters },
    });

  const isLoading =
    isLoadingTotalReceived || isLoadingTotalProcessing || isLoadingTotalError;
  const totalOpenOrders = totalReceived + totalProcessing + totalError;

  return (
    <Box py={5}>
      <Flex
        textAlign="center"
        gap={5}
        flexDirection={{ base: 'column', md: 'row' }}
      >
        <Stat size="8xl" variant="primary">
          <Shimmer h="90px" my={8} isLoaded={!isLoading}>
            <StatNumber>
              {totalOpenOrders.toLocaleString?.('en-US') ?? 0}
            </StatNumber>
          </Shimmer>
          <StatLabel>Total Open Orders</StatLabel>
        </Stat>
        <SimpleGrid columns={2} flex={1} gap={1}>
          <StatLink
            label="Received"
            number={totalReceived}
            to={`/network-orders?status_select=${NetworkOrderStatus.Received}&created_range=${defaultFilters.created_range}`}
            isLoading={isLoadingTotalReceived}
            tooltip="Order has been imported into Standvast."
          />
          <StatLink
            label="Processing"
            number={totalProcessing}
            to={`/network-orders?status_select=${NetworkOrderStatus.Processing}&created_range=${defaultFilters.created_range}`}
            isLoading={isLoadingTotalProcessing}
            tooltip="Order is being planned or one or more shipments were attempted."
          />
          <StatLink
            label="Error"
            number={totalError}
            to={`/network-orders?status_select=${NetworkOrderStatus.Error}&created_range=${defaultFilters.created_range}`}
            isLoading={isLoadingTotalError}
            tooltip="Order has an error and cannot be fulfilled."
          />
        </SimpleGrid>
      </Flex>
    </Box>
  );
};
