import {
  Alert,
  AlertProps,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
} from '@chakra-ui/react';

type Props = AlertProps & {
  type: AlertProps['status'];
  title: string;
  children: React.ReactNode;
  visible?: boolean;
};

export function AlertMessage({
  type,
  title,
  children,
  visible = true,
  ...props
}: Props) {
  if (visible === false) return null;

  return (
    <Alert status={type} textAlign="left" variant="solid" {...props}>
      <AlertIcon alignSelf="flex-start" />
      <Box>
        {title && <AlertTitle>{title}</AlertTitle>}
        <AlertDescription>{children}</AlertDescription>
      </Box>
    </Alert>
  );
}
