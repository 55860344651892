import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { PurchaseOrderDetailsModal } from 'features/purchase-order-details/PurchaseOrderDetailsModal';
import { ErrorBoundary } from 'components/ErrorBoundary';

export function PurchaseOrderDetailsPage() {
  const { purchaseOrderNumber } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const urlSegments = location.pathname.split('/');
  const firstUrlSegment = urlSegments[1] ?? '';
  const lastUrlSegment = urlSegments.at(-1);

  const handleClose = () => {
    navigate(`/${firstUrlSegment}${location.search}`);
  };

  const handleAbort = () => {
    navigate(`/inbound-inventory/${purchaseOrderNumber}${location.search}`);
  };

  const handleEdit = () => {
    navigate(
      `/inbound-inventory/${purchaseOrderNumber}/edit${location.search}`
    );
  };

  const handleCancel = () => {
    navigate(
      `/inbound-inventory/${purchaseOrderNumber}/cancel${location.search}`
    );
  };

  return (
    <ErrorBoundary size="full">
      <PurchaseOrderDetailsModal
        purchaseOrderNumber={purchaseOrderNumber}
        onClose={handleClose}
        onAbort={handleAbort}
        onEdit={handleEdit}
        onCancel={handleCancel}
        isCancelling={lastUrlSegment === 'cancel'}
        isEditing={lastUrlSegment === 'edit'}
      />
    </ErrorBoundary>
  );
}
