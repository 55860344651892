import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import { CONFIG } from 'utils/config';

if (CONFIG().featureToggles.enableDataDogRUM) {
  datadogRum.init({
    applicationId: '62cc55ad-3879-48ea-8346-14943f677ab5',
    clientToken: 'puba3631d2c3dd8e3825b1027c8b64e2d58',
    site: 'us5.datadoghq.com',
    service: 'customer-portal',
    env: CONFIG().env,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow',
  });
}

if (CONFIG().featureToggles.enableDataDogLogs) {
  datadogLogs.init({
    clientToken: 'puba3631d2c3dd8e3825b1027c8b64e2d58',
    site: 'us5.datadoghq.com',
    forwardErrorsToLogs: true,
    service: 'customer-portal',
    env: CONFIG().env,
    sessionSampleRate: 100,
  });
}
