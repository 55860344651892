import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { UserProfileDto } from 'types/userProfile';
import { mapDtoToUserProfile } from '../../mutations/userProfile/mappers';

export function useUserProfileByUserId(userId: string) {
  return useQuery(
    ['userProfile', userId],
    async () => {
      const { data } = await axios.get<UserProfileDto>(`/profile/${userId}`);

      return data;
    },
    {
      select: mapDtoToUserProfile,
      enabled: !!userId,
    }
  );
}
