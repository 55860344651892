/* istanbul ignore file */
import * as yup from 'yup';
import { bolBillingAddressSchema } from './bolBillingAddress';

export const bolDataSchema = yup
  .object({
    bolNumber: yup.string().label('BOL number').max(50).nullable().optional(),
    shipToCidNumber: yup
      .string()
      .label('Ship-to CID number')
      .max(100)
      .nullable()
      .optional(),
    shipToLocationNumber: yup
      .string()
      .label('Ship-to location number')
      .max(100)
      .nullable()
      .optional(),
    fob: yup.boolean().label('FOB'),
    billingAddress: bolBillingAddressSchema,
  })
  .nullable()
  .optional();
