import { Center, Image } from '@chakra-ui/react';
import { isEmpty } from 'lodash-es';
import UserPhoto from 'assets/user-photo.svg';

type UserProfilePhotoProps = {
  photoSrc?: string;
};

export function UserProfilePhoto({ photoSrc }: UserProfilePhotoProps) {
  return (
    <Center>
      <label htmlFor="icon-button-photo">
        <Image
          borderRadius="full"
          boxSize="120px"
          src={!isEmpty(photoSrc) ? photoSrc : UserPhoto}
          alt="User Photo"
        />
      </label>
    </Center>
  );
}
