import { SpaceProps } from '@chakra-ui/react';
import { useMemo } from 'react';
import { PaginatedTable } from 'components/PaginatedTable';
import { QueryState } from 'types/queryState';
import { NetworkOrderDetails, NetworkOrderLineItem } from 'types/networkOrder';

interface Props extends SpaceProps {
  order?: NetworkOrderDetails;
}

export function NetworkOrderLinesTable({ order }: Props) {
  const columns = useMemo(
    () => [
      {
        Header: 'Line #',
        accessor: 'lineNumber',
        width: 100,
      },
      {
        Header: 'SKU',
        accessor: 'sku',
        width: 300,
      },
      {
        Header: 'Order Qty',
        accessor: 'qtyRequested',
        width: 100,
      },
    ],
    []
  );

  return (
    <PaginatedTable
      columns={columns}
      queryState={
        {
          data: order?.lineItems || [],
          isLoading: !order,
        } as QueryState<NetworkOrderLineItem>
      }
      tableName="network_order_lines_table"
      isResizable
      isSticky
    />
  );
}
