import { FormSelect, FormSelectProps } from 'components/FormSelect';
import {
  SELECT_NODE_OPTION,
  useWarehouses,
} from 'queries/warehouses/useWarehouses';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { SalesOrder, SalesOrderType } from 'types/salesOrders';

type Props = Omit<FormSelectProps, 'options'> & {
  orderType: SalesOrderType;
};

export function WarehouseSelect({ orderType, ...props }: Props) {
  const { register } = useFormContext<SalesOrder>();

  const { data = [], isFetched } = useWarehouses({
    includeSelectNodeOption: orderType === 'Parcel',
  });

  const warehouses = useMemo(
    () =>
      data.map(({ code, address, displayText }) => ({
        value: code,
        label: displayText,
        address,
      })),
    [data]
  );

  return (
    <FormSelect
      label="Fulfillment Center"
      key={`warehouseID-${isFetched}`}
      isRequired
      {...register('warehouseID')}
      options={warehouses}
      tooltip={`If set to ${SELECT_NODE_OPTION.displayText}, the warehouse closest to the Ship To address with the necessary inventory will be selected.`}
      {...props}
    />
  );
}
