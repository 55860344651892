import {
  ReactNode,
  createContext as createReactContext,
  useContext as useReactContext,
} from 'react';

export default function createContext<ContextType>(
  contextHook: (defaultValue?: Partial<ContextType>) => ContextType
) {
  const Context = createReactContext<ContextType | undefined>(undefined);

  function useContext() {
    const context = useReactContext(Context);
    return context!;
  }

  function Provider({
    value: defaultValue,
    children,
  }: {
    value?: Partial<ContextType>;
    children: ReactNode;
  }) {
    const value = contextHook(defaultValue);

    return <Context.Provider value={value}>{children}</Context.Provider>;
  }

  return {
    useContext,
    Provider,
  };
}
