/* istanbul ignore file */
import { FC } from 'react';
import { Box } from '@chakra-ui/react';
import { CopyableEmail } from 'components/CopyableEmail';
import { Modal } from './Modal';
import { Prompt } from './Prompt';

export const AccessDenied: FC = () => {
  return (
    <Modal size={['full', null, '4xl']} showCloseButton={false}>
      <Prompt
        title="You are not a member of this organization or you do not have permission to view this page"
        content={
          <Box>
            <p>
              If you think this is a mistake, contact your account manager or
              send us an email at:
            </p>
            <CopyableEmail emailAddress="customer-service@standvast.com" />
          </Box>
        }
        buttons={[
          {
            label: 'Change Organization',
            handler: () => {
              window.location.href = '/';
            },
          },
        ]}
      />
    </Modal>
  );
};
