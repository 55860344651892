import {
  ChangeEvent,
  useRef,
  useState,
  useEffect,
  FocusEvent,
  useMemo,
} from 'react';
import { Box, IconButton, Input, useOutsideClick } from '@chakra-ui/react';
import SearchIcon from '@mui/icons-material/Search';
import debounce from 'lodash-es/debounce';
import { useSearch } from 'queries/search/useSearch';
import { SearchResultsList } from './components/SearchResultsList';
import { ErrorBoundary } from 'components/ErrorBoundary';

export function SearchBox() {
  const [isInputVisible, setIsInputVisible] = useState(false);
  const [isResultsListVisible, setIsResultsListVisible] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const searchContainerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const {
    data: searchResults = { salesOrders: [], purchaseOrders: [], items: [] },
    error,
    refetch,
    isFetching,
  } = useSearch(searchQuery);

  const hasSearchResults =
    searchResults.items.length +
      searchResults.salesOrders.length +
      searchResults.purchaseOrders.length >
    0;

  useEffect(() => {
    if (isInputVisible) {
      inputRef.current?.focus();
    }
  }, [isInputVisible]);

  useEffect(() => {
    setIsResultsListVisible(true);
  }, [searchResults]);

  useOutsideClick({
    ref: searchContainerRef,
    handler: () => setIsResultsListVisible(false),
  });

  const handleChange = useMemo(
    () =>
      debounce(({ target }: ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(target.value);
      }, 300),
    []
  );

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    if (e.target.value) return;

    setIsInputVisible(false);
  };

  const handleFocus = () => {
    if (!hasSearchResults) return;

    setIsResultsListVisible(true);
  };

  const handleSearchButtonClick = () => {
    if (isInputVisible) {
      inputRef.current?.focus();
    }

    setIsInputVisible(true);
  };

  const handleResultClick = () => {
    setIsResultsListVisible(false);
  };

  return (
    <Box
      position={['static', null, null, 'relative']}
      mr={2}
      ref={searchContainerRef}
      role="search"
    >
      <Input
        position="absolute"
        w={['calc(100% - 125px)', null, null, '300px']}
        left={['20px', null, null, 'auto']}
        right="8px"
        placeholder="Search SKU/SO Number"
        opacity={isInputVisible ? '1' : '0 !important'}
        pointerEvents={isInputVisible ? 'all' : 'none'}
        isDisabled={!isInputVisible}
        isInvalid={!!error}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        backgroundColor="white"
        role="searchbox"
        name="search-sku-so-number"
        ref={inputRef}
        transition="opacity 150ms ease"
        _focusVisible={{
          field: { borderColor: 'primaryBlue.500' },
        }}
      />
      <IconButton
        title="Search"
        aria-label="Search"
        onClick={handleSearchButtonClick}
        icon={<SearchIcon />}
        variant="ghost"
        size="sm"
      />
      {searchQuery && isResultsListVisible && (
        <ErrorBoundary size="compact">
          <SearchResultsList
            query={searchQuery}
            searchResults={isFetching ? undefined : searchResults}
            error={error}
            onRetrySearch={refetch}
            onResultClick={handleResultClick}
          />
        </ErrorBoundary>
      )}
    </Box>
  );
}
