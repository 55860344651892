/* istanbul ignore file */
import * as yup from 'yup';
import { basicSalesOrderInfoSchema } from './formParts/basicSalesOrderInfo';
import { bolDataSchema } from './formParts/bolData';
import { freightInfoSchema } from './formParts/freightInfo';
import { orderLinesSchema } from './formParts/orderLines';
import { shipToAddressSchema } from './formParts/shipToAddress';

export const createFreightSalesOrderSchema = basicSalesOrderInfoSchema
  .concat(freightInfoSchema)
  .concat(
    yup.object({
      bolData: bolDataSchema,
      shipTo: shipToAddressSchema,
      lines: orderLinesSchema,
    })
  );
