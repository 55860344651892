import { useEffect } from 'react';
import { Text, useDisclosure } from '@chakra-ui/react';
import { Modal } from 'components/Modal';
import { useInventoryItems } from 'queries/inventory/useInventoryItems';
import { useItemMaster } from 'queries/itemMasters/useItemMaster';
import { Measurements } from './components/Measurements';
import { Quantities } from './components/Quantities';
import { Shimmer } from 'components/Shimmer';
import { ExceptionState } from 'components/ExceptionState';
import { AxiosError } from 'axios';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { sendViewInventoryItemDetailsEvent } from 'utils/monitorEvents';

type Props = {
  sku: string;
  onClose: () => void;
};

export function InventoryItemDetailsModal(props: Props) {
  const { onOpen: handleOpen, ...disclosure } = useDisclosure();
  const {
    data: inventoryItems,
    isFetching: isFetchingInventoryItems,
    error: inventoryError,
  } = useInventoryItems(props.sku);
  const {
    data: itemMaster,
    isFetching: isFetchingItemMaster,
    error: itemMasterError,
  } = useItemMaster(props.sku);
  const handleClose = () => {
    disclosure.onClose();
    props.onClose();
  };

  useEffect(() => {
    handleOpen();
  }, [handleOpen]);

  useEffect(
    () => sendViewInventoryItemDetailsEvent(props.sku ?? ''),
    [props.sku]
  );

  const skuNotFound =
    (inventoryError as AxiosError)?.response?.status === 404 ||
    (itemMasterError as AxiosError)?.response?.status === 404;
  const isLoading =
    !itemMaster ||
    !inventoryItems ||
    isFetchingItemMaster ||
    isFetchingInventoryItems;

  return (
    <Modal
      title={`SKU ${props.sku}`}
      isOpen={disclosure.isOpen}
      onClose={handleClose}
      buttons={[
        {
          label: 'Done',
          handler: handleClose,
        },
      ]}
      modalFooterProps={{
        textAlign: 'center',
      }}
      size={['full', null, '4xl']}
    >
      {skuNotFound ? (
        <ExceptionState customTitle="Item Not Found" stateType="empty" />
      ) : (
        <>
          <Shimmer w="300px" h="40px" isLoaded={!isLoading}>
            <Text as="h2" fontSize="xl" mt={-1}>
              {itemMaster?.description}
            </Text>
          </Shimmer>
          <ErrorBoundary size="compact">
            <Measurements itemMaster={isLoading ? undefined : itemMaster} />
          </ErrorBoundary>
          <ErrorBoundary size="compact">
            <Quantities
              inventoryItems={isLoading ? undefined : inventoryItems}
            />
          </ErrorBoundary>
        </>
      )}
    </Modal>
  );
}
