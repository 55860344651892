/* istanbul ignore file */
import * as yup from 'yup';
import { buildAddressCommonSchema } from './addressCommon';

export const bolBillingAddressSchema = buildAddressCommonSchema(true).concat(
  yup
    .object({
      phoneNumber: yup
        .string()
        .label('Telephone')
        .max(20)
        .nullable()
        .optional(),
    })
    .nullable()
    .optional()
);
