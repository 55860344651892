import { ColumnDataType } from 'hooks/useGridFiltering';
import { SortState } from 'hooks/useGridSorting';

export type QueryParamsObject = { [queryKey: string]: string } | {};

export const DEFAULT_SORT_STATE: SortState<any> = {
  field: '',
  direction: 'asc',
};

const IGNORED_FILTERS = ['fieldToFocus'];

export function getQueryParamsObject(
  urlSearchparams: URLSearchParams
): QueryParamsObject {
  return Array.from(urlSearchparams.entries()).reduce((obj, [key, value]) => {
    return { ...obj, [key]: value };
  }, {});
}

export function parseSort(
  queryCriterion: string | undefined,
  queryToField: (queryKey: string, querySeparator?: string) => string
): SortState<any> {
  if (!queryCriterion) {
    return DEFAULT_SORT_STATE;
  }

  const [firstChar, ...restOfChars] = queryCriterion.split('');
  const direction: 'desc' | 'asc' = firstChar === '-' ? 'desc' : 'asc';
  const queryKey =
    direction === 'asc'
      ? [firstChar, ...restOfChars].join('')
      : restOfChars.join('');
  const field = queryToField(queryKey);

  const parsedSort = { field, direction };
  return parsedSort;
}

export function querifySort(
  sort: SortState<any> | undefined,
  fieldToQuery: (field: string, querySeparator?: string) => string
): string {
  if (!sort || !sort.field) {
    return '';
  }

  const directionChar = sort.direction === 'desc' ? '-' : '';
  const queryKey = fieldToQuery(sort.field.toString());

  const stringifiedQuery = directionChar + queryKey;
  return stringifiedQuery;
}

export function querifyOMSSort(
  sort: SortState<any> | undefined,
  fieldToQuery: (field: string, querySeparator?: string) => string
): { [key: string]: SortState<any>['direction'] } {
  if (!sort || !sort.field) {
    return {};
  }

  const queryKey =
    'sort_by_' +
    fieldToQuery(sort.field.toString())
      .replace('-range', '')
      .replace('-select', '')
      .replace('minmax-', '')
      .replace(/-/g, '_');
  return {
    [queryKey]: sort.direction,
  };
}

export function querifyFiltersForApi(
  filters: Partial<{
    [key: string]: string | string[];
  }>,
  fieldToQuery: (field: string, querySeparator?: string) => string
) {
  return querifyFilters(filters, fieldToQuery, true);
}

export function querifyFilters(
  filters: Partial<{
    [key: string]: string | string[];
  }>,
  fieldToQuery: (field: string, querySeparator?: string) => string,
  removeIgnoredFilters = false
): QueryParamsObject {
  return Object.entries(filters).reduce(function (acc, [key, value]) {
    if (removeIgnoredFilters && IGNORED_FILTERS.includes(key)) return acc;
    return {
      ...acc,
      [fieldToQuery(key, '_')]: value,
    };
  }, {});
}

export function parseFilters(
  queryFilters: QueryParamsObject,
  queryToField: (field: string, querySeparator?: string) => string
): Partial<{
  [key: string]: string;
}> {
  return Object.entries(queryFilters).reduce((acc, [queryKey, value]) => {
    const field = queryToField(queryKey, '_');
    if (!field || !value) {
      return acc;
    }

    return { ...acc, [field]: value };
  }, {});
}

export function parseAdvancedFilters(filters: QueryParamsObject) {
  return Object.entries(filters).reduce((acc, [queryKey, value]) => {
    if (queryKey.includes('_range') && value) {
      const minMax = value.split('_');
      return {
        ...acc,
        [queryKey.replace('_range', '_from')]: minMax[0],
        ...(minMax[1] && { [queryKey.replace('_range', '_to')]: minMax[1] }),
      };
    } else if (queryKey.includes('_select') && value)
      return { ...acc, [queryKey.replace('_select', '')]: value.split(',') };
    else if (value) return { ...acc, [queryKey]: value };
    else return acc;
  }, {});
}

export function parseOMSAdvancedFilters(filters: QueryParamsObject) {
  return Object.entries(filters).reduce((acc, [queryKey, value]) => {
    if (queryKey.includes('minmax_') && value) {
      const minMax = value.split('_');
      return {
        ...acc,
        [queryKey.replace('minmax_', 'min_')]: minMax[0],
        ...(minMax[1] && { [queryKey.replace('minmax_', 'max_')]: minMax[1] }),
      };
    } else if (queryKey.includes('_range') && value) {
      const dateRange = value.split('_');
      return {
        ...acc,
        [queryKey.replace('_range', '_after')]: dateRange[0],
        ...(dateRange[1] && {
          [queryKey.replace('_range', '_before')]: dateRange[1],
        }),
      };
    } else if (queryKey.includes('_select') && value)
      return { ...acc, [queryKey.replace('_select', '')]: value.split(',') };
    else if (value) return { ...acc, [queryKey]: value };
    else return acc;
  }, {});
}

export function parseValueForClientSideFilter(
  dataType: ColumnDataType,
  value: string | undefined
) {
  if (!value) return undefined;

  switch (dataType) {
    case 'select':
      return value.split(',');
    case 'date-range':
      return value.split('_');
    case 'number':
      const [minString, maxString] = value.split('_');
      const min =
        isNaN(+minString) || +minString === 0 ? undefined : +minString;
      const max =
        isNaN(+maxString) || +maxString === 0 ? undefined : +maxString;
      return min || max ? [min, max] : undefined;
    default:
      return value;
  }
}
