import { ComponentStyleConfig } from '@chakra-ui/react';

export const tableTheme: ComponentStyleConfig = {
  variants: {
    'main-grid': {
      table: {
        marginY: 4,
        width: '100%',
      },
      th: {
        color: 'primaryBlue.500',
        backgroundColor: 'white',
        textTransform: 'initial',
        fontSize: 'sm',
        padding: 1,
      },
      tr: {
        borderColor: '#E6F2F2',
        _odd: {
          backgroundColor: '#E6F2F2',
        },
      },
      td: {
        padding: 1,
        fontSize: 'xs',
      },
    },
    'order-lines': {
      table: {
        fontWeight: 300,
        w: '100%',
        isolation: 'isolate',
      },
      th: {
        padding: 1,
        fontFamily: 'body',
        color: 'primaryBlue.500',
        backgroundColor: 'white',
        textTransform: 'none',
        fontSize: 'sm',
        position: 'relative',
        whiteSpace: 'nowrap',
        _after: {
          content: '""',
          height: '12px',
          width: '1px',
          marginTop: '2px',
          display: 'inline-block',
          background: 'gray.300',
          position: 'absolute',
          right: '8px',
        },
        _last: {
          _after: {
            width: 0,
          },
        },
      },
      td: {
        fontSize: 'xs',
        padding: 1,
        verticalAlign: 'top',
      },
    },
  },
};
