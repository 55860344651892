import { useDisclosure } from '@chakra-ui/react';
import createContext from 'utils/createContext';

const useApp = () => {
  const disclosureIdeaSubmission = useDisclosure();

  return {
    disclosureIdeaSubmission,
  };
};

export const { Provider: AppContextProvider, useContext: useAppContext } =
  createContext(useApp);
