import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { PurchaseOrder } from 'types/purchaseOrders';
import { mapPurchaseOrderToDto } from './mappers';

export function useCreatePurchaseOrder() {
  const queryClient = useQueryClient();
  return useMutation<any, any, PurchaseOrder>({
    mutationFn: (order: PurchaseOrder) => {
      const purchaseOrderDto = mapPurchaseOrderToDto(order);

      return axios.post('/purchaseorder', {
        request_transaction_id: '',
        orders: [purchaseOrderDto],
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['purchaseOrders'] });
    },
  });
}
