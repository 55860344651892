import { SortState } from 'hooks/useGridSorting';
import { PurchaseOrder } from 'types/purchaseOrders';
import { QueryParamsObject } from 'utils/urlSearchParamsUtils';

export interface PurchaseOrderPageParams {
  initialSort?: SortState<PurchaseOrder>;
  onSortByChange?: (sorting: SortState<PurchaseOrder>) => void;
  initialPage?: number;
  initialPageSize?: number;
  onPaginationChange?: (pagination: { page: number; pageSize: number }) => void;
  initialFilters?: QueryParamsObject;
  onFilterChange?: (filters: QueryParamsObject) => void;
}

export const dictFieldToQuery = {
  poNumber: 'po-number',
  status: 'latest-status-select',
  containerNumber: 'container-number',
  createdDate: 'created-date-range',
  requestedDate: 'requested-delivery-date-range',
  warehouseID: 'target-dc-select',
  totalOrderQty: 'total-order-qty-range',
  totalReceivedQty: 'total-received-qty-range',
  totalLines: 'total-po-lines-range',
} as { [key in keyof PurchaseOrder]: string };

export function queryToPOField(
  queryKey: string,
  queryWordSeparator: string = '-'
): keyof PurchaseOrder | '' {
  const dictQueryToField = {
    'po-number': 'poNumber',
    'latest-status-select': 'status',
    'container-number': 'containerNumber',
    'created-date-range': 'createdDate',
    'requested-delivery-date-range': 'requestedDate',
    'target-dc-select': 'warehouseID',
    'total-order-qty-range': 'totalOrderQty',
    'total-received-qty-range': 'totalReceivedQty',
    'total-po-lines-range': 'totalLines',
  } as { [key: string]: keyof PurchaseOrder };
  const field =
    dictQueryToField[queryKey.replaceAll(queryWordSeparator, '-')] || '';
  return field;
}

export function poFieldToQuery(
  poField: string,
  queryWordSeparator: string = '-'
): string {
  const queryKey = dictFieldToQuery[poField as keyof PurchaseOrder] || poField;
  return queryKey.replaceAll('-', queryWordSeparator);
}
