import { CARRIER_TRACKING } from 'constants/carrierTrackingURLs';
import { SalesOrder } from 'types/salesOrders';

export const RESHIP_SUFFIX = /((-)([RS]{2})(.*))/;

export function canBePromptlyCancelled(order: SalesOrder) {
  return ['Received', 'Received-Updated'].includes(order.status);
}

export function needsApprovalToBeCancelled(order: SalesOrder) {
  return ['Picking-Started', 'Picked', 'Picked-Short'].includes(order.status);
}

export function isCancellable(order: SalesOrder) {
  return canBePromptlyCancelled(order) || needsApprovalToBeCancelled(order);
}

export function getCarrierTrackingURL(
  carrier: string = '',
  trackingNumber: string
) {
  let urlTemplate: string = '';

  CARRIER_TRACKING.forEach((carrier_tracking) => {
    if (
      carrier_tracking.startCode.filter((code) => carrier.startsWith(code))
        .length > 0
    ) {
      urlTemplate = carrier_tracking.url;
      return;
    }
  });

  return urlTemplate.length > 0
    ? urlTemplate.replace(/@tracking_number/, trackingNumber)
    : null;
}

export function isReshipOrder(orderNumber: string) {
  return orderNumber?.match(RESHIP_SUFFIX);
}

export function removeReshipSuffix(orderNumber: string) {
  return orderNumber.replace(RESHIP_SUFFIX, '');
}

export function generateReshipSONumber(soNumber: string): string {
  const match = isReshipOrder(soNumber);

  if (match) {
    const reshipNumber = match[1];

    if (isNaN(parseInt(reshipNumber))) {
      const lastChar = reshipNumber.at(-1) || '';
      if (!isNaN(parseInt(lastChar))) {
        return soNumber.replace(
          RESHIP_SUFFIX,
          `${reshipNumber.slice(0, -1)}${parseInt(lastChar) + 1}`
        );
      }

      return soNumber.replace(RESHIP_SUFFIX, `${reshipNumber}-RS1`);
    }

    return soNumber.replace(RESHIP_SUFFIX, `-RS${+reshipNumber + 1}`);
  }

  return `${soNumber}${soNumber.endsWith('-') ? '' : '-'}RS1`;
}
