import {
  Button,
  SimpleGrid,
  GridItem,
  GridProps,
  GridItemProps,
  ButtonProps,
  Tooltip,
} from '@chakra-ui/react';

export interface Props {
  wrapperProps?: GridProps;
  commonButtonWrapperProps?: GridItemProps;
  commonButtonProps?: ButtonProps;
  buttons: {
    label: string;
    handler?: () => void;
    disabledStateHandler?: () => void;
    visible?: boolean;
    variant?: string;
    isLoading?: boolean;
    isDisabled?: boolean;
    buttonWrapperProps?: GridItemProps;
    buttonProps?: ButtonProps;
    icon?: React.ReactNode;
    tooltip?: string;
  }[];
}

export function ButtonGroup({ buttons, ...props }: Props) {
  if (buttons.length === 0) return null;

  return (
    <SimpleGrid
      columns={2}
      display={['grid', null, 'block']}
      width="100%"
      gap={3}
      role="group"
      {...props.wrapperProps}
    >
      {buttons
        .filter((buttonConfig) => buttonConfig.visible !== false)
        .map((buttonConfig, i, visibleButtons) => {
          const isFullWidth =
            i === visibleButtons.length - 1 && visibleButtons.length % 2 === 1;
          return (
            <Tooltip label={buttonConfig.tooltip} key={buttonConfig.label}>
              <GridItem
                colSpan={isFullWidth ? 2 : 1}
                display={['block', null, 'inline-block']}
                onClick={() =>
                  buttonConfig.isDisabled &&
                  buttonConfig.disabledStateHandler?.()
                }
                mr={[0, null, i === visibleButtons.length - 1 ? 0 : 8]}
                {...props.commonButtonWrapperProps}
                {...buttonConfig.buttonWrapperProps}
              >
                <Button
                  gap={1}
                  variant={buttonConfig.variant ?? 'outline'}
                  onClick={buttonConfig.handler}
                  type={buttonConfig.handler ? 'button' : 'submit'}
                  fontSize={['sm', null, 'lg']}
                  width={['100%', null, '230px']}
                  isLoading={buttonConfig.isLoading}
                  isDisabled={buttonConfig.isDisabled}
                  pointerEvents={buttonConfig.isDisabled ? 'none' : 'all'}
                  {...props.commonButtonProps}
                  {...buttonConfig.buttonProps}
                >
                  {buttonConfig.icon}
                  {buttonConfig.label}
                </Button>
              </GridItem>
            </Tooltip>
          );
        })}
    </SimpleGrid>
  );
}
