import {
  Button,
  ButtonProps,
  Checkbox,
  Flex,
  PopoverTrigger,
  Popover,
  PopoverBody,
  PopoverContent,
  Stack,
  Collapse,
  useDisclosure,
  IconButton,
  IconButtonProps,
} from '@chakra-ui/react';
import { GridHeadingContentProps } from './GridHeadingContent';
import MoreIcon from '@mui/icons-material/MoreVert';
import ArrowDownwardIcon from '@mui/icons-material/South';
import ArrowUpwardIcon from '@mui/icons-material/North';
import ColumnsIcon from '@mui/icons-material/ViewWeek';
import { useTableContext } from 'contexts/tableContext';

export interface ColumnSortState {
  isSorting: boolean;
  isDescending: boolean;
}

type SettingsIconButtonProps = Omit<IconButtonProps, 'aria-label'> &
  Pick<GridHeadingContentProps, 'isLoading'> &
  ColumnSortState & {
    isSortable: boolean;
    toggleSort: (direction: 'asc' | 'desc') => void;
  };

export function SettingsIconButton({
  isDisabled,
  isLoading,
  isSortable,
  isSorting,
  isDescending,
  toggleSort,
  ...props
}: SettingsIconButtonProps) {
  const {
    columnSettings,
    hiddenColumns,
    toggleHiddenColumn,
    resetHiddenColumns,
    resetDefaultHiddenColumns,
    applyHiddenColumns,
    isLastHiddenColumn,
  } = useTableContext();
  const { isOpen, onToggle, onClose } = useDisclosure();
  const { isOpen: openToggleColumns, onToggle: toggleColumns } =
    useDisclosure();

  const apply = () => {
    applyHiddenColumns();
    onClose();
  };

  return (
    <Flex flexShrink="0" px={1}>
      <Popover
        isLazy
        returnFocusOnClose={false}
        isOpen={isOpen}
        onClose={() => {
          onClose();
          resetHiddenColumns();
        }}
      >
        <PopoverTrigger>
          <IconButton
            size="xs"
            colorScheme="primaryBlue"
            variant="ghost"
            icon={<MoreIcon fontSize="small" />}
            aria-label="Settings"
            isDisabled={isDisabled}
            {...props}
            onClick={onToggle}
          />
        </PopoverTrigger>
        <PopoverContent
          background="white"
          width="fit-content"
          borderRadius={2}
          outline="1px solid"
          outlineColor="primaryBlue.500"
        >
          <PopoverBody
            p={0}
            fontWeight="normal"
            color="black"
            minW="235px"
            aria-label="Settings Panel"
          >
            <Stack spacing={0}>
              {isSortable ? (
                <>
                  <ActionButton
                    isDisabled={isLoading || isDisabled}
                    leftIcon={<ArrowUpwardIcon sx={{ fontSize: '1rem' }} />}
                    active={isSorting && !isDescending}
                    onClick={() => toggleSort('asc')}
                  >
                    Sort Ascending
                  </ActionButton>
                  <ActionButton
                    isDisabled={isLoading || isDisabled}
                    leftIcon={<ArrowDownwardIcon sx={{ fontSize: '1rem' }} />}
                    active={isSorting && isDescending}
                    onClick={() => toggleSort('desc')}
                  >
                    Sort Descending
                  </ActionButton>
                </>
              ) : null}
              <ActionButton
                leftIcon={<ColumnsIcon sx={{ fontSize: '1rem' }} />}
                onClick={toggleColumns}
              >
                Columns
              </ActionButton>
              <Collapse in={openToggleColumns} unmountOnExit>
                <Stack spacing={2} p={2} pt={1}>
                  <Stack
                    spacing={2}
                    pl={1.5}
                    maxH="200px"
                    overflow="auto"
                    aria-label="All Columns"
                  >
                    {columnSettings
                      ?.filter((column) => Boolean(column.Header))
                      ?.map((column) => {
                        const { style } = column.getToggleHiddenProps();
                        const checked = !hiddenColumns.includes(column.id);

                        return (
                          <Checkbox
                            key={column.id}
                            size="sm"
                            style={style}
                            sx={{
                              '.multiselect-item__label:hover .multiselect-item__only-button':
                                { opacity: 1 },
                            }}
                            isChecked={checked}
                            isDisabled={isLastHiddenColumn && checked}
                            onChange={() => {
                              toggleHiddenColumn(column.id);
                            }}
                          >
                            <Flex className="multiselect-item__label">
                              <span>{column.render('Header')}</span>
                            </Flex>
                          </Checkbox>
                        );
                      })}
                  </Stack>
                  <Flex>
                    <Button
                      flex="0 0 calc(50% - 0.25rem)"
                      mr="0.25rem"
                      colorScheme="primaryBlue"
                      minW="100px"
                      variant="solid"
                      size="sm"
                      onClick={apply}
                    >
                      Apply
                    </Button>
                    <Button
                      ml="0.25rem"
                      flex="0 0 calc(50% - 0.25rem)"
                      minW="100px"
                      variant="outline"
                      size="sm"
                      onClick={resetDefaultHiddenColumns}
                    >
                      Reset
                    </Button>
                  </Flex>
                </Stack>
              </Collapse>
            </Stack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Flex>
  );
}

function ActionButton({
  active,
  ...props
}: ButtonProps & { active?: boolean }) {
  return (
    <Button
      colorScheme="primaryBlue"
      borderRadius="0"
      justifyContent="flex-start"
      px={3}
      py={1}
      height="28px"
      size="sm"
      variant={active ? 'solid' : 'ghost'}
      {...props}
    >
      {props.children}
    </Button>
  );
}
