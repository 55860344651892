import 'react-datepicker/dist/react-datepicker.css';
import { forwardRef, MouseEventHandler, Ref, useId, useState } from 'react';
import { Input, InputProps, ResponsiveValue } from '@chakra-ui/react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { parseDateInUTC, toISOInUTC } from 'utils/dateUtils';

export interface DateRangePickerProps
  extends Omit<ReactDatePickerProps, 'selected' | 'onChange' | 'defaultValue'> {
  defaultValue?: string;
  onChange?: (value: string) => void;
  onMouseEnter?: MouseEventHandler;
  variant?: ResponsiveValue<string>;
  size?: 'xs' | 'sm' | 'md';
  'aria-label'?: string;
}

export function DateRangePicker({
  variant = 'outline',
  size = 'md',
  defaultValue,
  onChange,
  onMouseEnter,
  isClearable,
  'aria-label': ariaLabel,
  dateFormat = 'MM/dd/yy',
  ...props
}: DateRangePickerProps) {
  const id = useId();
  const defaultRange = defaultValue?.split('_') ?? [null, null];
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    defaultRange[0] ? parseDateInUTC(defaultRange[0]) : null,
    defaultRange[1] ? parseDateInUTC(defaultRange[1]) : null,
  ]);
  const [startDate, endDate] = dateRange;

  const handleChange = (date: [Date | null, Date | null]) => {
    setDateRange(date);
    if (onChange) {
      const fromISO = date[0]
        ? toISOInUTC(date[0]).split('T')[0] + 'T00:00:00.000Z'
        : '';
      const toISO =
        date[1] && toISOInUTC(date[1]).split('T')[0] + 'T23:59:59.999Z';

      if ((fromISO && toISO) || (!fromISO && !toISO)) {
        onChange(fromISO + (toISO ? `_${toISO}` : ''));
      }
    }
  };

  return (
    <DatePicker
      {...props}
      id={id}
      selectsRange
      selected={startDate}
      startDate={startDate}
      endDate={endDate}
      dateFormat={dateFormat}
      onChange={handleChange}
      customInput={
        <AdapterInput
          aria-label={ariaLabel}
          type={isClearable ? 'search' : 'text'}
          size={size}
          variant={variant}
          onMouseEnter={onMouseEnter}
        />
      }
    />
  );
}

const AdapterInput = forwardRef(
  (props: InputProps, ref: Ref<HTMLInputElement>) => (
    <Input ref={ref} {...props}></Input>
  )
);
