import axios from 'axios';
import { useState } from 'react';

export const useInventoryBySku = () => {
  const [inventoryItems, setInventoryItems] = useState<any>({});
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const fetchInventoryItems = (
    warehouseId: string,
    sku: string,
    force?: boolean
  ) => {
    const isNotAvailableSku =
      !inventoryItems[sku] && inventoryItems?.warehouseId === warehouseId;
    const isNewWarehouse = inventoryItems?.warehouseId !== warehouseId;
    if (
      sku &&
      sku.trim().length > 0 &&
      warehouseId.trim().length > 0 &&
      (isNotAvailableSku || isNewWarehouse || force)
    ) {
      setIsFetching(true);
      axios
        .get('/inventory', {
          params: {
            dc_name: warehouseId,
            sku,
          },
        })
        .then(({ data }) => {
          setInventoryItems((oldItems: any) => ({
            ...oldItems,
            ...data.items.reduce((items: any, item: any) => {
              if (!items[item.sku]) {
                items[item.sku] = {};
              }

              return {
                ...items,
                [item.sku]: item.qty_available_to_promise,
                warehouseId,
              };
            }, {}),
          }));
        })
        .finally(() => setIsFetching(false));
    }
  };

  const getQuantityAvailable = (sku: string): number => {
    return sku in inventoryItems ? inventoryItems[sku] : -1;
  };

  const resetInventoryItems = () => {
    if (Object.keys(inventoryItems).length > 0) {
      setInventoryItems({});
    }
  };

  return {
    fetchInventoryItems,
    getQuantityAvailable,
    inventoryItems,
    isFetching,
    resetInventoryItems,
  };
};
