import { SortState } from 'hooks/useGridSorting';
import { ReturnsItem } from 'types/returns';
import { Report } from 'types/report';
import { QueryParamsObject } from 'utils/urlSearchParamsUtils';

export interface ReturnsPageParams {
  initialFilters?: QueryParamsObject;
  initialPage?: number;
  initialPageSize?: number;
  initialSort?: SortState<ReturnsItem>;
  onFilterChange?: (filters: QueryParamsObject) => void;
  onPaginationChange?: (pagination: { page: number; pageSize: number }) => void;
  onSortByChange?: (sorting: SortState<ReturnsItem>) => void;
  sortBy?: string | null;
}

export const dictFieldToQuery: Record<keyof Report<ReturnsItem>, string> = {
  dcName: 'dc-name',
  returnQty: 'return-qty-range',
  weekStart: 'week-start-range',
  pageSkip: 'page-skip',
  pageSize: 'page-size',
  sortBy: 'sort-by',
};

const dictQueryToField = {
  'dc-name': 'dcName',
  'return-qty-range': 'returnQty',
  'week-start-range': 'weekStart',
} as { [key: string]: keyof ReturnsItem };

export function queryToReturnField(
  queryKey: string,
  queryWordSeparator: string = '-'
): keyof ReturnsItem | '' {
  const field =
    dictQueryToField[queryKey.replaceAll(queryWordSeparator, '-')] || '';
  return field;
}

export function returnFieldToQuery(
  field: string,
  queryWordSeparator: string = '-'
): string {
  const queryKey = dictFieldToQuery[field as keyof ReturnsItem] || field;
  return queryKey.replaceAll('-', queryWordSeparator);
}
