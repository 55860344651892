import { ComponentStyleConfig } from '@chakra-ui/react';

export const listTheme: ComponentStyleConfig = {
  variants: {
    searchResult: {
      container: {
        w: 'calc(100% - 40px)',
        ml: '20px !important',
        p: 0,
      },
      item: {
        borderBottomWidth: '1px',
        borderColor: 'secondaryGray.800',
        p: 2,
        color: 'black',
        cursor: 'pointer',
        _last: {
          borderBottomWidth: 0,
        },
        _hover: {
          fontWeight: 'bold',
        },
      },
    },
  },
};
