import { useState } from 'react';
import {
  HeaderGroup,
  HeaderPropGetter,
  UseSortByColumnProps,
} from 'react-table';

export interface SortState<T extends object> {
  field: keyof T | '';
  direction: 'asc' | 'desc' | '';
}

export const DEFAULT_SORT_STATE: SortState<any> = {
  field: '',
  direction: 'asc',
};

export function useGridSorting<T extends object>({
  getQueryState,
  initialSort,
  onSortChange,
}: GridSortingHookOptions<T>): GridSortingHookResult<T> {
  const [sort, _setSort] = useState<SortState<T>>(
    initialSort || DEFAULT_SORT_STATE
  );
  const setSort = (sorting: SortState<T>) => {
    _setSort(sorting);

    if (onSortChange) {
      onSortChange(sorting);
    }
  };

  const isSortingColumn: (column: HeaderGroup<T>) => boolean = (column) =>
    column.id === sort.field;

  const getHeadingContentSortProps = (
    column: HeaderGroup<T> & Partial<UseSortByColumnProps<T>>
  ): HeadingContentSortProps => {
    const isPressedSorting = isSortingColumn(column);

    const nextState: () => SortState<T> = () => {
      if (isPressedSorting && sort.direction === 'asc') {
        return {
          field: column.id as keyof T,
          direction: 'desc',
        };
      }

      if (isPressedSorting && sort.direction === 'desc') {
        return {
          field: '',
          direction: 'asc',
        };
      }

      return {
        field: column.id as keyof T,
        direction: 'asc',
      };
    };

    const handleClick = () => {
      setSort(nextState());
    };

    const columnSortState = isPressedSorting
      ? { isSorting: true, isDescending: sort.direction === 'desc' }
      : { isSorting: false, isDescending: false };

    return {
      columnId: column.id,
      setSort,
      toggleSort: handleClick,
      isPressedSorting,
      isLoading: !!getQueryState().isLoading,
      clientSideSortByProps: {
        toggleSortBy: column.toggleSortBy,
        clearSortBy: column.clearSortBy,
        getSortByToggleProps: column.getSortByToggleProps,
        isSorted: column.isSorted,
        isSortedDesc: column.isSortedDesc,
      },
      ...columnSortState,
    };
  };

  const getHeadingSortProps: (column: HeaderGroup<T>) => HeaderPropGetter<T> & {
    'aria-sort': 'none' | 'ascending' | 'descending';
  } = (column) => {
    const isPressed = isSortingColumn(column);

    return {
      'aria-sort':
        (isPressed &&
          (sort.direction === 'asc' ? 'ascending' : 'descending')) ||
        'none',
    };
  };

  return {
    sort,
    getHeadingContentSortProps,
    getHeadingSortProps,
  };
}

export interface HeadingContentSortProps {
  columnId: string;
  toggleSort: React.MouseEventHandler<HTMLElement>;
  setSort: (sorting: SortState<any>) => void;
  isPressedSorting: boolean;
  isLoading: boolean;
  isSorting: boolean;
  isDescending: boolean;
  clientSideSortByProps?: Partial<UseSortByColumnProps<any>>;
}

interface GridSortingHookOptions<T extends object> {
  getQueryState: () => { isLoading: boolean };
  initialSort?: SortState<T>;
  onSortChange?: (sorting: SortState<T>) => void;
}

export interface GridSortingHookResult<T extends object> {
  sort: SortState<T>;
  getHeadingContentSortProps: (
    column: HeaderGroup<T>
  ) => HeadingContentSortProps;
  getHeadingSortProps: (column: HeaderGroup<T>) => HeaderPropGetter<T> & {
    'aria-sort': 'none' | 'ascending' | 'descending';
  };
}
