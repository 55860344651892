import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

/* Components */
import { Link } from 'components/Link';
import { PaginatedTable } from 'components/PaginatedTable';

/* Types */
import { InventoryItem } from 'types/inventory';
import { Column, Row } from 'react-table';

/* Utils */
import { useInventory } from 'queries/inventory/useInventory';
import { useGridPagination } from 'hooks/useGridPagination';
import { InventoryPageParams, dictFieldToQuery } from './inventoryPageParams';
import { useGridSorting } from 'hooks/useGridSorting';
import { ColumnMetaData, useGridFiltering } from 'hooks/useGridFiltering';
import { useWarehouses } from 'queries/warehouses/useWarehouses';
import { useDownloadReport } from 'utils/reportUtils';
import { REPORT_TYPES } from 'constants/reports';
import { useLayoutPaginatedTable } from 'hooks/useLayoutPaginatedTable';
import { BigLoading } from 'components/BigLoading';
import { MAX_WIDTH_MAIN_LAYOUT as MIN_WIDTH_STRETCH } from 'hoc/withLayout';
import { ButtonGroup } from 'components/ButtonGroup';
import { Box, Flex, Text } from '@chakra-ui/react';

export interface InventoryGridProps extends InventoryPageParams {}

export function InventoryGrid(props: InventoryGridProps) {
  const { buildParameters, isDownloadingReport, onDownloadReport, resources } =
    useDownloadReport();
  const location = useLocation();
  const gridPagination = useGridPagination({
    initialPage: props.initialPage,
    initialPageSize: props.initialPageSize,
    onPaginationChange: props.onPaginationChange,
  });
  const { data: warehouses } = useWarehouses({
    refetchOnMount: false,
  });

  const gridSorting = useGridSorting<InventoryItem>({
    getQueryState: () => queryState,
    initialSort: props.initialSort,
    onSortChange: props.onSortByChange,
  });

  const columnsMetaData: { [key in keyof InventoryItem]: ColumnMetaData } = {
    sku: { dataType: 'text' },
    warehouseId: {
      dataType: 'select',
      selectOptions:
        warehouses?.map((warehouse) => ({
          label: warehouse.displayText,
          value: warehouse.code,
        })) ?? [],
    },
    availableQty: { dataType: 'number' },
    promisedQty: { dataType: 'number' },
    damagedQty: { dataType: 'number' },
    totalQty: { dataType: 'number' },
    onHoldQty: { dataType: 'number' },
    description: { dataType: 'text' },
    uom: { dataType: 'text' },
    conversionFactor: { dataType: 'number' },
  };

  const gridFiltering = useGridFiltering<InventoryItem>({
    columnsMetaData,
    initialFilters: props.initialFilters,
    onFilterChange: props.onFilterChange,
  });

  const filters = { ...gridFiltering.filters };

  const queryState = useInventory(
    { skip: gridPagination.skip, limit: gridPagination.limit },
    filters,
    gridSorting.sort
  );

  const { widthWindow, getColumnWidth } =
    useLayoutPaginatedTable(MIN_WIDTH_STRETCH);

  const inventoryColumns = useMemo<Column<InventoryItem>[]>(
    () => [
      {
        accessor: 'sku',
        Header: 'SKU',
        width: getColumnWidth(13, 170),
        id: 'sku',
        Cell: ({ value: sku }: { value: string }) => (
          <Link
            to={`/inventory/${encodeURIComponent(sku)}${location.search}`}
            fontWeight="bold"
            _hover={{ textDecoration: 'underline' }}
          >
            {sku}
          </Link>
        ),
      },
      {
        Header: 'Description',
        accessor: 'description',
        width: getColumnWidth(20, 200),
      },
      {
        Header: 'Fulfillment Center',
        accessor: 'warehouseId',
        Cell: ({ value }) => {
          const warehouseInfo = warehouses?.find((item) => item.code === value);
          return <>{warehouseInfo?.displayText || value}</>;
        },
        width: getColumnWidth(14, 180),
      },
      {
        Header: 'Available',
        accessor: 'availableQty',
        id: 'availableQty',
        width: getColumnWidth(10.6, 165),
        isNumeric: true,
        helpText: 'Qty Available To Promise',
      },
      {
        Header: 'Promised',
        accessor: 'promisedQty',
        id: 'promisedQty',
        isNumeric: true,
        width: getColumnWidth(10.6, 165),
        helpText: 'Qty Allocated to Pending Orders',
      },
      {
        Header: 'Damaged',
        accessor: 'damagedQty',
        isNumeric: true,
        id: 'damagedQty',
        width: getColumnWidth(10.6, 165),
        helpText: 'Qty Damaged',
      },
      {
        Header: 'On Hold',
        accessor: 'onHoldQty',
        id: 'onHoldQty',
        isNumeric: true,
        width: getColumnWidth(10.6, 160),
        helpText: 'Qty on Hold, Unavailable',
      },
      {
        Header: 'Total QTY',
        accessor: 'totalQty',
        id: 'totalQty',
        Cell: ({ row }: { row: Row<InventoryItem> }) => (
          <>{row.values['totalQty'] * row.original['conversionFactor']}</>
        ),
        isNumeric: true,
        width: getColumnWidth(10.6, 170),
        helpText:
          'Total Qty On Hand = Available + Promised + Damaged + On Hold',
      },
    ],
    [location.search, getColumnWidth, warehouses]
  );
  const buttons = [
    {
      label: resources.labels.button,
      handler: () => {
        onDownloadReport(
          REPORT_TYPES.INVENTORY,
          buildParameters(dictFieldToQuery, filters)
        );
      },
      isLoading: isDownloadingReport,
      tooltip: isDownloadingReport
        ? resources.labels.tooltip.isDownloading
        : resources.labels.tooltip.idle,
    },
  ];

  return widthWindow ? (
    <Box>
      <Flex
        justifyContent="space-between"
        alignItems={[null, 'center']}
        flexDirection={['column', null, 'row']}
      >
        <Text mb={[buttons.length ? 4 : 0, 0]} as="h2" variant="pageTitle">
          Inventory
        </Text>
        {buttons.length ? (
          <ButtonGroup
            buttons={buttons}
            wrapperProps={{
              display: 'flex',
              flexDirection: ['column-reverse', null, 'row'],
              width: 'auto',
            }}
            commonButtonProps={{ fontSize: 'lg' }}
            commonButtonWrapperProps={{ mr: 0 }}
          />
        ) : null}
      </Flex>
      <PaginatedTable
        columns={inventoryColumns}
        gridSorting={gridSorting}
        gridFiltering={gridFiltering}
        gridPagination={gridPagination}
        queryState={queryState}
        tableName="inventory"
        isResizable
      />
    </Box>
  ) : (
    <BigLoading />
  );
}
