/* istanbul ignore file */
import { createContext, FC, useRef, useState } from 'react';
import {
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react';

type ModalContextType = {
  showConfirmation: (config: ConfirmationDialogConfig) => void;
};

type ConfirmationDialogConfig = {
  title: string;
  message: string;
  cancelButtonLabel?: string;
  confirmButtonLabel?: string;
  onCancel?: () => void;
  onConfirm?: () => void;
  blockScrollOnMount?: boolean;
};

type ConfirmationDialogContextProviderProps = {
  children: React.ReactNode;
};

export const ConfirmationDialogContext = createContext<ModalContextType>(
  {} as ModalContextType
);

export const ConfirmationDialogContextProvider: FC<
  ConfirmationDialogContextProviderProps
> = (props) => {
  const { onOpen, isOpen, onClose } = useDisclosure();
  const [dialogConfig, setDialogConfig] = useState<ConfirmationDialogConfig>();
  const cancelRef = useRef(null);

  const handleShow = (config: ConfirmationDialogConfig) => {
    setDialogConfig(config);
    onOpen();
  };

  const handleCancel = () => {
    dialogConfig?.onCancel?.();
    onClose();
  };

  const handleConfirm = () => {
    dialogConfig?.onConfirm?.();
    onClose();
  };

  return (
    <ConfirmationDialogContext.Provider
      value={{
        showConfirmation: handleShow,
      }}
    >
      {props.children}

      {dialogConfig && (
        <AlertDialog
          isOpen={isOpen}
          onClose={onClose}
          blockScrollOnMount={dialogConfig.blockScrollOnMount}
          leastDestructiveRef={cancelRef}
          size="2xl"
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="2xl" fontWeight="bold">
                {dialogConfig.title}
              </AlertDialogHeader>

              <AlertDialogBody>{dialogConfig.message}</AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={handleCancel} mr={3} flex={1}>
                  {dialogConfig.cancelButtonLabel ?? 'Cancel'}
                </Button>
                <Button variant="cta" onClick={handleConfirm} flex={1}>
                  {dialogConfig.confirmButtonLabel ?? 'Confirm'}
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      )}
    </ConfirmationDialogContext.Provider>
  );
};
