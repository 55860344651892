import { ShipVia, ShipViaDto } from 'types/shipVia';

export function mapDtoToShipVia(dto: ShipViaDto): ShipVia {
  return {
    shipVia: dto.ship_via,
    shippingAccounts: dto.shipping_accounts.map((account) => ({
      distributionCenterCode: account.distribution_center_code,
      countryCode: account.country_code,
      carrierAccountUuid: account.carrier_account_uuid,
    })),
  };
}
