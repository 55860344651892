import { useState } from 'react';
import { Flex, Box, BoxProps, Text } from '@chakra-ui/react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

interface Props extends BoxProps {
  hiddenCover?: React.ReactNode;
  bypass?: boolean;
  children: React.ReactNode;
}

export function ToggleVisibility({
  hiddenCover,
  children,
  bypass,
  ...props
}: Props) {
  const [isVisible, setIsVisible] = useState(false);
  const cover = hiddenCover ?? '•••••';
  const handleToggleClick = () => {
    setIsVisible((isVisible) => !isVisible);
  };
  const EyeIcon = isVisible ? VisibilityOffIcon : VisibilityIcon;

  if (bypass) {
    return <>{children}</>;
  }

  return (
    <Box
      position="relative"
      onClick={handleToggleClick}
      cursor="pointer"
      role="button"
      {...props}
    >
      <Text
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        width="calc(100% - 25px)"
      >
        {isVisible ? children : cover}
      </Text>
      <Flex
        aria-label="Toggle Visibility"
        flexDirection="column"
        position="absolute"
        inset="0"
        left="auto"
        justifyContent="center"
        transform="scale(0.7)"
        maxHeight="36px"
        color="primaryBlue.500"
      >
        <EyeIcon />
      </Flex>
    </Box>
  );
}
