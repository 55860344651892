import { ComponentStyleConfig } from '@chakra-ui/react';

const sharedStyle = {
  _focusVisible: {
    borderColor: 'secondaryGreen.500',
  },
  _invalid: {
    borderColor: 'errorRed !important',
  },
  _readOnly: {
    _focusVisible: {
      borderColor: 'primaryBlue.500',
    },
    bgColor: 'secondaryGray.200',
    cursor: 'not-allowed',
    marginTop: 2,
  },
};

export const inputTheme: ComponentStyleConfig = {
  baseStyle: {
    field: {
      _disabled: {
        opacity: 0.4,
        cursor: 'not-allowed',
      },
    },
  },
  variants: {
    flushed: {
      field: {
        borderBottomWidth: 2,
        borderBottomColor: 'primaryBlue.500',
        boxShadow: 'none !important',
        ...sharedStyle,
      },
    },
    outline: {
      field: {
        borderRadius: 0,
        borderColor: 'secondaryTeal.500',
        fontSize: 'sm',
        padding: '0 0 0 5px',
        boxShadow: 'none !important',
        ...sharedStyle,
        _readOnly: {
          ...sharedStyle._readOnly,
          marginTop: 0,
        },
      },
    },
    headingInput: {
      field: {
        ...sharedStyle,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: 'secondaryOrange.500',
        fontSize: 'sm',
        padding: '0 0 0 5px',
        color: 'secondaryOrange.500',
        _placeholderShown: {
          borderColor: 'gray.300',
        },
        _hover: {
          borderColor: 'secondaryOrange.500',
        },
        _focus: {
          borderColor: 'secondaryOrange.500',
        },
        _readOnly: {
          ...sharedStyle._readOnly,
          _focusVisible: {},
          marginTop: 0,
        },
      },
    },
  },
  defaultProps: {
    variant: 'flushed',
  },
};
