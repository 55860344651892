import { useRef } from 'react';
import {
  Flex,
  IconButton,
  Slide,
  SlideProps,
  useOutsideClick,
} from '@chakra-ui/react';
import CloseIcon from '@mui/icons-material/Close';
import { LayoutBox } from './LayoutBox';

interface SlideMenuProps extends SlideProps {
  isOpen: boolean;
  closeMenu: () => void;
  justifyContent?: string;
  children: React.ReactNode;
}

export const SlideMenu = ({
  isOpen,
  closeMenu,
  children,
  justifyContent = 'normal',
  ...props
}: SlideMenuProps) => {
  const menuWrapperRef = useRef<HTMLDivElement>(null);
  useOutsideClick({
    ref: menuWrapperRef,
    handler: closeMenu,
  });

  return (
    <Slide in={isOpen} direction="left" style={{ zIndex: 10 }} {...props}>
      <LayoutBox
        variant="navBox"
        as="nav"
        id="main-menu"
        hidden={!isOpen}
        ref={menuWrapperRef}
      >
        <IconButton
          variant="ghost"
          size="sm"
          onClick={closeMenu}
          title="Close"
          aria-label="Close main menu"
          aria-controls="main-menu"
          aria-expanded={isOpen}
          icon={<CloseIcon />}
          position="absolute"
          top="5"
          right="5"
        />
        <Flex
          mt={8}
          mx="auto"
          w="80%"
          flexDirection="column"
          justifyContent={justifyContent}
        >
          {children}
        </Flex>
      </LayoutBox>
    </Slide>
  );
};
