import { AxiosError } from 'axios';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { ExceptionState } from 'components/ExceptionState';
import { NetworkOrderDetails } from 'features/network-order-details/NetworkOrderDetails';
import { useNetworkOrder } from 'queries/networkOrders/useNetworkOrder';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ModalWrapper } from 'components/ModalWrapper';

export function NetworkOrderDetailsPage() {
  const { orderName } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const urlSegments = location.pathname.split('/');
  const firstUrlSegment = urlSegments[1] ?? '';

  const { data: order, error } = useNetworkOrder(orderName);

  return (
    <ErrorBoundary size="full">
      <ModalWrapper
        title={`Order #${orderName}`}
        onClose={() => navigate(`/${firstUrlSegment}${location.search}`)}
      >
        {(error as AxiosError)?.response?.status === 404 ? (
          <ExceptionState customTitle="Order Not Found" stateType="empty" />
        ) : (
          <NetworkOrderDetails order={order} />
        )}
      </ModalWrapper>
    </ErrorBoundary>
  );
}
