import { ComponentStyleConfig } from '@chakra-ui/react';

export const layoutBoxTheme: ComponentStyleConfig = {
  variants: {
    headerBox: {
      bg: 'black',
      position: 'fixed',
      zIndex: '2',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      w: '100%',
      h: { base: '65px', md: '80px' },
      background: 'white',
      color: 'primaryBlue.500',
      px: 6,
      boxShadow: '0px 10px 15px -3px rgba(0, 0, 0, 0.1)',
    },
    mainBox: {
      maxW: '100%',
      pb: 12,
      px: [4, 4, 4, 12],
      mx: 'auto',
    },
    navBox: {
      display: 'flex',
      bg: 'white',
      boxShadow: '10px 0px 15px -3px rgba(0, 0, 0, 0.1)',
      position: 'absolute',
      h: '100%',
      w: { base: '100%', md: '375px' },
    },
  },
};
