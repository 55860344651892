import { SortState } from 'hooks/useGridSorting';
import { SalesOrder } from 'types/salesOrders';
import { QueryParamsObject } from 'utils/urlSearchParamsUtils';

export interface OrderPageParams {
  initialSort?: SortState<SalesOrder>;
  onSortByChange?: (sorting: SortState<SalesOrder>) => void;
  initialPage?: number;
  onPageChange?: (page: number) => void;
  initialPageSize?: number;
  onPaginationChange?: (pagination: { page: number; pageSize: number }) => void;
  initialFilters?: QueryParamsObject;
  onFilterChange?: (filters: QueryParamsObject) => void;
}

export const dictFieldToQuery = {
  soNumber: 'so-number',
  poNumber: 'po-number',
  storefront: 'storefront-name-select',
  status: 'latest-status',
  createdDate: 'created-date-range',
  dueDate: 'due-date-range',
  shipTo: 'ship-to-name',
  shipVia: 'ship-via-name',
  shipDate: 'latest-ship-date-range',
  totalOrderQty: 'total-order-qty-range',
  totalShippedQty: 'total-shipped-qty-range',
  totalShortQty: 'total-short-qty-range',
  totalPickedQty: 'total-picked-qty-range',
  totalUnpickedQty: 'total-unpicked-qty-range',
  totalCanceledQty: 'total-canceled-qty-range',
  targetDc: 'target-dc-select',
  type: 'type',
} as { [key in keyof SalesOrder]: string };

export function queryToOrderField(
  queryKey: string,
  queryWordSeparator: string = '-'
): keyof SalesOrder | '' {
  const dictQueryToField = {
    'so-number': 'soNumber',
    'po-number': 'poNumber',
    'storefront-name-select': 'storefront',
    'latest-status': 'status',
    'created-date-range': 'createdDate',
    'due-date-range': 'dueDate',
    'ship-to-name': 'shipTo',
    'ship-via-name': 'shipVia',
    'latest-ship-date-range': 'shipDate',
    'total-order-qty-range': 'totalOrderQty',
    'total-shipped-qty-range': 'totalShippedQty',
    'total-short-qty-range': 'totalShortQty',
    'total-picked-qty-range': 'totalPickedQty',
    'total-unpicked-qty-range': 'totalUnpickedQty',
    'total-canceled-qty-range': 'totalCanceledQty',
    'target-dc-select': 'targetDc',
    type: 'type',
  } as { [key: string]: keyof SalesOrder };
  const field =
    dictQueryToField[queryKey.replaceAll(queryWordSeparator, '-')] || '';
  return field;
}

export function orderFieldToQuery(
  orderField: string,
  queryWordSeparator: string = '-'
): string {
  const queryKey =
    dictFieldToQuery[orderField as keyof SalesOrder] || orderField;
  return queryKey.replaceAll('-', queryWordSeparator);
}
