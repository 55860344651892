import { SimpleGrid } from '@chakra-ui/react';
import { LabelValue } from 'components/LabelValue';
import { UserSystem } from 'types/userProfile';
import { formatDate } from 'utils/dateUtils';

type UserProfileSystemProps = {
  data: UserSystem;
};

export function UserProfileSystem({
  data: { createdDate, lastLoginDate, emailVerified },
}: UserProfileSystemProps) {
  return (
    <SimpleGrid columns={[1, 2]} columnGap={8} rowGap={0} marginBottom={[2, 0]}>
      <LabelValue label="Created Date" value={formatDate(createdDate)} />
      <LabelValue label="Last Login Date" value={formatDate(lastLoginDate)} />
      <LabelValue label="Email Verified" value={emailVerified ? 'Yes' : 'No'} />
    </SimpleGrid>
  );
}
