import { isEmpty } from 'lodash-es';
import {
  DtoFieldError,
  DtoFieldErrorSegment,
} from 'hooks/useCrudActionUserFeedback';
import {
  UserProfile,
  UserProfileDto,
  UserProfileModify,
  UserProfileModifyDto,
  UserSystem,
} from 'types/userProfile';
import { mapRoleDtoToOption } from 'queries/role/mappers';
export function mapDtoToUserProfile(profile: UserProfileDto): UserProfile {
  return {
    firstName: profile.first_name || '',
    lastName: profile.last_name || '',
    nickName: profile.nickname || '',
    emailAddress: profile.email_address,
    picture: profile.picture || '',
    createdDate: profile.created_at,
    lastLoginDate: profile.last_login || '',
    emailVerified: profile.email_verified,
    pronouns: profile.custom_fields?.pronouns || '',
    department: profile.custom_fields?.department || '',
    jobTitle: profile.custom_fields?.job_title || '',
    phoneNumber: profile.custom_fields?.phone_number
      ? profile.custom_fields?.phone_number.substring(1)
      : '',
    roles: profile.roles?.length
      ? mapRoleDtoToOption({ roles: profile.roles })
      : [],
  };
}

export function mapUserProfileModifyToDto(
  profile: UserProfileModify
): UserProfileModifyDto {
  return {
    first_name: profile.firstName,
    last_name: profile.lastName,
    nickname: profile.nickName || '',
    picture: profile.picture || '',
    custom_fields: {
      pronouns: !isEmpty(profile.pronouns) ? profile.pronouns || '' : null,
      department: !isEmpty(profile.department)
        ? profile.department || ''
        : null,
      job_title: !isEmpty(profile.jobTitle) ? profile.jobTitle || '' : null,
      phone_number: !isEmpty(profile.phoneNumber)
        ? `+${profile?.phoneNumber?.replace(/\D/g, '') || null}`
        : null,
    },
  };
}

export function mapUserProfileToModify(
  profile: UserProfile
): UserProfileModify {
  return {
    firstName: profile.firstName,
    lastName: profile.lastName,
    nickName: profile.nickName,
    pronouns: profile.pronouns,
    picture: profile.picture,
    phoneNumber: profile.phoneNumber,
    department: profile.department,
    jobTitle: profile.jobTitle,
  };
}

export function mapUserProfileToUserSystem(
  profile: UserProfile | undefined
): UserSystem {
  return {
    createdDate: profile?.createdDate || '',
    lastLoginDate: profile?.lastLoginDate || '',
    emailVerified: profile?.emailVerified || false,
  };
}

export function mapFieldDtoErrorToRhfPath(error: DtoFieldError) {
  const path = [];
  const segments = error.loc?.slice(1) ?? [];

  for (let i = 0; i < segments.length; i++) {
    const segment = segments[i];
    let mappedSegment = null;

    if (segments[0] !== 'user_metadata') {
      mappedSegment = mapTopLevelDtoSegmentToRhfSegment(segment);
    } else if (segments[0] === 'user_metadata' && i > 0) {
      mappedSegment = mapCustomFieldsDtoSegmentToRhfSegment(segment);
    }

    mappedSegment && path.push(mappedSegment);
  }

  return path.length === 0 || path.some((s) => s === null)
    ? null
    : path.join('.');
}

function mapTopLevelDtoSegmentToRhfSegment(segment: DtoFieldErrorSegment) {
  return (
    {
      given_name: 'firstName',
      family_name: 'lastName',
    }[segment] ?? null
  );
}

function mapCustomFieldsDtoSegmentToRhfSegment(segment: DtoFieldErrorSegment) {
  return (
    {
      phone_number: 'phoneNumber',
    }[segment] ?? null
  );
}
