import { MsaDocument } from 'types/msa';

export function queryToMsaField(
  queryKey: string,
  queryWordSeparator: string = '-'
): keyof MsaDocument {
  const dictQueryToField: { [key: string]: keyof MsaDocument } = {
    'agreement-title': 'fileName',
    'effective-start-date': 'effectiveStartDate',
    'effective-end-date': 'effectiveEndDate',
  };
  const field =
    dictQueryToField[queryKey.replaceAll(queryWordSeparator, '-')] || '';
  return field;
}

export function msaFieldToQuery(
  userField: string,
  queryWordSeparator: string = '-'
): string {
  const dictFieldToQuery = {
    fileName: 'agreement-title',
    effectiveStartDate: 'effective-start-date',
    effectiveEndDate: 'effective-end-date',
  } as { [key in keyof MsaDocument]: string };
  const queryKey =
    dictFieldToQuery[userField as keyof MsaDocument] || userField;
  return queryKey.replaceAll('-', queryWordSeparator);
}
