import {
  LinkProps as ChakraLinkProps,
  Link as ChakraLink,
} from '@chakra-ui/react';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';

export type LinkProps = ChakraLinkProps & Pick<RouterLinkProps, 'to'>;

export const Link: React.FC<LinkProps> = (props) => {
  return <ChakraLink as={RouterLink} {...props} />;
};
