import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

export function useDeleteMsaDocument() {
  const queryClient = useQueryClient();
  return useMutation<any, any, string>({
    mutationFn: (fileName) => axios.delete(`/admin/msa/${fileName}`),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['msa-documents'],
      }),
  });
}
