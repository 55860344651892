import { Box } from '@chakra-ui/react';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { InlineText } from 'components/InlineText';
import { Shimmer } from 'components/Shimmer';
import { PurchaseOrder } from 'types/purchaseOrders';
import { StatusChangeBreadcrumb } from '../../../order-details/components/ViewOrder/StatusChangeBreadcrumb';
import { PurchaseOrderLinesTable } from './PurchaseOrderLinesTable';
import { getContainerNumbersString } from 'utils/purchaseOrdersUtils';

type Props = {
  order?: PurchaseOrder;
};

export function PurchaseOrderDetails({ order }: Props) {
  return (
    <>
      <Box>
        <InlineText variant="boldBlue" fontSize="xl" mr={1}>
          Warehouse ID:
        </InlineText>

        <Shimmer
          w="160px"
          h="30px"
          display="inline-block"
          verticalAlign="top"
          isLoaded={!!order}
        >
          <InlineText fontSize="xl" display={['block', null, 'inline']}>
            {order?.warehouseID}
          </InlineText>
        </Shimmer>
      </Box>

      <ErrorBoundary>
        <StatusChangeBreadcrumb
          history={order?.statusChangesHistory}
          my={4}
          containerStatus={order?.containerStatus}
          containerNumbers={order?.containerNumbers}
        />
      </ErrorBoundary>

      <Box>
        <InlineText variant="boldBlue" fontSize="xl" mr={1}>
          Container Number
          {(order?.containerNumbers?.length ?? 0) > 0 ? 's' : ''}:
        </InlineText>

        <Shimmer
          w="160px"
          h="30px"
          display="inline-block"
          verticalAlign="top"
          isLoaded={!!order}
        >
          <InlineText fontSize="xl" display={['block', null, 'inline']}>
            {order &&
              getContainerNumbersString(
                order.containerNumbers,
                order.containerStatus
              )}
          </InlineText>
        </Shimmer>
      </Box>
      <Box mx={-2} overflow="auto">
        <ErrorBoundary>
          <PurchaseOrderLinesTable order={order} mt={30} />
        </ErrorBoundary>
      </Box>
    </>
  );
}
