import { Text } from '@chakra-ui/react';
import { Prompt } from 'components/Prompt';
import { useCrudActionUserFeedback } from 'hooks/useCrudActionUserFeedback';
import { useCancelPurchaseOrder } from 'mutations/purchaseOrders/useCancelPurchaseOrder';
import { PurchaseOrder } from 'types/purchaseOrders';
import { sendConfirmCancelPurchaseOrderEvent } from 'utils/monitorEvents';

type Props = {
  order: PurchaseOrder;
  onConfirm: () => void;
  onAbort: () => void;
};

export function CancelPurchaseOrderPrompt({
  order,
  onConfirm,
  onAbort,
}: Props) {
  const { mutateAsync, isLoading } = useCancelPurchaseOrder(order.poNumber);

  const handleCancelOrder = useCrudActionUserFeedback<PurchaseOrder>({
    mutateAsync,
    actionType: 'DELETE',
    successMessage: `PO #${order.poNumber} deleted`,
    successCallback: () => {
      sendConfirmCancelPurchaseOrderEvent(order.poNumber);
      onConfirm();
    },
    errorCallback: () => {
      onAbort();
    },
  });

  return (
    <Prompt
      title={`Cancel PO #${order?.poNumber}`}
      content={<Text>Are you sure you want to cancel this order?</Text>}
      buttons={[
        {
          label: 'Go Back',
          handler: onAbort,
        },
        {
          label: 'Cancel Order',
          handler: handleCancelOrder,
          isLoading,
        },
      ]}
    />
  );
}
