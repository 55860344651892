import { FormSelect, FormSelectProps } from 'components/FormSelect';
import { useCountries } from 'queries/countries/useCountries';
import { RATE_SHOP_OPTION } from 'queries/shipVias/useShipVias';
import { useTenant } from 'queries/tenant/useTenant';
import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { CONFIG } from 'utils/config';

type Props = Omit<FormSelectProps, 'options'>;

function CountrySelect(props: Props) {
  const {
    register,
    watch,
    trigger,
    clearErrors,
    formState: { touchedFields },
  } = useFormContext();
  const shipVia = watch('shipVia');
  const country = watch('shipTo.country');

  const { data: tenant } = useTenant();
  const { data: dataCountries = [], isFetched } = useCountries();

  const allowInternational = useMemo(() => {
    return (
      CONFIG().rateShopInternationalTenant.some(
        (code) => code === tenant?.code
      ) && shipVia === RATE_SHOP_OPTION
    );
  }, [shipVia, tenant]);

  const countries = useMemo(() => {
    const data = allowInternational
      ? dataCountries
      : dataCountries.filter((item) => !item.requiresCustoms);

    return data.map((item) => ({
      value: item.countryCode,
      label: item.name,
    }));
  }, [dataCountries, allowInternational]);

  useEffect(() => {
    if (touchedFields.shipTo?.country) {
      const fields = ['shipTo.state', 'shipTo.phoneNumber'];
      clearErrors(fields);
      trigger(fields);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);

  return (
    <FormSelect
      {...props}
      key={`country-select-${isFetched}`}
      label="Country"
      {...register('shipTo.country')}
      options={countries}
      isRequired
    />
  );
}

export default CountrySelect;
