import {
  Step,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper as ChakraStepper,
  StepperProps as ChakraStepperProps,
  Box,
  Collapse,
  Text,
} from '@chakra-ui/react';
import { useControllableState } from '@chakra-ui/react';
import { PRIMARY_BLUE_500 } from 'theme/ui-palette';

type StepDefinition = {
  title: string;
  component: React.FC;
};

export type StepperProps = Omit<
  ChakraStepperProps,
  'index' | 'children' | 'onChange'
> & {
  steps: StepDefinition[];
  value?: number;
  onChange?: (index: number) => void;
};

export function Stepper({ steps, value, onChange, ...props }: StepperProps) {
  const [internalValue, setInternalValue] = useControllableState({
    value,
    onChange: onChange,
  });

  return (
    <ChakraStepper
      index={internalValue ?? 0}
      orientation="vertical"
      height="auto"
      gap="0"
      justifyContent="flex-start"
      {...props}
    >
      {steps.map((step, index) => (
        <Step
          style={
            {
              flex: 0,
              '--stepper-accent-color': PRIMARY_BLUE_500,
            } as React.CSSProperties
          }
          key={index}
          onClick={() => setInternalValue(index)}
        >
          <StepIndicator style={{ cursor: 'pointer' }}>
            <StepStatus
              complete={<StepIcon />}
              incomplete={<StepNumber />}
              active={<StepNumber />}
            />
          </StepIndicator>

          <Box flexShrink={1}>
            <StepTitle style={{ cursor: 'pointer' }}>
              <Text variant="pageHeading" mt={{ base: 0.5, md: -0.5 }}>
                {step.title}
              </Text>
            </StepTitle>
            <Box py={2} minH={8}>
              <Collapse in={internalValue === index} unmountOnExit>
                <step.component />
              </Collapse>
            </Box>
          </Box>

          <StepSeparator />
        </Step>
      ))}
    </ChakraStepper>
  );
}
