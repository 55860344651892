/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Button,
  Flex,
  GridItem,
  SimpleGrid,
  Tab,
  TabList,
  Tabs,
  Text,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider } from 'react-hook-form';
import { ButtonGroup } from 'components/ButtonGroup';
import { SetHandlerInterceptor } from 'hooks/useHandlerInterceptor';
import { SalesOrder, SalesOrderType } from 'types/salesOrders';
import { createFreightSalesOrderSchema } from 'schemas/createFreightSalesOrder';
import { createParcelSalesOrderSchema } from 'schemas/createParcelSalesOrder';
import { useCreateSalesOrder } from 'mutations/salesOrders/useCreateSalesOrder';
import { CreateParcelOrderForm } from './CreateParcelOrderForm';
import { CreateFreightOrderForm } from './CreateFreightOrderForm';
import { useCrudActionUserFeedback } from 'hooks/useCrudActionUserFeedback';
import { mapFieldDtoErrorToRhfPath } from 'mutations/salesOrders/mappers';
import RestoreIcon from '@mui/icons-material/Restore';
import { useConfirmationDialog } from 'hooks/useConfirmationDialog';
import {
  buildInittialLines,
  buildEmptyOrder,
} from 'queries/salesOrders/mappers';
import { sendCreateSalesOrderEvent } from 'utils/monitorEvents';
import { useSalesOrderForm } from 'hooks/useSalesOrderForm';
import { useLocation } from 'react-router-dom';
import merge from 'lodash-es/merge';
import { useShipVias } from 'queries/shipVias/useShipVias';
import { BasicInfoForm } from './BasicInfoForm';
import { ShipToForm } from 'features/order-details/components/ModifyOrder/ShipToForm';
import { InstructionsForm } from './InstructionsForm';
import { OrderLines } from 'features/order-lines/OrderLines';

type Props = {
  onConfirm: () => void;
  onAbort: () => void;
  setCloseInterceptor: SetHandlerInterceptor;
};

const sessionStorageKey = 'create_so_draft';

const tabs = [
  { type: 'Parcel', label: 'Parcel Order', isSelected: true },
  { type: 'Freight', label: 'Freight Order' },
] as const;

export const CreateOrder: React.FC<Props> = ({
  onAbort,
  onConfirm,
  setCloseInterceptor,
}) => {
  const location = useLocation();
  const { openConfirmationDialog } = useConfirmationDialog();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const draftJson = sessionStorage.getItem(sessionStorageKey);
  const { getDefaultShipVia } = useShipVias();

  const defaultOrderValue = useMemo(() => {
    const emptyOrder = buildEmptyOrder();
    const draftData = draftJson ? { ...JSON.parse(draftJson) } : null;
    const reshipOrder = location.state as SalesOrder;
    if (reshipOrder?.type) {
      return merge(emptyOrder, reshipOrder);
    }
    if (draftData) {
      return merge(
        emptyOrder,
        draftData.lines.length
          ? draftData
          : { ...draftData, lines: buildInittialLines() }
      );
    }
    return {
      ...emptyOrder,
      shipVia: emptyOrder.type === 'Parcel' ? getDefaultShipVia() : '',
    };
  }, []);

  const [draft, setDraft] = useState<any>(defaultOrderValue);
  const reactHookFormRet = useSalesOrderForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: defaultOrderValue,
    resolver: yupResolver(
      defaultOrderValue.type === 'Freight'
        ? createFreightSalesOrderSchema
        : createParcelSalesOrderSchema
    ),
  });
  const {
    handleSubmit,
    getValues,
    setValue,
    reset,
    formState: { isDirty },
  } = reactHookFormRet;

  const confirmAbort = useCallback(
    (callback: () => void) => {
      if (isDirty || draftJson) {
        sessionStorage.setItem(sessionStorageKey, JSON.stringify(getValues()));
      }

      callback();
    },
    [isDirty]
  );

  useEffect(() => {
    setCloseInterceptor(confirmAbort);
  }, [confirmAbort]);

  const { mutateAsync } = useCreateSalesOrder();

  const handleCreateOrder = useCrudActionUserFeedback<SalesOrder>({
    mutateAsync: () => mutateAsync(getValues()),
    actionType: 'CREATE',
    successMessage: 'Order successfully created',
    successCallback: () => {
      sendCreateSalesOrderEvent(draft.type, draft.soNumber);
      onConfirm();
      sessionStorage.removeItem(sessionStorageKey);
    },
    startCallback: () => {
      setIsSubmitting(true);
    },
    finallyCallback: () => {
      setIsSubmitting(false);
    },
    setFieldError: reactHookFormRet.setError,
    mapFieldDtoErrorToRhfPath,
  });

  const handleResetClick = () => {
    openConfirmationDialog({
      title: 'Discard changes',
      message: 'Discard changes?',
      onConfirm: () => {
        const draft = {
          ...buildEmptyOrder(),
          shipVia: getDefaultShipVia(),
        };
        setDraft(draft);
        reset(draft);
        sessionStorage.removeItem(sessionStorageKey);
      },
      blockScrollOnMount: false,
      confirmButtonLabel: 'Discard',
    });
  };

  const handleOrderTypeChange = (orderType: SalesOrderType) => {
    setDraft({ ...draft, type: orderType });
    setValue('shipVia', orderType === 'Freight' ? '' : getDefaultShipVia());
    setValue('type', orderType);
  };

  return (
    <FormProvider {...reactHookFormRet}>
      <Flex justifyContent="flex-end">
        <Button
          variant="ghost"
          mr={-5}
          ml={[-4, null, 0]}
          mt={[-4, null, 0]}
          onClick={handleResetClick}
        >
          <Text mr={2}>Reset</Text> <RestoreIcon />
        </Button>
      </Flex>
      <form onSubmit={handleSubmit(handleCreateOrder)}>
        <SimpleGrid columns={1} gap={8} mt={[5, null, 0]}>
          <GridItem>
            <Text variant="boldBlue" fontSize="xl">
              General
            </Text>
            <BasicInfoForm startTabIndex={0} />
          </GridItem>
          <GridItem>
            <Text variant="boldBlue" fontSize="xl">
              Ship To
            </Text>
            <ShipToForm startTabIndex={3} />
          </GridItem>
          <InstructionsForm tabIndex={13} />
          <GridItem>
            <Text variant="boldBlue" fontSize="xl">
              Shipping Options
            </Text>
            <Tabs
              mt={2}
              variant="unstyled"
              index={draft.type === 'Parcel' ? 0 : 1}
              isManual
              isLazy
            >
              <TabList color="#4A5568">
                {tabs.map((tab, index) => (
                  <Tab
                    key={index}
                    onClick={() => handleOrderTypeChange(tab.type)}
                    px={0}
                    py={2}
                    mr={6}
                    fontSize="xl"
                    _selected={{
                      color: 'primaryBlue.500',
                      borderBottomWidth: 2,
                      borderColor: 'primaryOrange.500',
                      fontWeight: 'bold',
                    }}
                    _hover={{ opacity: '0.75' }}
                  >
                    {tab.label}
                  </Tab>
                ))}
              </TabList>
            </Tabs>
            {draft.type === 'Parcel' && (
              <CreateParcelOrderForm startTabIndex={13} />
            )}
            {draft.type === 'Freight' && (
              <CreateFreightOrderForm startTabIndex={13} />
            )}
          </GridItem>
          <GridItem>
            <Text variant="boldBlue" fontSize="xl">
              SKUs
            </Text>
            <OrderLines
              startTabIndex={20}
              isFreightOrder={draft.type === 'Freight'}
            />
          </GridItem>
          <SimpleGrid px={4} columns={{ base: 1, xl: 2 }}>
            <ButtonGroup
              wrapperProps={{ display: 'grid' }}
              commonButtonProps={{ width: '100%' }}
              buttons={[
                {
                  label: 'Create Order',
                  variant: 'cta',
                  isLoading: isSubmitting,
                },
                {
                  label: 'Cancel',
                  handler: () => confirmAbort(onAbort),
                },
              ]}
            />
          </SimpleGrid>
        </SimpleGrid>
      </form>
    </FormProvider>
  );
};
