import { useMemo, useRef, useState } from 'react';
import { ColumnInstance } from 'react-table';
import createContext from 'utils/createContext';
import { persistTableHiddenColumns } from 'utils/storageUtils';

type TableContextProps = {
  tableName?: string;
  columnSettings?: ColumnInstance<object>[];
  defaultHiddenColumns?: string[];
  persistedHiddenColumns?: string[];
};

const useTable = ({
  tableName,
  columnSettings,
  defaultHiddenColumns = [],
  persistedHiddenColumns = [],
}: TableContextProps = {}) => {
  const [hiddenColumns, setHiddenColumns] = useState<string[]>(
    persistedHiddenColumns
  );
  const previousHiddenColumns = useRef<string[]>(persistedHiddenColumns);

  const isLastHiddenColumn = useMemo(() => {
    return hiddenColumns.length + 1 === columnSettings?.length;
  }, [hiddenColumns, columnSettings]);

  const toggleHiddenColumn = (columnId: string) => {
    setHiddenColumns((prev) => {
      if (prev.includes(columnId)) {
        return prev.filter((id) => id !== columnId);
      }
      return [...prev, columnId];
    });
  };

  const resetDefaultHiddenColumns = () => {
    setHiddenColumns(defaultHiddenColumns);
  };

  const resetHiddenColumns = () => {
    setHiddenColumns(previousHiddenColumns.current);
  };

  const applyHiddenColumns = () => {
    columnSettings?.forEach((column) => {
      column.toggleHidden(hiddenColumns.includes(column.id));
    });
    persistTableHiddenColumns(tableName!, hiddenColumns);
    previousHiddenColumns.current = hiddenColumns;
  };

  return {
    isLastHiddenColumn,
    toggleHiddenColumn,
    resetHiddenColumns,
    resetDefaultHiddenColumns,
    applyHiddenColumns,
    hiddenColumns,
    columnSettings,
    persistedHiddenColumns,
    defaultHiddenColumns,
    tableName,
  };
};

export const { Provider: TableContextProvider, useContext: useTableContext } =
  createContext(useTable);
