export enum TransportationReportType {
  Shipment = 'shipment',
  Billing = 'billing',
}

export enum TransportationDateType {
  PreviousWeek = 'previous-week',
  PreviousFourWeeks = 'previous-four-weeks',
  CustomDateRange = 'custom-date-range',
}
