import { Box, BoxProps, Center, Link as ChakraLink } from '@chakra-ui/react';
import ArrowUp from '@mui/icons-material/ArrowDropUp';
import ArrowDown from '@mui/icons-material/ArrowDropDown';
import { Link } from 'components/Link';
import { MenuItem } from './NavMenu';

interface NavMenuItemProps extends BoxProps {
  menuItem: MenuItem;
  isCollapsed?: boolean;
  isSubMenu?: boolean;
  ['aria-current']: 'page' | 'false';
}

export const NavMenuItem = ({
  menuItem,
  children,
  isCollapsed,
  isSubMenu,
  ...props
}: NavMenuItemProps) => {
  return (
    <Box
      as="li"
      role="menuitem"
      fontSize="xl"
      mt={isSubMenu ? 3 : 0}
      pt={3}
      pb={isSubMenu ? 0 : 3}
      borderBottomWidth={isSubMenu ? 0 : 1}
      borderTopWidth={isSubMenu ? 1 : 0}
      fontWeight={props['aria-current'] === 'page' ? 'bold' : 'normal'}
      borderColor={
        props['aria-current'] === 'page'
          ? 'primaryOrange.500'
          : !isSubMenu
          ? 'black'
          : '#D8D8D8'
      }
      _last={
        !isSubMenu
          ? {
              border: 'none',
            }
          : {}
      }
      _hover={{
        borderColor: 'primaryOrange.500',
        textDecoration: 'none',
        fontWeight: 'bold',
      }}
      {...props}
    >
      {menuItem.children?.length ? (
        <Box
          onClick={menuItem.onClick}
          display="flex"
          justifyContent="space-between"
          _hover={{ cursor: 'pointer' }}
          aria-label={menuItem.text}
        >
          {menuItem.text}
          <Center ml="auto">{isCollapsed ? <ArrowUp /> : <ArrowDown />}</Center>
        </Box>
      ) : menuItem.href ? (
        <ChakraLink variant="navLink" href={menuItem.href} target="_blank">
          {menuItem.text}
        </ChakraLink>
      ) : (
        <Link
          variant="navLink"
          role={menuItem.pathname ? 'link' : 'button'}
          to={menuItem.pathname || window.location.pathname}
          onClick={menuItem.onClick}
        >
          {menuItem.text}
          {menuItem.children && (
            <Center ml="auto">
              {isCollapsed ? <ArrowUp /> : <ArrowDown />}
            </Center>
          )}
        </Link>
      )}
      {children}
    </Box>
  );
};
