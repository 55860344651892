import { Box, BoxProps, Text } from '@chakra-ui/react';
import { InlineText } from 'components/InlineText';
import { Shimmer } from 'components/Shimmer';
import { StatusHistoryEntry } from 'types/status';
import { formatDate } from 'utils/dateUtils';

type Props = BoxProps & {
  history?: StatusHistoryEntry<any>[];
  containerNumbers?: { containerNumber: string }[];
  containerStatus?: string[];
};

function ContainerStatus({
  containerNumbers,
  containerStatus,
}: {
  containerNumbers?: { containerNumber: string }[];
  containerStatus?: string[];
}) {
  if (
    containerNumbers &&
    Array.isArray(containerNumbers) &&
    containerStatus &&
    Array.isArray(containerStatus)
  ) {
    const checkedContainers = containerStatus.filter(
      (c) => c.trim().length > 0 && c !== 'OPEN'
    );
    return (
      <Text>
        ({checkedContainers.length} of {containerNumbers.length} container
        {containerNumbers.length > 1 ? 's' : ''} checked in)
      </Text>
    );
  }

  return null;
}

export function StatusChangeBreadcrumb({
  history,
  containerNumbers,
  containerStatus,
  ...props
}: Props) {
  const currentStatus = history?.at(-1);
  const previousStatuses = history?.slice(0, -1);

  return (
    <Box alignItems="center" display="flex" gap={2} {...props}>
      <Box lineHeight={['shorter', null, 'base']}>
        <InlineText variant="boldBlue" fontSize="xl" mr={1}>
          Order Status
        </InlineText>
        <InlineText fontSize="xl" display={['block', null, 'inline']}>
          <Shimmer
            w="160px"
            h="30px"
            display="inline-block"
            verticalAlign="middle"
            isLoaded={!!history}
          >
            {currentStatus?.status} {formatDate(currentStatus?.status_date)}
          </Shimmer>
        </InlineText>
      </Box>
      <Box mt={[4, null, 0]} role="list">
        <Shimmer w="350px" h="22px" my={2} isLoaded={!!history}>
          {previousStatuses?.map(({ status, status_date }, i) => (
            <InlineText
              key={status + status_date}
              whiteSpace="nowrap"
              display={['block', null, 'inline']}
              lineHeight={['shorter', null, 'base']}
              role="listitem"
            >
              {status} {formatDate(status_date)}
              <InlineText color="primaryOrange.500">
                {i === previousStatuses.length - 1 ? null : ' > '}
              </InlineText>
            </InlineText>
          ))}
        </Shimmer>
      </Box>
      <Box>
        <ContainerStatus
          containerNumbers={containerNumbers}
          containerStatus={containerStatus}
        />
      </Box>
    </Box>
  );
}
