import { Button, Flex, ListItem, Text, UnorderedList } from '@chakra-ui/react';
import { SearchResults } from 'types/search';
import { useNavigate } from 'react-router-dom';
import { InlineText } from 'components/InlineText';
import { Shimmer } from 'components/Shimmer';
import { MINIMUM_QUERY_LENGTH } from 'queries/search/useSearch';

type Props = {
  query: string;
  searchResults?: SearchResults;
  error: any;
  onRetrySearch: () => void;
  onResultClick: () => void;
};

export function SearchResultsList({
  query,
  searchResults,
  error,
  onRetrySearch,
  onResultClick,
}: Props) {
  const navigate = useNavigate();
  const hasSearchResults =
    searchResults &&
    searchResults.items.length +
      searchResults.salesOrders.length +
      searchResults.purchaseOrders.length >
      0;

  const handleSearchResultClick = (url: string) => {
    navigate(url);
    onResultClick();
  };

  return (
    <Flex
      position="absolute"
      w={['calc(100% - 85px)', null, null, '300px']}
      left={['20px', null, null, 'auto']}
      top={['58px', null, null, '40px']}
      right="8px"
      background="white"
      boxShadow="rgba(0, 0, 0, 0.25) 0px 5px 15px"
    >
      {error ? (
        <Text variant="thinGray" fontSize="sm" fontStyle="italic" my={3} mx={5}>
          An error has occurred connecting to the database, please
          <Button
            variant="link"
            display="inline-block"
            onClick={onRetrySearch}
            fontStyle="italic"
            fontSize="inherit"
            ml={1}
          >
            try again
          </Button>
        </Text>
      ) : hasSearchResults ? (
        <UnorderedList listStyleType="none" variant="searchResult">
          {searchResults.items.map((item) => (
            <ListItem
              key={`sku-${item.sku}`}
              onClick={() => handleSearchResultClick(`/inventory/${item.sku}`)}
            >
              {item.sku}
              <InlineText ml={1} variant="thinGray" fontSize="xs">
                (SKU#)
              </InlineText>
            </ListItem>
          ))}
          {searchResults.salesOrders.map((order) => (
            <ListItem
              key={`sales-order-${order.soNumber}`}
              onClick={() =>
                handleSearchResultClick(`/orders/${order.soNumber}`)
              }
            >
              {order.soNumber}
              <InlineText ml={1} variant="thinGray" fontSize="xs">
                (SO#)
              </InlineText>
            </ListItem>
          ))}
          {searchResults.purchaseOrders.map((order) => (
            <ListItem
              key={`purchase-order-${order.poNumber}`}
              onClick={() =>
                handleSearchResultClick(`/inbound-inventory/${order.poNumber}`)
              }
            >
              {order.poNumber}
              <InlineText ml={1} variant="thinGray" fontSize="xs">
                (PO#)
              </InlineText>
            </ListItem>
          ))}
        </UnorderedList>
      ) : query.trim().length < MINIMUM_QUERY_LENGTH ? (
        <Text variant="thinGray" fontStyle="italic" my={3} mx={5} fontSize="sm">
          Your query must be at least {MINIMUM_QUERY_LENGTH} characters long.
        </Text>
      ) : !searchResults ? (
        <UnorderedList listStyleType="none" variant="searchResult">
          <ListItem>
            <Shimmer w="180px" />
          </ListItem>
          <ListItem>
            <Shimmer w="150px" />
          </ListItem>
          <ListItem>
            <Shimmer w="210px" />
          </ListItem>
          <ListItem>
            <Shimmer w="130px" />
          </ListItem>
          <ListItem>
            <Shimmer w="230px" />
          </ListItem>
          <ListItem>
            <Shimmer w="160px" />
          </ListItem>
          <ListItem>
            <Shimmer w="140px" />
          </ListItem>
          <ListItem>
            <Shimmer w="190px" />
          </ListItem>
          <ListItem>
            <Shimmer w="160px" />
          </ListItem>
        </UnorderedList>
      ) : (
        <Text variant="thinGray" fontStyle="italic" my={3} mx={5} fontSize="sm">
          No results found
        </Text>
      )}
    </Flex>
  );
}
