import { Box, Button, Text } from '@chakra-ui/react';
import { PaginatedTable } from 'components/PaginatedTable';
import { useLayoutPaginatedTable } from 'hooks/useLayoutPaginatedTable';
import { mapItemMasterUploadErrorToResults } from 'queries/itemMasters/mappers';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Column } from 'react-table';
import {
  ItemMasterUploadError,
  ItemMasterUploadErrorResult,
  ItemMasterUploadResponse,
  ItemMastersUploadMode,
} from 'types/itemMaster';
import { QueryState } from 'types/queryState';

type UploadItemMastersResultsProps = {
  mode: ItemMastersUploadMode;
  data?: ItemMasterUploadResponse;
  error?: ItemMasterUploadError;
};

export function UploadItemMastersResults({
  mode,
  data,
  error,
}: UploadItemMastersResultsProps) {
  const navigate = useNavigate();
  const { getColumnWidth } = useLayoutPaginatedTable(0);

  const errorData = useMemo(
    () => mapItemMasterUploadErrorToResults(error),
    [error]
  );

  const columns: Column<ItemMasterUploadErrorResult>[] = useMemo(() => {
    return [
      {
        Header: 'Reason',
        accessor: 'reason',
        width: getColumnWidth(100, 330),
        Cell: ({ value }) => (
          <Text as="pre" fontWeight={500} whiteSpace="pre">
            {value}
          </Text>
        ),
      },
    ];
  }, [getColumnWidth]);

  return (
    <Box>
      <Text as="h3" variant="pageHeading" mb={2}>
        3. Results
      </Text>
      {data?.message ? (
        <>
          <Text
            fontWeight={500}
            mb={4}
            sx={{
              '&::first-letter': {
                textTransform: 'capitalize',
              },
            }}
          >
            {data.message}
          </Text>
          <Box display="flex" justifyContent="center">
            <Button
              variant="outline"
              size="md"
              onClick={() => navigate('/item-masters')}
            >
              View my item master catalog
            </Button>
          </Box>
        </>
      ) : errorData.length > 0 ? (
        <>
          <Text fontWeight={500}>
            <Text as="b" fontWeight="bold">
              Upload failed! 0
            </Text>{' '}
            records were {mode === 'create' ? 'created' : 'updated'}. Correct
            the errors listed below and resubmit the corrected file.
          </Text>
          <PaginatedTable
            clientSide
            tableName="create_itemmaster_error_table"
            columns={columns}
            queryState={
              {
                data: errorData,
                isLoading: false,
              } as QueryState<ItemMasterUploadErrorResult>
            }
            isResizable
          />
        </>
      ) : null}
    </Box>
  );
}
