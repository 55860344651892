import { Modal, ModalButtonProps } from 'components/Modal';

type Props = {
  title: string;
  onClose: () => void;
  children?: React.ReactNode;
  buttons?: ModalButtonProps[];
};

export function ModalWrapper(props: Props) {
  const { onClose, children, title, buttons = [] } = props;

  return (
    <Modal
      title={title}
      fullscreen
      isOpen
      scrollVisible
      onClose={onClose}
      buttons={[
        {
          label: 'Done',
          handler: onClose,
        },
        ...buttons,
      ]}
      modalHeaderProps={{
        'aria-label': title,
      }}
    >
      {children}
    </Modal>
  );
}
