import { Box } from '@chakra-ui/react';
import { UpdateItemMastersInstruction } from 'features/update-item-masters/UpdateItemMastersInstruction';
import { UpdateItemMastersPrepareTemplate } from 'features/update-item-masters/UpdateItemMastersPrepareTemplate';
import { UploadItemMastersResults } from 'features/create-item-masters/UploadItemMastersResults';
import { UploadItemMasters } from 'features/create-item-masters/UploadItemMasters';
import { useState } from 'react';
import {
  ItemMasterUploadError,
  ItemMasterUploadResponse,
} from 'types/itemMaster';

export function UpdateItemMastersPage() {
  const [uploadResponse, setUploadResponse] = useState<{
    data?: ItemMasterUploadResponse;
    error?: ItemMasterUploadError;
  }>({});
  return (
    <Box aria-label="Update Item Masters">
      <UpdateItemMastersInstruction />
      <UpdateItemMastersPrepareTemplate />
      <UploadItemMasters
        mode="update"
        title="2. Upload your updated item master records file"
        description="Upload the comma-delimited (.csv) updated item master records file you created using the template above."
        onUploadSettled={(data, error) => setUploadResponse({ data, error })}
        onFileChanged={() => setUploadResponse({})}
      />
      {Boolean(uploadResponse.data) || Boolean(uploadResponse.error) ? (
        <UploadItemMastersResults mode="update" {...uploadResponse} />
      ) : null}
    </Box>
  );
}
