import { Box } from '@chakra-ui/react';
import { CreateItemMastersPrepareTemplate } from 'features/create-item-masters/CreateItemMastersPrepareTemplate';
import { UploadItemMastersResults } from 'features/create-item-masters/UploadItemMastersResults';
import { CreateItemMastersInstruction } from 'features/create-item-masters/CreateItemMastersInstruction';
import { useState } from 'react';

import {
  ItemMasterUploadError,
  ItemMasterUploadResponse,
} from 'types/itemMaster';
import { UploadItemMasters } from 'features/create-item-masters/UploadItemMasters';

export function CreateItemMastersPage() {
  const [uploadResponse, setUploadResponse] = useState<{
    data?: ItemMasterUploadResponse;
    error?: ItemMasterUploadError;
  }>({});
  return (
    <Box aria-label="Create Item Masters">
      <CreateItemMastersInstruction />
      <CreateItemMastersPrepareTemplate />
      <UploadItemMasters
        mode="create"
        title="2. Upload your item master records creation file"
        description="Upload the comma-delimited (.csv) item master records creation file you created using the template above."
        onUploadSettled={(data, error) => setUploadResponse({ data, error })}
        onFileChanged={() => setUploadResponse({})}
      />
      {Boolean(uploadResponse.data) || Boolean(uploadResponse.error) ? (
        <UploadItemMastersResults mode="create" {...uploadResponse} />
      ) : null}
    </Box>
  );
}
