import { useAuth0 } from '@auth0/auth0-react';
import { AUTH0_ROLES } from 'constants/auth0Roles';

type UsePortalProp = {
  isPortalReadOnly: boolean;
};

export const usePortal = (): UsePortalProp => {
  const { user } = useAuth0();

  const userRoles: string[] =
    (user && user[`${window.location.origin}/roles`]) || [];

  const isPortalReadOnly = userRoles.some((role) => {
    return role.toLowerCase() === AUTH0_ROLES.PORTAL_READ_ONLY.toLowerCase();
  });

  return { isPortalReadOnly };
};
