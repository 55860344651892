import { ReactNode, useCallback } from 'react';
import { Flex, Stack, Text, TextProps, Tooltip } from '@chakra-ui/react';
import HelpIcon from '@mui/icons-material/Help';
import { HeadingContentFilterProps } from 'hooks/useGridFiltering';
import { HeadingContentSortProps, SortState } from 'hooks/useGridSorting';
import { parseValueForClientSideFilter } from 'utils/urlSearchParamsUtils';
import { HeadingInputControl } from './HeadingInputControl';
import { ColumnSortState, SettingsIconButton } from './SettingsIconButton';
import ArrowDownwardIcon from '@mui/icons-material/South';
import ArrowUpwardIcon from '@mui/icons-material/North';

export interface GridHeadingContentProps
  extends HeadingContentFilterProps,
    ColumnSortState,
    HeadingContentSortProps {
  children?: ReactNode;
  onFilterChange: (
    columnId: string,
    value: string,
    fieldToFocus?: string
  ) => void;
  columnHelpText?: string;
  tableName: string;
  resizeElement?: ReactNode;
  clientSide?: boolean;
}

export function GridHeadingContent(props: GridHeadingContentProps) {
  const {
    children,
    columnId,
    toggleSort,
    isPressedSorting,
    defaultFilterValue,
    onFilterChange,
    isLoading,
    isSorting,
    isDescending,
    columnMetaData,
    columnHelpText,
    resizeElement,
    setFilter,
    setSort,
    clientSide = false,
    clientSideSortByProps,
  } = props;

  const {
    isSortable = true,
    isFilterable = true,
    hideSettingsButton = false,
    dataType,
  } = columnMetaData;

  const handleClientSideFilter = useCallback(
    (value: string) => {
      if (!clientSide) return;
      setFilter?.(parseValueForClientSideFilter(dataType, value));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dataType, clientSide]
  );

  const sortProps = clientSide
    ? {
        toggleSort: toggleSortBy,
        isSorting: clientSideSortByProps?.isSorted ?? false,
        isDescending: clientSideSortByProps?.isSortedDesc ?? false,
      }
    : {
        toggleSort: toggleSortBy,
        isSorting: isSorting,
        isDescending: isDescending,
      };

  function toggleSortBy(direction: 'asc' | 'desc') {
    let newState: SortState<any> = { field: '', direction: 'asc' };
    if (
      isPressedSorting &&
      ((sortProps.isDescending && direction === 'desc') ||
        (!sortProps.isDescending && direction === 'asc'))
    ) {
      newState = { field: '', direction: 'asc' };
    } else {
      newState = { field: columnId, direction };
    }
    if (clientSide) {
      if (newState.field) {
        clientSideSortByProps?.toggleSortBy?.(
          direction === 'desc' ? true : false
        );
      } else {
        clientSideSortByProps?.clearSortBy?.();
      }
    }
    setSort(newState);
  }

  return (
    <Flex display="flex" justifyContent="space-between" alignItems="center">
      <Stack spacing={0.5} width="full">
        <Flex alignItems="center" alignContent="space-between">
          <HeadingText
            role="button"
            aria-label="Toggle column sorting"
            aria-pressed={isPressedSorting}
            aria-disabled={isLoading || !isSortable}
            onClick={
              isSortable
                ? clientSide
                  ? clientSideSortByProps?.getSortByToggleProps?.()?.onClick
                  : toggleSort
                : undefined
            }
          >
            {children}
            {sortProps.isSorting ? (
              sortProps.isDescending ? (
                <ArrowDownwardIcon sx={{ fontSize: '1rem' }} />
              ) : (
                <ArrowUpwardIcon sx={{ fontSize: '1rem' }} />
              )
            ) : null}
          </HeadingText>
          {columnHelpText && (
            <Text fontSize="md" cursor="pointer">
              <Tooltip label={columnHelpText}>
                <HelpIcon fontSize="inherit" />
              </Tooltip>
            </Text>
          )}
          {!hideSettingsButton ? (
            <SettingsIconButton
              isLoading={isLoading}
              isSortable={isSortable}
              {...sortProps}
            />
          ) : null}
          {resizeElement}
        </Flex>
        {isFilterable ? (
          <HeadingInputControl
            columnId={columnId}
            defaultFilterValue={defaultFilterValue}
            onFilterChange={(...args) => {
              onFilterChange(...args);
              handleClientSideFilter(args[1]);
            }}
            columnMetaData={columnMetaData}
            canFilter={!isLoading}
          />
        ) : null}
      </Stack>
    </Flex>
  );
}

function HeadingText({ children, ...props }: TextProps) {
  return (
    <Text
      as="span"
      display="flex"
      alignItems="center"
      flexGrow="1"
      fontWeight="bold"
      {...props}
    >
      {children}
    </Text>
  );
}
