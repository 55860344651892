import 'react-datepicker/dist/react-datepicker.css';
import { forwardRef, Ref, useId, useMemo, useState } from 'react';
import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputProps,
  Tooltip,
} from '@chakra-ui/react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { Hidden } from './Hidden';
import AsteriskIcon from './AsteriskIcon';
import { parseDateInUTC, toISOInUTC } from 'utils/dateUtils';
import HelpIcon from '@mui/icons-material/Help';
import { endOfDay } from 'date-fns';
import { Nullable } from 'types/utilities';
import { BaseFormDatePickerProps } from './FormDatePicker';

export type FormDateRangePickerProps = BaseFormDatePickerProps &
  Omit<
    ReactDatePickerProps<never, true>,
    | 'selected'
    | 'onChange'
    | 'defaultValue'
    | 'placeholderText'
    | 'selectsRange'
  > & {
    defaultValue?: [string | Date, string | Date];
    onChange?: (
      value: [string, string],
      date?: [Date | null, Date | null]
    ) => void;
  };

export type DateRangeValue = [Nullable<Date>, Nullable<Date>];

export function FormDateRangePicker({
  variant = 'outline',
  size = 'md',
  iconSize = 'medium',
  defaultValue,
  onChange,
  isClearable,
  'aria-label': ariaLabel,
  error,
  onMouseEnter,
  disabled,
  label,
  isRequired,
  tooltip,
  placeholder,
  ...props
}: FormDateRangePickerProps) {
  const id = useId();

  const defaultDate: DateRangeValue = useMemo(() => {
    if (Array.isArray(defaultValue)) {
      const [startDate, endDate] = defaultValue;
      return [
        startDate ? parseDateInUTC(startDate) : null,
        endDate ? parseDateInUTC(endDate) : null,
      ];
    }
    return [null, null];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [dateRange, setDateRange] = useState<DateRangeValue>(defaultDate);

  const handleChange = (date: DateRangeValue) => {
    setDateRange([date[0], date[1] ? endOfDay(date[1]) : null]);
    if (onChange) {
      const [start, end] = date;
      onChange(
        [start ? toISOInUTC(start) : '', end ? toISOInUTC(endOfDay(end)) : ''],
        date
      );
    }
  };

  return (
    <FormControl isInvalid={!!error}>
      {label ? (
        <FormLabel display="flex" opacity={disabled ? 0.4 : 1}>
          {label}
          {tooltip ? (
            <Tooltip label={tooltip}>
              <HelpIcon
                fontSize="inherit"
                sx={{
                  marginLeft: '0.3rem',
                  width: '1.3rem',
                  height: '1.3rem',
                }}
              />
            </Tooltip>
          ) : null}
          {isRequired ? <AsteriskIcon width="0.375rem" /> : null}
        </FormLabel>
      ) : null}

      <Flex
        alignItems="center"
        sx={{
          '& .react-datepicker-popper': {
            zIndex: 2,
          },
          '& .react-datepicker__year-read-view--down-arrow, & .react-datepicker__month-read-view--down-arrow, & .react-datepicker__month-year-read-view--down-arrow':
            {
              top: '3px',
            },
          '& .react-datepicker__month-dropdown-container--scroll': {
            marginRight: '22px',
          },
        }}
      >
        <DatePicker
          {...props}
          placeholderText={placeholder}
          id={id}
          selectsRange={true}
          selected={dateRange[0]}
          startDate={dateRange[0]}
          endDate={dateRange[1]}
          onChange={handleChange}
          disabled={disabled}
          customInput={
            <AdapterInput
              isDisabled={disabled}
              aria-label={ariaLabel}
              type={isClearable ? 'search' : 'text'}
              size={size}
              variant={variant}
              onMouseEnter={onMouseEnter}
            />
          }
        />
        <Flex as="label" htmlFor={id} marginLeft="1">
          <Hidden>{ariaLabel}</Hidden>
          <CalendarTodayIcon fontSize={iconSize} />
        </Flex>
      </Flex>
      {error && <FormErrorMessage>{error}</FormErrorMessage>}
    </FormControl>
  );
}

const AdapterInput = forwardRef(
  (props: InputProps, ref: Ref<HTMLInputElement>) => (
    <Input ref={ref} {...props} />
  )
);
