import { SortState } from 'hooks/useGridSorting';
import { InventoryItem } from 'types/inventory';
import { QueryParamsObject } from 'utils/urlSearchParamsUtils';

export interface InventoryPageParams {
  initialSort?: SortState<InventoryItem>;
  onSortByChange?: (sorting: SortState<InventoryItem>) => void;
  initialPage?: number;
  initialPageSize?: number;
  onPaginationChange?: (pagination: { page: number; pageSize: number }) => void;
  initialFilters?: QueryParamsObject;
  onFilterChange?: (filters: QueryParamsObject) => void;
}

export const dictFieldToQuery = {
  sku: 'sku',
  description: 'description',
  warehouseId: 'dc-name-select',
  availableQty: 'qty-available-range',
  promisedQty: 'qty-allocated-range',
  damagedQty: 'qty-damaged-range',
  totalQty: 'qty-on-hand-range',
  onHoldQty: 'qty-on-hold-range',
} as { [key in keyof InventoryItem]: string };

export function queryToInventoryField(
  queryKey: string,
  queryWordSeparator: string = '-'
): keyof InventoryItem | '' {
  const dictQueryToField = {
    sku: 'sku',
    description: 'description',
    'dc-name-select': 'warehouseId',
    'qty-available-range': 'availableQty',
    'qty-allocated-range': 'promisedQty',
    'qty-damaged-range': 'damagedQty',
    'qty-on-hand-range': 'totalQty',
    'qty-on-hold-range': 'onHoldQty',
  } as { [key: string]: keyof InventoryItem };
  const field =
    dictQueryToField[queryKey.replaceAll(queryWordSeparator, '-')] || '';
  return field;
}

export function inventoryFieldToQuery(
  inventoryField: string,
  queryWordSeparator: string = '-'
): string {
  const queryKey =
    dictFieldToQuery[inventoryField as keyof InventoryItem] || inventoryField;
  return queryKey.replaceAll('-', queryWordSeparator);
}
