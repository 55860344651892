import { FC } from 'react';
import { ModalHeader, Flex } from '@chakra-ui/react';
import { AlertMessage } from 'components/AlertMessage';
import { ButtonGroup, Props as ButtonGroupProps } from 'components/ButtonGroup';

type Props = {
  title?: string;
  content: React.ReactNode;
  alertMessage?: string;
} & Pick<ButtonGroupProps, 'buttons'>;

export const Prompt: FC<Props> = ({
  title,
  content,
  alertMessage,
  buttons,
}) => {
  return (
    <Flex direction="column" textAlign="center" alignSelf="center">
      <AlertMessage
        visible={!!alertMessage}
        type="error"
        title="Please note"
        mx={[0, null, -10]}
        mt={2}
        mb={-10}
      >
        {alertMessage}
      </AlertMessage>
      <ModalHeader fontSize={['3xl', null, '4xl']} mt={[0, null, 7]}>
        {title}
      </ModalHeader>
      {content}
      <ButtonGroup commonButtonProps={{ my: 10 }} buttons={buttons} />
    </Flex>
  );
};
