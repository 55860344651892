import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import {
  ItemMastersUploadMode,
  ItemMasterUploadError,
  ItemMasterUploadResponse,
} from 'types/itemMaster';

type ItemMastersUploadData = { mode: ItemMastersUploadMode; file: File };

export function useItemMastersUpload(
  options?: Omit<
    UseMutationOptions<
      AxiosResponse<ItemMasterUploadResponse>,
      AxiosError<ItemMasterUploadError>,
      ItemMastersUploadData
    >,
    'mutationFn' | 'onSettled'
  > & {
    onSettled?: (
      data?: ItemMasterUploadResponse,
      error?: ItemMasterUploadError
    ) => void;
  }
) {
  const { onSettled, ...otherOptions } = options ?? {};
  return useMutation<
    AxiosResponse<ItemMasterUploadResponse>,
    AxiosError<ItemMasterUploadError>,
    ItemMastersUploadData
  >({
    ...otherOptions,
    mutationFn: async ({ mode, file }) => {
      const formData = new FormData();
      formData.append('file', file);

      try {
        const response = await (mode === 'create' ? axios.post : axios.patch)<{
          message: string;
        }>('/itemmaster', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        onSettled?.(response.data, undefined);
        return response;
      } catch (error: any) {
        onSettled?.(undefined, error?.response?.data);
        return error;
      }
    },
  });
}
