/* istanbul ignore file */
import { AMERICAN_STATES } from 'constants/countriesAndStates';
import * as yup from 'yup';

export const buildAddressCommonSchema = (optionalFields: boolean) => {
  const schema = {
    name: yup.string().label('Name').max(150),
    address: yup.string().label('Address line 1').max(150),
    address2: yup
      .string()
      .label('Address line 2')
      .max(150)
      .nullable()
      .optional(),
    city: yup.string().label('City').max(50),
    state: yup
      .string()
      .label('State')
      .max(50)
      .when('country', {
        is: (val: string) => !optionalFields && val === 'US',
        then: (schema) =>
          schema.required().test(
            'is-valid-american-state',
            () => 'Invalid state',
            function (state = '') {
              return AMERICAN_STATES.includes(state);
            }
          ),
        otherwise: (schema) => schema.nullable().optional(),
      }),
    zipCode: yup
      .string()
      .label('ZIP/Postal Code')
      .max(15)
      .test(
        'is-valid-american-zipcode',
        () => 'Invalid ZIP/Postal Code',
        function (zip = '') {
          if (optionalFields && !zip) return true;
          return this.parent.country !== 'US' || /^\d{5}(-\d{4})?$/.test(zip);
        }
      ),
    country: yup.string().label('Country'),
  };

  const objectSchema = yup.object(
    Object.fromEntries(
      Object.entries(schema)
        .filter(([key]) => key !== 'address2')
        .map(([key, value]) => [
          key,
          optionalFields ? value.nullable().optional() : value.required(),
        ])
    )
  );
  if (optionalFields) return objectSchema.nullable().optional();
  return objectSchema;
};
