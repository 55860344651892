import {
  Box,
  GridItem,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import { Shimmer } from 'components/Shimmer';
import { Fragment } from 'react';
import { InventoryItem } from 'types/inventory';

type Props = {
  inventoryItems?: InventoryItem[];
};

export function Quantities({ inventoryItems }: Props) {
  const isDesktop = useBreakpointValue(
    { base: false, md: true },
    {
      fallback: 'base',
    }
  );
  const totalColummns = isDesktop ? 3 : 2;

  return (
    <SimpleGrid columns={[2, null, 3]} width="100%" mt={0}>
      {(inventoryItems ?? [{}, {}, {}]).map((item, i) => {
        const {
          warehouseId,
          totalQty,
          availableQty,
          promisedQty,
          damagedQty,
          onHoldQty,
          conversionFactor,
        } = item as InventoryItem;
        const isBeginningOfRow = i % totalColummns === 0;
        const isEndOfRow = (i + 1) % totalColummns === 0;
        const isLastItem = i + 1 === inventoryItems?.length;

        const qtyTotal = totalQty * conversionFactor;

        return (
          <Fragment key={warehouseId ?? i}>
            <Box
              pr={10}
              pl={isBeginningOfRow ? 0 : 10}
              my={10}
              borderRight={isEndOfRow ? 0 : '2px'}
              borderColor="secondaryTeal.200"
              role="gridcell"
            >
              <Shimmer w="120px" h="30px" isLoaded={!!inventoryItems}>
                <Text variant="boldBlue" mb={3}>
                  {warehouseId}
                </Text>
              </Shimmer>

              <Quantity
                label="QTY Total"
                qty={isNaN(qtyTotal) ? 0 : qtyTotal}
              />
              <Quantity label="QTY Available" qty={availableQty} />
              <Quantity label="QTY Promised" qty={promisedQty} />
              <Quantity label="QTY Damaged" qty={damagedQty} />
              <Quantity label="QTY On Hold" qty={onHoldQty} />
            </Box>
            {isEndOfRow && !isLastItem ? (
              <GridItem
                colSpan={3}
                borderTop="2px"
                borderColor="secondaryTeal.200"
              />
            ) : null}
          </Fragment>
        );
      })}
    </SimpleGrid>
  );
}

const Quantity = ({ label, qty }: { label: string; qty?: number }) => (
  <Stat variant="primaryWithThinLabel">
    <StatNumber fontSize="2xl">
      <Shimmer w="40px" h="28px" my={1} isLoaded={qty !== undefined}>
        {qty}
      </Shimmer>
    </StatNumber>
    <StatLabel fontSize="sm">
      <Shimmer w="80px" h="18px" my={1} isLoaded={qty !== undefined}>
        {label}
      </Shimmer>
    </StatLabel>
  </Stat>
);
