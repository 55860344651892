import { CountryDto, Country } from 'types/country';

export function mapDtoToCountry(dto: CountryDto): Country {
  return {
    countryCode: dto.iso_3166_country_code_2,
    name: dto.name,
    requiresCustoms: dto.requires_customs,
    requiresPaperInvoice: dto.requires_paper_invoice,
    isReserved: dto.is_reserved,
    isUnassigned: dto.is_unassigned,
    active: dto.active,
  };
}
