import { FormControl, FormLabel, Text } from '@chakra-ui/react';

type LabelControlProps = {
  label: string;
  value: string;
};

export function LabelControl({ label, value }: LabelControlProps) {
  return (
    <FormControl variant="floating">
      <FormLabel display="flex" marginTop={7}>
        {label}
      </FormLabel>
      <Text fontWeight={400} mt="10px" aria-label={label}>
        {value}
      </Text>
    </FormControl>
  );
}
