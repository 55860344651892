import { useState } from 'react';

export function useGridPagination({
  initialPage,
  initialPageSize,
  onPaginationChange,
}: GridPaginationHookOptions = {}): GridPaginationHookResult {
  const [{ page, pageSize }, setState] = useState({
    page: initialPage || DEFAULT_PAGE,
    pageSize: initialPageSize || DEFAULT_PAGE_SIZE,
  });

  const setPage = (updatingPage: number) => {
    const nextState = {
      page: updatingPage,
      pageSize,
    };
    setState(nextState);

    if (onPaginationChange) {
      onPaginationChange(nextState);
    }
  };

  const setPageSize = (updatingPageSize: number) => {
    const nextState = {
      pageSize: updatingPageSize,
      page: DEFAULT_PAGE,
    };
    setState(nextState);

    if (onPaginationChange) {
      onPaginationChange(nextState);
    }
  };

  const skip: number = (page - 1) * pageSize;
  const limit: number = pageSize;
  const getTotalPages = (count: number): number => Math.ceil(count / pageSize);
  const getPageStart = (data: Array<object>): number =>
    data.length ? skip + 1 : -1;
  const getPageEnd = (data: Array<object>): number =>
    data.length ? skip + data.length : -1;

  return {
    page,
    setPage,
    pageSize,
    setPageSize,
    skip,
    limit,
    getTotalPages,
    getPageStart,
    getPageEnd,
  };
}

interface GridPaginationHookOptions {
  initialPage?: number;
  initialPageSize?: number;
  onPaginationChange?: (pagination: { page: number; pageSize: number }) => void;
}

export interface GridPaginationHookResult {
  page: number;
  setPage: (updatingPage: number) => void;
  pageSize: number;
  setPageSize: (updatingPageSize: number) => void;
  skip: number;
  limit: number;
  getTotalPages: (count: number) => number;
  getPageStart: (data: Array<object>) => number;
  getPageEnd: (data: Array<object>) => number;
}

const DEFAULT_PAGE: number = 1;

export const DEFAULT_PAGE_SIZE: number = 20;
